/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import dNone from '@waiterio/styles/bootstrap/dNone.js'
import dLgBlock from '@waiterio/styles/bootstrap/dLgBlock.js'
import dLgNone from '@waiterio/styles/bootstrap/dLgNone.js'
import colMd5 from '@waiterio/styles/bootstrap/colMd5.js'
import colMd7 from '@waiterio/styles/bootstrap/colMd7.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import primaryHeading from '@waiterio/www/styles/primaryHeading.js'

const image1UrlJpg =
  'https://www.imagelato.com/images/food-truck-increase-your-sales-1-db0678ac.jpg'
const image1Url768Jpg = image1UrlJpg.replace('.jpg', '-768w.jpg')
const image1Url640Jpg = image1UrlJpg.replace('.jpg', '-640w.jpg')
const image1Url768Webp = image1UrlJpg.replace('.jpg', '-768w.webp')
const image1Url640Webp = image1UrlJpg.replace('.jpg', '-640w.webp')

const image2UrlJpg =
  'https://www.imagelato.com/images/food-truck-increase-your-sales-2-c81ee876.jpg'
const image2Url768Jpg = image2UrlJpg.replace('.jpg', '-768w.jpg')
const image2Url640Jpg = image2UrlJpg.replace('.jpg', '-640w.jpg')
const image2Url768Webp = image2UrlJpg.replace('.jpg', '-768w.webp')
const image2Url640Webp = image2UrlJpg.replace('.jpg', '-640w.webp')

const image3UrlJpg =
  'https://www.imagelato.com/images/food-truck-increase-your-sales-3-8cff62e0.jpg'
const image3Url768Jpg = image3UrlJpg.replace('.jpg', '-768w.jpg')
const image3Url640Jpg = image3UrlJpg.replace('.jpg', '-640w.jpg')
const image3Url768Webp = image3UrlJpg.replace('.jpg', '-768w.webp')
const image3Url640Webp = image3UrlJpg.replace('.jpg', '-640w.webp')

const sectionContainer = css`
  margin: 180px 0px;

  @media (max-width: 992px) {
    margin: 120px 0px;
  }

  @media (max-width: 768px) {
    margin: 60px 0px;
  }
`

const contentText = css`
  margin: 0;
  max-width: 400px;

  @media (max-width: 992px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 768px) {
    max-width: 400px;
    text-align: center;
  }
`

const ctaButton = css`
  background-color: var(--color-primary);
  border-radius: 4px;
  color: white;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  padding: 12px 36px;
  text-align: center;
  &:hover,
  &:focus {
    color: white;
    background: var(--color-green);
  }
`

const IncreaseYourSalesSection = ({ locale }) => {
  const { t } = useTranslation(locale)
  let [firstSectionHovered, setFirstSectionHovered] = useState(true)
  let [secondSectionHovered, setSecondSectionHovered] = useState(false)
  let [thirdSectionHovered, setThirdSectionHovered] = useState(false)
  let [increaseYourSalesSectionImage, setIncreaseYourSalesSectionImage] =
    useState(image1UrlJpg)

  const onMouseEnterFirstSection = () => {
    setFirstSectionHovered(true)
    setSecondSectionHovered(false)
    setThirdSectionHovered(false)
    setIncreaseYourSalesSectionImage(image1UrlJpg)
  }

  const onMouseEnterSecondSection = () => {
    setFirstSectionHovered(false)
    setSecondSectionHovered(true)
    setThirdSectionHovered(false)
    setIncreaseYourSalesSectionImage(image2UrlJpg)
  }

  const onMouseEnterThirdSection = () => {
    setFirstSectionHovered(false)
    setSecondSectionHovered(false)
    setThirdSectionHovered(true)
    setIncreaseYourSalesSectionImage(image3UrlJpg)
  }

  return (
    <section css={sectionContainer}>
      <div css={container}>
        <div
          css={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <h2 css={primaryHeading}>
            {t('__food_truck_increaseyoursales_title__')}
          </h2>
          <p css={[contentText, { textAlign: 'center' }]}>
            {t('__food_truck_increaseyoursales_subtitle__')}
          </p>
        </div>

        <div css={dLgNone}>
          <div
            css={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              marginTop: 30,
            }}
          >
            <div css={{ textAlign: 'center' }}>
              <picture>
                <source
                  type="image/webp"
                  media="(min-width: 768px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 384px)"
                  srcSet={image1Url768Webp}
                />
                <source type="image/webp" srcSet={image1Url640Webp} />

                <source
                  type="image/jpg"
                  media="(min-width: 768px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 384px)"
                  srcSet={image1Url768Jpg}
                />
                <source type="image/jpg" srcSet={image1Url640Jpg} />

                <img
                  css={{
                    maxHeight: 500,
                    objectFit: 'contain',
                    width: '100%',
                    height: '100%',
                    '@media(max-width: 768px)': {
                      maxHeight: 400,
                    },
                    '@media(max-width: 479px)': {
                      maxHeight: 350,
                    },
                  }}
                  src={image1UrlJpg}
                />
              </picture>
            </div>
            <div css={{ padding: '24px', textAlign: 'center' }}>
              <h4 css={{ marginBottom: 5 }}>
                {t(
                  '__food_truck_increaseyoursales_nospecialhardwarerequired_title__',
                  locale,
                )}
              </h4>
              <p css={contentText}>
                {t(
                  '__food_truck_increaseyoursales_nospecialhardwarerequired_content__',
                  locale,
                )}
              </p>
            </div>

            <div css={{ textAlign: 'center' }}>
              <picture>
                <source
                  type="image/webp"
                  media="(min-width: 768px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 384px)"
                  srcSet={image2Url768Webp}
                />
                <source type="image/webp" srcSet={image2Url640Webp} />
                <source
                  type="image/jpg"
                  media="(min-width: 768px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 384px)"
                  srcSet={image2Url768Jpg}
                />
                <source type="image/jpg" srcSet={image2Url640Jpg} />

                <img
                  css={{
                    maxHeight: 500,
                    objectFit: 'contain',
                    width: '100%',
                    height: '100%',
                    '@media(max-width: 768px)': {
                      maxHeight: 400,
                    },
                    '@media(max-width: 479px)': {
                      maxHeight: 350,
                    },
                  }}
                  src={image2UrlJpg}
                />
              </picture>
            </div>
            <div css={{ padding: '24px', textAlign: 'center' }}>
              <h4 css={{ marginBottom: 5 }}>
                {t(
                  '__food_truck_increaseyoursales_increaseyourprofitability_title__',
                  locale,
                )}
              </h4>
              <p css={contentText}>
                {t(
                  '__food_truck_increaseyoursales_increaseyourprofitability_content__',
                  locale,
                )}
              </p>
            </div>

            <div css={{ textAlign: 'center' }}>
              <picture>
                <source
                  type="image/webp"
                  media="(min-width: 768px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 384px)"
                  srcSet={image3Url768Webp}
                />
                <source type="image/webp" srcSet={image3Url640Webp} />

                <source
                  type="image/jpg"
                  media="(min-width: 768px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 384px)"
                  srcSet={image3Url768Jpg}
                />
                <source type="image/jpg" srcSet={image3Url640Jpg} />

                <img
                  css={{
                    maxHeight: 500,
                    objectFit: 'contain',
                    width: '100%',
                    height: '100%',
                    '@media(max-width: 768px)': {
                      maxHeight: 400,
                    },
                    '@media(max-width: 479px)': {
                      maxHeight: 350,
                    },
                  }}
                  src={image3UrlJpg}
                />
              </picture>
            </div>
            <div css={{ padding: '24px', textAlign: 'center' }}>
              <h4 css={{ marginBottom: 5 }}>
                {t(
                  '__food_truck_increaseyoursales_takeorderfaster_title__',
                  locale,
                )}
              </h4>
              <p css={contentText}>
                {t(
                  '__food_truck_increaseyoursales_takeorderfaster_content__',
                  locale,
                )}
              </p>
            </div>
          </div>
        </div>
        <div css={[dNone, dLgBlock]}>
          <div css={[row, { marginTop: 30 }]}>
            <div css={[colSm12, colMd5]}>
              <div
                onMouseEnter={onMouseEnterFirstSection}
                css={[
                  {
                    backgroundColor: '#f5f7fb',
                    padding: '24px 48px',
                    marginBottom: 24,
                    transition: 'all .25s ease-in-out',
                  },
                  firstSectionHovered && {
                    transform: 'tY(-10px)',
                    cursor: 'pointer',
                    backgroundColor: 'white',
                    color: 'var(--color-primary)',
                    boxShadow:
                      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                    h4: {
                      color: 'var(--color-primary)',
                    },
                  },
                ]}
              >
                <h4 css={{ marginBottom: 5 }}>
                  {t(
                    '__food_truck_increaseyoursales_nospecialhardwarerequired_title__',
                    locale,
                  )}
                </h4>
                <p css={{ margin: 0 }}>
                  {t(
                    '__food_truck_increaseyoursales_nospecialhardwarerequired_content__',
                    locale,
                  )}
                </p>
              </div>
              <div
                onMouseEnter={onMouseEnterSecondSection}
                css={[
                  {
                    backgroundColor: '#f5f7fb',
                    padding: '24px 48px',
                    margin: '24px 0px',
                    transition: 'all .25s ease-in-out',
                  },
                  secondSectionHovered && {
                    transform: 'tY(-10px)',
                    cursor: 'pointer',
                    backgroundColor: 'white',
                    color: 'var(--color-primary)',
                    boxShadow:
                      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                    h4: {
                      color: 'var(--color-primary)',
                    },
                  },
                ]}
              >
                <h4 css={{ marginBottom: 5 }}>
                  {t(
                    '__food_truck_increaseyoursales_increaseyourprofitability_title__',
                    locale,
                  )}
                </h4>
                <p css={{ margin: 0 }}>
                  {t(
                    '__food_truck_increaseyoursales_increaseyourprofitability_content__',
                    locale,
                  )}
                </p>
              </div>
              <div
                onMouseEnter={onMouseEnterThirdSection}
                css={[
                  {
                    backgroundColor: '#f5f7fb',
                    padding: '24px 48px',
                    marginTop: 24,
                    transition: 'all .25s ease-in-out',
                  },
                  thirdSectionHovered && {
                    transform: 'tY(-10px)',
                    cursor: 'pointer',
                    backgroundColor: 'white',
                    color: 'var(--color-primary)',
                    boxShadow:
                      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                    h4: {
                      color: 'var(--color-primary)',
                    },
                  },
                ]}
              >
                <h4 css={{ marginBottom: 5 }}>
                  {t(
                    '__food_truck_increaseyoursales_takeorderfaster_title__',
                    locale,
                  )}
                </h4>
                <p css={{ margin: 0 }}>
                  {t(
                    '__food_truck_increaseyoursales_takeorderfaster_content__',
                    locale,
                  )}
                </p>
              </div>
            </div>
            <div css={[colSm12, colMd7]}>
              <div
                css={{
                  width: '100%',
                  height: '100%',
                  background: `url(${increaseYourSalesSectionImage}) center top / contain no-repeat`,
                  display: 'block',
                }}
              />
            </div>
          </div>
        </div>
        <div css={[row, { marginTop: 30 }]}>
          <div css={colSm12}>
            <div css={{ display: 'flex', justifyContent: 'center' }}>
              <a href="https://app.waiterio.com/signup" css={ctaButton}>
                {t('Try it for free')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default IncreaseYourSalesSection
