/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import colLg6 from '@waiterio/styles/bootstrap/colLg6.js'
import colMd12 from '@waiterio/styles/bootstrap/colMd12.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'

import rocketIcon from '../../images/qr-code-advantages-fast-service.svg'
import walletIcon from '../../images/qr-code-advantages-reduce-payroll-cost.svg'
import receiptIcon from '../../images/qr-code-advantages-reduce-print-cost.svg'
import menuIcon from '../../images/qr-code-advantages-easy-menu.svg'
import revenueIcon from '../../images/qr-code-advantages-increase-revenue.svg'

const section = css`
  margin: 80px 0px;

  @media (max-width: 992px) {
    margin: 64px 0px;
  }

  @media (max-width: 768px) {
    margin: 48px 0px;
  }
`

const AdvantagesSection = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <section css={section}>
      <div css={container}>
        <div css={row}>
          <div css={colMd12}>
            <h2
              css={{
                fontSize: 36,
                fontWeight: '700',
                lineHeight: '48px',
                textAlign: 'center',
                color: 'var(--color-primary)',
                textTransform: 'capitalize',
                maxWidth: 571,
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              {t('__menu_with_qrcode_advantages_title__')}
            </h2>
          </div>
        </div>

        <div css={[row, { marginTop: 80 }]}>
          <div css={colLg6}>
            <div css={{ display: 'flex', marginTop: 40 }}>
              <img src={rocketIcon} css={{ width: 80, height: 80 }} />
              <h3
                css={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  marginLeft: 24,
                  color: 'var(--color-primary)',
                  fontSize: 24,
                  lineHeight: '40px',
                }}
              >
                1. {t('__menu_with_qrcode_advantages_1_title__')}
              </h3>
            </div>
            <p css={{ color: '#959EAD' }}>
              {t('__menu_with_qrcode_advantages_1_content__')}
            </p>
          </div>

          <div css={colLg6}>
            <div css={{ display: 'flex', marginTop: 40 }}>
              <img src={walletIcon} css={{ width: 80, height: 80 }} />
              <h3
                css={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  marginLeft: 24,
                  color: 'var(--color-primary)',
                  fontSize: 24,
                  lineHeight: '40px',
                }}
              >
                2. {t('__menu_with_qrcode_advantages_2_title__')}
              </h3>
            </div>
            <p css={{ color: '#959EAD' }}>
              {t('__menu_with_qrcode_advantages_2_content__')}
            </p>
          </div>

          <div css={colLg6}>
            <div css={{ display: 'flex', marginTop: 40 }}>
              <img src={receiptIcon} css={{ width: 80, height: 80 }} />
              <h3
                css={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  marginLeft: 24,
                  color: 'var(--color-primary)',
                  fontSize: 24,
                  lineHeight: '40px',
                }}
              >
                3. {t('__menu_with_qrcode_advantages_3_title__')}
              </h3>
            </div>
            <p css={{ color: '#959EAD' }}>
              {t('__menu_with_qrcode_advantages_3_content__')}
            </p>
          </div>

          <div css={colLg6}>
            <div css={{ display: 'flex', marginTop: 40 }}>
              <img src={menuIcon} css={{ width: 80, height: 80 }} />
              <h3
                css={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  marginLeft: 24,
                  color: 'var(--color-primary)',
                  fontSize: 24,
                  lineHeight: '40px',
                }}
              >
                4. {t('__menu_with_qrcode_advantages_4_title__')}
              </h3>
            </div>
            <p css={{ color: '#959EAD' }}>
              {t('__menu_with_qrcode_advantages_4_content__')}
            </p>
          </div>

          <div css={colLg6}>
            <div css={{ display: 'flex', marginTop: 40 }}>
              <img src={revenueIcon} css={{ width: 80, height: 80 }} />
              <h3
                css={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  marginLeft: 24,
                  color: 'var(--color-primary)',
                  fontSize: 24,
                  lineHeight: '40px',
                }}
              >
                5. {t('__menu_with_qrcode_advantages_5_title__')}
              </h3>
            </div>
            <p css={{ color: '#959EAD' }}>
              {t('__menu_with_qrcode_advantages_5_content__')}
            </p>
          </div>

          <div css={colLg6} />
        </div>
      </div>
    </section>
  )
}

export default AdvantagesSection
