/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import faviconUrl from '@waiterio/shared/faviconUrl.js'
import violet from '@waiterio/styles/violet.js'
import Layout from '../../components/LayoutComponent.js'
import slugsOnlineOrdering from '../../slugsOnlineOrdering.js'
import languages from '../../languages.js'
import BannerSection from './BannerSection.js'
import OverviewSection from './OverviewSection.js'
import FeaturesSection from './FeaturesSection.js'
import IncreaseYourProfitsSection from './IncreaseYourProfitsSection.js'
import PosOverviewSection from './PosOverviewSection.js'
import ClosingSection from '../ClosingSection.js'

export const Head = ({ locale }) => {
  const { t } = useTranslation(locale)

  const title = `Waiterio | ${t('Restaurant online ordering')}`
  const description = `${t('Restaurant online ordering')}`

  const url = `https://www.waiterio.com/${locale}/${slugsOnlineOrdering[locale]}/`

  return (
    <>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={faviconUrl} />
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={faviconUrl} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={faviconUrl} />
    </>
  )
}

export const paths = languages.map(locale => ({
  path: `/${locale}/${slugsOnlineOrdering[locale] || slugsOnlineOrdering.en}/`,
  props: {
    locale,
  },
}))

const OnlineOrderingPage = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <>
      <style>{`
      :root {
        --color-primary: ${violet};
      }
    `}</style>
      <Layout locale={locale}>
        <BannerSection locale={locale} />
        <OverviewSection locale={locale} />
        <FeaturesSection locale={locale} />
        <IncreaseYourProfitsSection locale={locale} />
        <PosOverviewSection locale={locale} />
        <ClosingSection
          sectionId="online-ordering"
          locale={locale}
          title={t('__online_ordering_closing_title__')}
          subtitle={t('__online_ordering_closing_subtitle__')}
        />
      </Layout>
    </>
  )
}

export default OnlineOrderingPage
