/* Copyright 2013 - 2024 Waiterio LLC */
import IS_BROWSER from './IS_BROWSER.js'
import IS_NODE from './IS_NODE.js'

let IS_SCREENSHOTS = false

if (
  IS_BROWSER &&
  window.WAITERIO_SCREENSHOTS === '{{{WAITERIO_SCREENSHOTS}}}'
) {
  delete window.WAITERIO_SCREENSHOTS
}

if (
  IS_NODE &&
  process.env.WAITERIO_SCREENSHOTS === '{{{WAITERIO_SCREENSHOTS}}}'
) {
  delete process.env.WAITERIO_SCREENSHOTS
}

if (IS_BROWSER && window.WAITERIO_SCREENSHOTS) {
  IS_SCREENSHOTS = window.WAITERIO_SCREENSHOTS
} else if (
  IS_NODE &&
  process.env.WAITERIO_SCREENSHOTS &&
  process.env.WAITERIO_SCREENSHOTS !== '{{{WAITERIO_SCREENSHOTS}}}'
) {
  IS_SCREENSHOTS = process.env.WAITERIO_SCREENSHOTS
}

// if (IS_STAGING || IS_DEVELOPMENT) {
//   console.debug('IS_SCREENSHOTS = ' + IS_SCREENSHOTS)
// }

export default IS_SCREENSHOTS
