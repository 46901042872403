/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint max-len: 0 */
/* eslint react/no-unescaped-entities: ["error", {"forbid": ['>', '}'] }] */

/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import getCookie from '@monorepo/shared/getCookie.js'
import { useTranslation } from '@multilocale/react/index.js'
import IconWindows from '@stiloso/icons/IconWindows.js'
import IconMac from '@stiloso/icons/IconMac.js'
import IconAndroid from '@stiloso/icons/IconNewAndroid.js'
import IconApple from '@stiloso/icons/IconFabApple.js'
import IconLinux from '@stiloso/icons/IconLinux.js'
import faviconUrl from '@waiterio/shared/faviconUrl.js'
import colLg2 from '@waiterio/styles/bootstrap/colLg2.js'
import colSm10 from '@waiterio/styles/bootstrap/colSm10.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import Layout from '../components/LayoutComponent.js'
import underlinePartial from '../styles/underlinePartial.js'
import languages from '../languages.js'

const section = css`
  background-color: rgb(245, 247, 251);
  padding-top: 50px;
  padding-bottom: 100px;
  text-align: center;
`

const boxShadow = css`
  background-color: white;
  padding: 60px 0;
`

const title = css`
  ${underlinePartial}
  font-size: 52px;
  font-weight: 700;
  line-height: 58px;
  margin-bottom: 25px;
  margin-top: 0px;
`

const subtitle = css`
  font-size: 24px;
  line-height: 36px;
  margin: 0px;
  padding: 0 80px;
`

const osLink = css`
  display: block;
  padding-top: 50px;
`

export const Head = ({ locale }) => {
  const { t } = useTranslation(locale)

  const title = `${t('Downloads')} | ${t(
    'Waiterio Restaurant Point of Sale',
    locale,
  )}`
  const description = t('__downloads_subtitle__', locale)
  const url = `https://www.waiterio.com/${locale}/downloads/`

  return (
    <>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={faviconUrl} />
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={faviconUrl} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={faviconUrl} />
    </>
  )
}

export const paths = languages.map(locale => ({
  path: `/${locale}/downloads/`,
  props: {
    locale,
  },
}))

const DownloadsPage = ({ locale }) => {
  const { t } = useTranslation(locale)

  let referrer = getCookie('referrer') || 'www.waiterio.com/downloads/'
  let referrers = getCookie('referrers') || 'www.waiterio.com/downloads/'
  let urlParametersGooglePlayStore = new URLSearchParams({
    utm_source: 'waiterio.com/downloads',
    utm_medium: 'link',
    utm_content: referrer,
    referrers,
  }).toString()

  return (
    <Layout locale={locale}>
      <div css={{ height: 90, backgroundColor: '#f5f7fb' }} />
      <section css={section}>
        <div css={container}>
          <div css={boxShadow}>
            <div css={[row, { justifyContent: 'center' }]}>
              <div css={colSm10}>
                <h1 css={title}>{t('Downloads')}</h1>
                <p css={subtitle}>{t('__downloads_subtitle__')}</p>
              </div>
            </div>
            <div css={[row, { justifyContent: 'center', marginTop: 30 }]}>
              <a css={[colLg2, osLink]} href="/install/windows/latest/">
                <IconWindows css={{ width: 35, height: 40 }} />
                <div>Windows</div>
              </a>
              <a css={[colLg2, osLink]} href="/install/macos/latest/">
                <IconMac css={{ width: 45, height: 40 }} />
                <div>macOS</div>
              </a>
              <a
                css={[colLg2, osLink]}
                href={`https://play.google.com/store/apps/details?id=io.waiter.android&${urlParametersGooglePlayStore}`}
              >
                <IconAndroid css={{ width: 45, height: 40 }} />
                <div>Android</div>
              </a>
              <a css={[colLg2, osLink]} href="/install/ios/latest/">
                <IconApple css={{ width: 45, height: 40 }} />
                <div>iOS</div>
              </a>
              <a css={[colLg2, osLink]} href="/install/linux/latest/">
                <IconLinux css={{ width: 35, height: 40 }} />
                <div>Linux</div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default DownloadsPage
