/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import colMd12 from '@waiterio/styles/bootstrap/colMd12.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import button from '@waiterio/www/styles/button.js'
import LaptopFrameComponent from '../../components/LaptopFrameComponent.js'

const bannerSection = css`
  background: var(--color-primary);
  position: relative;

  @media (max-width: 576px) {
    padding: 112px 0 0;
  }

  @media (max-width: 479px) {
    padding: 96px 0 0;
  }
`

export const bannerHeadingContainer = css`
  padding: 200px 0 150px;
  text-align: center;

  @media (max-width: 767px) {
    padding: 200px 0;
  }
`

export const bannerBackground = css`
  position: absolute;
  top: 0;
  background: var(--color-primary);
  min-height: 600px;
  height: calc(100vh + 9vw);
  width: 100%;
`

export const bannerForeground = css`
  position: relative;
  padding-top: 16vh;
  padding-bottom: 16vh;
  text-align: center;
`

export const bannerTitle = css`
  color: white;
  font-size: 64px;
  font-weight: 800;
  line-height: normal;
  text-transform: capitalize;

  @media (max-width: 992px) {
    font-size: 54px;
  }

  @media (max-width: 767px) {
    font-size: 44px;
  }

  @media (max-width: 479px) {
    font-size: 36px;
  }
`

export const bannerSubtitle = css`
  color: white;
  font-size: 24px;
  font-weight: normal;
  line-height: 32px;
  margin: 0 100px 20px 100px;
  padding: 15px 50px 0 50px;
  text-align: center;

  @media (max-width: 992px) {
    font-size: 18px;
    line-height: 24px;
    margin: 20px 0 20px 0;
    padding: 0;
  }
`

const BannerSection = ({ locale }) => {
  const { t } = useTranslation(locale)
  const fallbackImage = event => {
    let href = event?.target?.getAttribute('href')

    if (event?.target?.src?.indexOf(`${locale}.jpg`) > 0) {
      event.target.src = event.target.src.replace(`${locale}.jpg`, 'en.jpg')
    } else if (href?.indexOf(`${locale}.jpg`) > 0) {
      event.target.setAttribute('href', href.replace(`${locale}.jpg`, 'en.jpg'))
    }
  }

  return (
    <section css={bannerSection}>
      <div css={container}>
        <div css={bannerForeground}>
          <div css={row}>
            <div css={colMd12}>
              <h1 css={bannerTitle}>
                {t('__menu_with_qrcode_banner_title__')}
              </h1>
            </div>
          </div>

          <div css={row}>
            <div css={colMd12}>
              <div css={{ position: 'relative', margin: '24px 0px' }}>
                <LaptopFrameComponent />
                <img
                  css={{
                    position: 'absolute',
                    top: '11.42%',
                    left: '13.37%',
                    width: '73.52%',
                  }}
                  src={`https://www.imagelato.com/images/screenshot-2e0628e9-order-computer-${locale}.jpg`}
                  onError={fallbackImage}
                />
                <svg
                  viewBox="0 0 1024 640"
                  css={{
                    position: 'absolute',
                    left: 0,
                    bottom: '5%',
                    width: '55%',
                  }}
                >
                  <rect
                    x="0"
                    y="0"
                    width="1024"
                    height="640"
                    rx="60"
                    css={{ fill: 'white' }}
                  />
                  <circle
                    cx="60"
                    cy="320"
                    r="25"
                    height="100"
                    css={{ fill: '#f6f6f6' }}
                  />
                  <image
                    x="80"
                    y="50"
                    width="864"
                    height="540"
                    href={`https://www.imagelato.com/images/screenshot-2e0628e9-orders-tablet-${locale}.jpg`}
                  />
                </svg>
                <div
                  css={{
                    position: 'absolute',
                    bottom: 0,
                    left: '42%',
                    width: '16.2%',
                  }}
                >
                  <img
                    css={{ border: '0.4vw solid white', borderRadius: '1vw' }}
                    src={`https://www.imagelato.com/images/screenshot-2e0628e9-tables-smartphone-tall-${locale}.jpg`}
                    onError={fallbackImage}
                  />
                </div>
              </div>
            </div>
          </div>

          <div css={row}>
            <div css={[colMd12, { margin: '24px 0px' }]}>
              <h2 css={bannerSubtitle}>
                {t('__menu_with_qrcode_banner_subtitle__')}
              </h2>
              <a href="https://app.waiterio.com/signup" css={button}>
                {t('Try it for free')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BannerSection
