/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import colLg6 from '@waiterio/styles/bootstrap/colLg6.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import button from '@waiterio/www/styles/button.js'

const section = css`
  margin: 80px 0px;

  @media (max-width: 992px) {
    margin: 64px 0px;
  }

  @media (max-width: 768px) {
    margin: 48px 0px;
  }
`

const CallToActionSection = ({ locale }) => {
  const { t } = useTranslation(locale)
  return (
    <section css={section}>
      <div css={container}>
        <div css={[row]}>
          <div
            css={[
              colLg6,
              { '@media(max-width: 992px)': { textAlign: 'center' } },
            ]}
          >
            <h2 css={{ color: '#024873', fontSize: 24, lineHeight: '48px' }}>
              {t('__menu_with_qrcode_calltoaction_strapline__')}
            </h2>
            <h2
              css={{
                marginBottom: 28,
                color: '#024873',
                fontSize: 36,
                fontWeight: '700',
                lineHeight: '48px',
              }}
            >
              {t('__menu_with_qrcode_calltoaction_title__')}
            </h2>
            <a
              href="https://app.waiterio.com"
              css={[button, { color: 'white', backgroundColor: '#024872' }]}
            >
              {t('Try it for free')}
            </a>
          </div>

          <div css={colLg6}>
            <div
              css={{
                position: 'absolute',
                bottom: -0,
                right: 0,
                '@media(max-width: 992px)': {
                  position: 'relative',
                  marginTop: 32,
                  img: { height: 'auto' },
                },
              }}
            >
              <img
                src="https://www.imagelato.com/images/menu-with-qrcode-call-to-action-1a55d622.png"
                height="500px"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CallToActionSection
