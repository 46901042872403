/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import container from '@waiterio/styles/bootstrap/container.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import row from '@waiterio/styles/bootstrap/row.js'
import Layout from '../components/LayoutComponent.js'
import languages from '../languages.js'

const errorSection = css`
  padding: 100px 0;
  text-align: center;
`

const goBackToTheHomePage = css`
  background-color: var(--color-primary);
  color: white;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  padding: 10px;
  :hover {
    color: white;
    background-color: var(--color-green);
  }
`

export const Head = ({ locale }) => {
  const { t } = useTranslation(locale)

  const title = `${t('Page not found')} | Waiterio`
  return <title>{title}</title>
}

export const paths = languages
  .map(locale => ({
    path: `/${locale}/not-found/`,
    props: {
      locale,
    },
  }))
  .concat({
    path: '*',
  })

const NotFoundPage = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <Layout locale={locale}>
      <div css={{ height: 90, backgroundColor: '#f5f7fb' }} />
      <section css={errorSection}>
        <div css={container}>
          <div css={row}>
            <div css={colSm12}>
              <div
                css={{
                  fontSize: 180,
                  fontWeight: 700,
                  lineHeight: '180px',
                  color: '#333333',
                }}
              >
                404
              </div>
              <div
                css={{
                  fontSize: 24,
                  fontWeight: 600,
                  lineHeight: '24px',
                  margin: '20px 0px',
                }}
              >
                {t('Page not found')}
              </div>
              <a href="/" css={goBackToTheHomePage}>
                {t('Go back to the home page')}
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
