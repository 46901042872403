/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import IconHandUsd from '@stiloso/icons/IconHandUsd.js'
import IconBarChart from '@stiloso/icons/IconBarChart.js'
import IconUsers from '@stiloso/icons/IconUsers.js'
import IconPrint from '@stiloso/icons/IconPrint.js'
import IconMoneyBill from '@stiloso/icons/IconMoneyBill.js'
import IconPhone from '@stiloso/icons/IconPhone.js'
import colLg4 from '@waiterio/styles/bootstrap/colLg4.js'
import colMd6 from '@waiterio/styles/bootstrap/colMd6.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'

const specialitiesSection = css`
  label: specialities-section;
  background-color: #f5f7fb;
  margin-top: -100px;
  padding: 100px 0 70px 0;
`

const specialityBox = css`
  background: white;
  border: 1px solid #f2f2f2;
  overflow: hidden;
  padding: 40px 15px 25px 15px;
  position: relative;
  text-align: center;
  margin-bottom: 30px;
`

const specialityIcon = css`
  background: #f4f4f4;
  border-radius: 90px;
  color: var(--color-primary);
  display: inline-block;
  fill: var(--color-primary);
  height: 86px;
  line-height: 86px;
  text-align: center;
  width: 86px;
  vertical-align: middle;
`

const specialityTitle = css`
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 15px;
  margin-top: 25px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  z-index: 9;
`

const SpecialitiesSection = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <section css={specialitiesSection}>
      <div css={container}>
        <div css={[row, { paddingBottom: 30 }]}>
          <div css={colSm12}>
            <div
              css={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'var(--color-primary)',
              }}
            >
              <h3
                css={{
                  fontSize: 36,
                  lineHeight: '44px',
                  margin: '30px 0',
                  fontWeight: 700,
                  color: 'white',
                }}
              >
                {t('__home_specialities_title__')}
              </h3>
            </div>
          </div>
        </div>
        <div css={row}>
          <div css={[colSm12, colMd6, colLg4]}>
            <div css={specialityBox}>
              <div css={specialityIcon}>
                <IconHandUsd
                  css={{
                    width: 50,
                    height: 46,
                    marginTop: -12,
                    verticalAlign: 'middle',
                  }}
                />
              </div>
              <h5 css={specialityTitle}>
                {t('__home_specialities_freedom_title__')}
              </h5>
              <p>{t('__home_specialities_freedom_content__')}</p>
            </div>
          </div>
          <div css={[colSm12, colMd6, colLg4]}>
            <div css={specialityBox}>
              <div css={specialityIcon}>
                <IconBarChart
                  css={{
                    width: 46,
                    height: 46,
                    marginTop: -12,
                    verticalAlign: 'middle',
                  }}
                />
              </div>
              <div>
                <h5 css={specialityTitle}>
                  {t('__home_specialities_reporting_title__')}
                </h5>
                <p>{t('__home_specialities_reporting_content__')}</p>
              </div>
            </div>
          </div>
          <div css={[colSm12, colMd6, colLg4]}>
            <div css={specialityBox}>
              <div css={specialityIcon}>
                <IconUsers
                  css={{
                    width: 58,
                    height: 46,
                    marginTop: -12,
                    verticalAlign: 'middle',
                  }}
                />
              </div>
              <h5 css={specialityTitle}>
                {t('__home_specialities_collaboration_title__')}
              </h5>
              <p>{t('__home_specialities_collaboration_content__')}</p>
            </div>
          </div>
          <div css={[colSm12, colMd6, colLg4]}>
            <div css={specialityBox}>
              <div css={specialityIcon}>
                <IconPrint
                  css={{
                    width: 46,
                    height: 46,
                    marginTop: -12,
                    verticalAlign: 'middle',
                  }}
                />
              </div>
              <h5 css={specialityTitle}>
                {t('__home_specialities_anyprinter_title__')}
              </h5>
              <p>{t('__home_specialities_anyprinter_content__')}</p>
            </div>
          </div>
          <div css={[colSm12, colMd6, colLg4]}>
            <div css={specialityBox}>
              <div css={specialityIcon}>
                <IconMoneyBill
                  css={{
                    width: 58,
                    height: 46,
                    marginTop: -12,
                    verticalAlign: 'middle',
                  }}
                />
              </div>
              <h5 css={specialityTitle}>
                {t('__home_specialities_splitthebill_title__')}
              </h5>
              <p>{t('__home_specialities_splitthebill_content__')}</p>
            </div>
          </div>
          <div css={[colSm12, colMd6, colLg4]}>
            <div css={specialityBox}>
              <div css={specialityIcon}>
                <IconPhone
                  css={{
                    width: 30,
                    height: 46,
                    marginTop: -12,
                    verticalAlign: 'middle',
                  }}
                />
              </div>
              <h5 css={specialityTitle}>
                {t('__home_specialities_anydevice_title__')}
              </h5>
              <p>{t('__home_specialities_anydevice_content__')}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SpecialitiesSection
