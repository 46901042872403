/* Copyright 2013 - 2024 Waiterio LLC */
import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client' // eslint-disable-line import/extensions
import { BrowserRouter } from 'react-router-dom'
import { initMultilocale } from '@multilocale/react/index.js'
import GloboProvider from '@globo/react/GloboProvider.js'
import PolyblogProvider from '@polyblog/react/PolyblogProvider.js'
import ReferrerCookie from '@waiterio/components/ReferrerCookie.js'
import StageBadge from '@waiterio/components/StageBadge.js'
import ErrorBoundary from '@waiterio/components/ErrorBoundary.js'
import ScrollToTop from '@waiterio/components/ScrollToTop.js'
import allowSubdomainsCrossOrigin from '@monorepo/shared/allowSubdomainsCrossOrigin.js'
import getSentryBrowser from '@monorepo/telemetry/getSentryBrowser.js'
import GlobalStyles from './styles/GlobalStyles.js'
import Routes from './Routes.js'

allowSubdomainsCrossOrigin()

initMultilocale({
  organizationId: 'e395dba6a14d8af4c2d35890',
  project: 'waiterio-www',
})

createRoot(document.getElementById('root')).render(
  <ErrorBoundary>
    <BrowserRouter>
      <GloboProvider>
        <PolyblogProvider>
          <Suspense>
            <ScrollToTop />
            <GlobalStyles />
            <Routes />
            <ReferrerCookie />
            <StageBadge />
          </Suspense>
        </PolyblogProvider>
      </GloboProvider>
    </BrowserRouter>
  </ErrorBoundary>,
)

getSentryBrowser()
