/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { Link } from 'react-router-dom'
import { useTranslation } from '@multilocale/react/index.js'
import colMd6 from '@waiterio/styles/bootstrap/colMd6.js'
import colLg3 from '@waiterio/styles/bootstrap/colLg3.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import slugsPosSystem from '../slugsPosSystem.js'
import slugsWebsiteBuilder from '../slugsWebsiteBuilder.js'
import slugsOnlineOrdering from '../slugsOnlineOrdering.js'
import slugsMenuWithQRCode from '../slugsMenuWithQRCode.js'
import slugsFoodTruckPosSystem from '../slugsFoodTruckPosSystem.js'
import slugsBarPosSystem from '../slugsBarPosSystem.js'

const footerSection = css`
  padding-top: 30px;
`

const footerBox = css`
  display: flex;
  flex-direction: column;

  a {
    width: max-content;
  }
`

const footerTitle = css`
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  padding-bottom: 10px;
  text-transform: capitalize;
`

const FooterComponent = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <footer css={[footerSection, container]}>
      <div css={row}>
        <div css={[colSm12, colMd6, colLg3, { marginTop: 30 }]}>
          <div css={footerBox}>
            <h4 css={footerTitle}>{t('Language')}</h4>
            <a href="/en/">English</a>
            <a href="/es/">Español</a>
            <a href="/de/">Deutsch</a>
            <a href="/pt/">Português</a>
            <a href="/it/">Italiano</a>
            <a href="/fr/">Français</a>
            <a href="/ru/">Русский</a>
            <a href="/th/">ไทย</a>
            <a href="/nl/">Nederlands</a>
            <a href="/el/">ελληνικά</a>
            <a href="/zh/">中文</a>
            <a href="/id/">Bahasa Indonesia</a>
            <a href="/pl/">Polski</a>
            <a href="/tr/">Türkçe</a>
          </div>
        </div>
        <div css={[colSm12, colMd6, colLg3, { marginTop: 30 }]}>
          <div css={footerBox}>
            <h4 css={footerTitle}>{t('__pos_system_features_title__')}</h4>
            <a
              css={{ textTransform: 'capitalize' }}
              href={`/${locale}/${
                slugsPosSystem[locale] || slugsPosSystem.en
              }#features-order-management`}
            >
              {t('__pos_system_features_ordermanagement_title__')}
            </a>
            <a
              css={{ textTransform: 'capitalize' }}
              href={`/${locale}/${
                slugsPosSystem[locale] || slugsPosSystem.en
              }#features-table-management`}
            >
              {t('__pos_system_features_tablemanagement_title__')}
            </a>
            <a
              css={{ textTransform: 'capitalize' }}
              href={`/${locale}/${
                slugsPosSystem[locale] || slugsPosSystem.en
              }#features-menu-management`}
            >
              {t('__pos_system_features_menumanagement_title__')}
            </a>
            <a
              css={{ textTransform: 'capitalize' }}
              href={`/${locale}/${
                slugsPosSystem[locale] || slugsPosSystem.en
              }#features-staff-management`}
            >
              {t('__pos_system_features_staffmanagement_title__')}
            </a>
            <a
              css={{ textTransform: 'capitalize' }}
              href={`/${locale}/${
                slugsPosSystem[locale] || slugsPosSystem.en
              }#features-payment-and-discount`}
            >
              {t('__pos_system_features_paymentanddiscount_title__')}
            </a>
            <a
              css={{ textTransform: 'capitalize' }}
              href={`/${locale}/${
                slugsPosSystem[locale] || slugsPosSystem.en
              }#features-sales-report-analysis`}
            >
              {t('__pos_system_features_salesreports_title__')}
            </a>
            <a
              css={{ textTransform: 'capitalize' }}
              href={`/${locale}/${
                slugsPosSystem[locale] || slugsPosSystem.en
              }#features-quick-support`}
            >
              {t('__pos_system_features_quicksupport_title__')}
            </a>
          </div>
        </div>

        <div css={[colSm12, colMd6, colLg3, { marginTop: 30 }]}>
          <div css={footerBox}>
            <h4 css={footerTitle}>{t('Products')}</h4>
            <Link
              css={{ textTransform: 'capitalize', maxWidth: '100%' }}
              to={`/${locale}/${slugsPosSystem[locale] || slugsPosSystem.en}/`}
            >
              {t('Restaurant POS system')}
            </Link>
            <Link
              css={{ textTransform: 'capitalize', maxWidth: '100%' }}
              to={`/${locale}/${
                slugsWebsiteBuilder[locale] || slugsWebsiteBuilder.en
              }/`}
            >
              {t('Restaurant website builder')}
            </Link>
            <Link
              css={{ textTransform: 'capitalize', maxWidth: '100%' }}
              to={`/${locale}/${
                slugsOnlineOrdering[locale] || slugsOnlineOrdering.en
              }/`}
            >
              {t('Restaurant online ordering')}
            </Link>
            <Link
              css={{ textTransform: 'capitalize', maxWidth: '100%' }}
              to={`/${locale}/${
                slugsMenuWithQRCode[locale] || slugsMenuWithQRCode.en
              }/`}
            >
              {t('Restaurant menu with QR Code')}
            </Link>
          </div>
        </div>

        <div css={[colSm12, colMd6, colLg3, { marginTop: 30 }]}>
          <div css={footerBox}>
            <h4 css={footerTitle}>{t('Restaurant types')}</h4>
            <Link
              css={{ textTransform: 'capitalize' }}
              to={`/${locale}/${
                slugsFoodTruckPosSystem[locale] || slugsFoodTruckPosSystem.en
              }/`}
            >
              {t('Food truck')}
            </Link>
            <Link
              css={{ textTransform: 'capitalize' }}
              to={`/${locale}/${
                slugsBarPosSystem[locale] || slugsBarPosSystem.en
              }/`}
            >
              {t('Bars and clubs')}
            </Link>
          </div>
        </div>
      </div>

      <div css={row}>
        <div css={[colSm12, colMd6, colLg3, { marginTop: 30 }]}>
          <div css={footerBox}>
            <h4 css={footerTitle}>{t('Resources')}</h4>
            <Link
              css={{ textTransform: 'capitalize' }}
              to={`/${locale}/pricing/`}
            >
              {t('Pricing')}
            </Link>
            <a href={`/blog/${locale}/`}>{t('Blog')}</a>
            <a href={`/docs/${locale}/`}>{t('Documentation')}</a>
            <a
              href={`/${locale}/${
                slugsPosSystem[locale] || slugsPosSystem.en
              }#hardware-section`}
            >
              {t('__pos_system_hardware_title__')}
            </a>
          </div>
        </div>

        <div css={[colSm12, colMd6, colLg3, { marginTop: 30 }]}>
          <div css={footerBox}>
            <h4 css={footerTitle}>{t('Follow Us')}</h4>
            <a
              href="https://twitter.com/waiterio"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
            <a
              href="https://www.facebook.com/waiterio"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
            <a
              href="https://www.linkedin.com/company/waiter-io"
              target="_blank"
              rel="noopener noreferrer"
            >
              Linkedin
            </a>
          </div>
        </div>
        <div
          css={[
            colSm12,
            colMd6,
            colLg3,
            { marginTop: 30, display: 'flex', flexDirection: 'column' },
          ]}
        >
          <div css={footerBox}>
            <h4 css={footerTitle}>{t('More Links')}</h4>
            <a href="/takeaway/">{t('Takeaway')}</a>
            <Link to={`/${locale}/privacy-policy/`}>{t('Privacy Policy')}</Link>
            <Link to={`/${locale}/terms-and-conditions/`}>
              {t('Terms and Conditions')}
            </Link>
          </div>
        </div>
        <div css={[colSm12, colMd6, colLg3, { marginTop: 30 }]}>
          <div css={footerBox}>
            <h4 css={footerTitle}>{t('Contact us')}</h4>
            <a
              href="mailto:info@waiterio.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              info@waiterio.com
            </a>
            <Link to={`/${locale}/referral/`}>{t('Referral program')}</Link>
          </div>
        </div>
      </div>
      <div css={[row, { marginTop: 40 }]}>
        <div css={[colSm12, { textAlign: 'center' }]}>
          <div css={{ borderTop: '1px solid #efefef', padding: '20px 0' }}>
            {t('Copyright')} @ <Link to={`/${locale}/`}>Waiterio</Link> 2013 -{' '}
            {new Date().getFullYear()}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default FooterComponent
