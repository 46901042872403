/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint react/no-unescaped-entities: ["error", {"forbid": ['>', '"', '}'] }] */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import colLg3 from '@waiterio/styles/bootstrap/colLg3.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import {
  subtitle,
  stepNumber,
  stepName,
  stepContent,
} from '@waiterio/www/styles/section.js'
import primaryHeading from '@waiterio/www/styles/primaryHeading.js'

const sectionContainer = css`
  margin: 160px 0px;

  @media (max-width: 1199px) {
    margin: 128px 0px;
  }

  @media (max-width: 992px) {
    margin: 96px 0px;
  }

  @media (max-width: 767px) {
    margin: 64px 0px;
  }

  @media (max-width: 576px) {
    margin: 32px 0px;
  }
`

const sectionBox = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  padding: 60px;

  @media (max-width: 992px) {
    padding: 30px;
  }
`

const HowItWorksSection = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <section id="how-it-works" css={sectionContainer}>
      <div css={container}>
        <div css={sectionBox}>
          <div css={row}>
            <div css={colSm12}>
              <h2 css={primaryHeading}>{t('__home_howitworks_title__')}</h2>
              <p css={subtitle}>{t('__home_howitworks_subtitle__')}</p>
            </div>
          </div>
          <div css={row}>
            <div css={colLg3}>
              <div css={stepNumber}>1</div>
              <div css={stepName}>{t('__home_howitworks_step_1_title__')}</div>
              <div css={stepContent}>
                {t('__home_howitworks_step_1_name__')}
              </div>
            </div>
            <div css={colLg3}>
              <div css={stepNumber}>2</div>
              <div css={stepName}>{t('__home_howitworks_step_2_title__')}</div>
              <div css={stepContent}>
                {t('__home_howitworks_step_2_name__')}
              </div>
            </div>
            <div css={colLg3}>
              <div css={stepNumber}>3</div>
              <div css={stepName}>{t('__home_howitworks_step_3_title__')}</div>
              <div css={stepContent}>
                {t('__home_howitworks_step_3_name__')}
              </div>
            </div>
            <div css={colLg3}>
              <div css={stepNumber}>4</div>
              <div css={stepName}>{t('__home_howitworks_step_4_title__')}</div>
              <div css={stepContent}>
                {t('__home_howitworks_step_4_name__')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HowItWorksSection
