/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'

import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import colMd12 from '@waiterio/styles/bootstrap/colMd12.js'
import {
  bannerHeadingContainer,
  bannerTitle,
  bannerSubtitle,
} from '@waiterio/www/styles/banner.js'
import button from '@waiterio/www/styles/button.js'

const BannerSectionWithCoverImage = ({
  title,
  subtitle,
  heroImageUrl,
  imageAlt,
  buttonText,
  buttonLink = 'https://app.waiterio.com/signup',
}) => (
  <header css={{ position: 'relative', padding: '20px 0', marginBottom: 15 }}>
    <picture>
      <source
        type="image/webp"
        media="(min-width: 1920px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 960px)"
        srcSet={heroImageUrl.replace('.jpg', '-1920w.webp')}
      />
      <source
        type="image/webp"
        media="(min-width: 1600px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 800px)"
        srcSet={heroImageUrl.replace('.jpg', '-1600w.webp')}
      />
      <source
        type="image/webp"
        media="(min-width: 1366px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 683px)"
        srcSet={heroImageUrl.replace('.jpg', '-1366w.webp')}
      />
      <source
        type="image/webp"
        media="(min-width: 1024px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 512px)"
        srcSet={heroImageUrl.replace('.jpg', '-1024w.webp')}
      />
      <source
        type="image/webp"
        media="(min-width: 768px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 384px)"
        srcSet={heroImageUrl.replace('.jpg', '-768w.webp')}
      />
      <source
        type="image/webp"
        srcSet={heroImageUrl.replace('.jpg', '-640w.webp')}
      />
      <source
        type="image/webp"
        media="(min-width: 1920px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 960px)"
        srcSet={heroImageUrl.replace('.jpg', '-1920w.jpg')}
      />
      <source
        type="image/jpeg"
        media="(min-width: 1600px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 800px)"
        srcSet={heroImageUrl.replace('.jpg', '-1600w.jpg')}
      />
      <source
        type="image/jpeg"
        media="(min-width: 1366px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 683px)"
        srcSet={heroImageUrl.replace('.jpg', '-1366w.jpg')}
      />
      <source
        type="image/jpeg"
        media="(min-width: 1024px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 512px)"
        srcSet={heroImageUrl.replace('.jpg', '-1024w.jpg')}
      />
      <source
        type="image/jpeg"
        media="(min-width: 768px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 384px)"
        srcSet={heroImageUrl.replace('.jpg', '-768w.jpg')}
      />
      <source
        type="image/jpeg"
        srcSet={heroImageUrl.replace('.jpg', '-640w.jpg')}
      />

      <img
        css={{
          height: '100%',
          position: 'absolute',
          top: 0,
          padding: 0,
          objectFit: 'cover',
        }}
        src={heroImageUrl.replace('.jpg', '-1024w.jpg')}
        alt={imageAlt}
      />
    </picture>

    <div
      css={{
        position: 'absolute',
        top: 0,
        height: '100%',
        width: '100%',
        backgroundColor: 'rgb(33, 37, 41)',
        opacity: 0.75,
      }}
    />
    <div css={container}>
      <div css={bannerHeadingContainer}>
        <div css={row}>
          <div css={colMd12}>
            <h1 css={bannerTitle}>{title}</h1>
            <h2 css={bannerSubtitle}>{subtitle}</h2>
            <a href={buttonLink} css={button}>
              {buttonText}
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
)

export default BannerSectionWithCoverImage
