/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import useArticles from '@polyblog/react/useArticles.js'
import blogSlugs from '@waiterio/blog/slugs.js'
import colLg4 from '@waiterio/styles/bootstrap/colLg4.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import heading from '@waiterio/www/styles/heading.js'

const blogSection = css`
  background-color: #f5f7fb;
  padding: 100px 0;

  @media (max-width: 1199px) {
    padding: 80px 0;
  }

  @media (max-width: 767px) {
    padding: 50px 0;
  }
`

const articleBox = css`
  color: #666666;
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
  display: block;
  transition: all 0.25s ease-in-out;
  :hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transform: translateY(-10px);
    color: var(--color-primary);
    fill: var(--color-primary);
    h5 {
      color: var(--color-primary);
    }
  }
`

const articleDetails = css`
  padding: 20px 15px 15px;
`

const articleTitle = css`
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 10px;
`

const articleContent = css`
  margin-bottom: 16px;
  margin-top: 0;
`

const BlogSection = ({ locale }) => {
  const { t } = useTranslation(locale)

  let { data: articles } = useArticles({
    blogId: 'f4dd348fa8f16d3c95941c50',
    locale: blogSlugs[locale] ? locale : 'en',
    limit: 3,
    published: true,
    sortField: 'lastRewriteTime',
    sortDirection: 'DESC',
    fields: [
      'author',
      'coverUrl',
      'creationTime',
      'description',
      'lastRewriteTime',
      'locale',
      'slug',
      'title',
      'url',
    ],
  })

  if (articles) {
    articles = articles.map(article => {
      let blogSlug = blogSlugs[article.locale]

      return {
        ...article,
        url: `/blog/${article.locale}/${blogSlug}/${article.slug}/`,
      }
    })
  }

  return (
    <section id="blog" css={blogSection}>
      <div css={container}>
        <div css={row}>
          <div css={colSm12}>
            <div
              css={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'var(--color-primary)',
                padding: '30px 0px',
              }}
            >
              <h2 css={heading}>{t('Recent articles')}</h2>
            </div>
          </div>
        </div>
        <div css={row}>
          {articles?.map(({ slug, coverUrl, description, title, url }) => (
            <div key={slug} css={[colSm12, colLg4, { marginTop: 30 }]}>
              <a css={articleBox} href={url}>
                <picture>
                  <source
                    type="image/webp"
                    media="(-webkit-min-device-pixel-ratio: 2)"
                    srcSet={coverUrl?.replace('.jpg', '-768w.webp')}
                  />
                  <source
                    type="image/webp"
                    srcSet={coverUrl?.replace('.jpg', '-640w.webp')}
                  />
                  <source
                    type="image/jpeg"
                    media="(-webkit-min-device-pixel-ratio: 2)"
                    srcSet={coverUrl?.replace('.jpg', '-768w.jpg')}
                  />
                  <source
                    type="image/jpeg"
                    srcSet={coverUrl?.replace('.jpg', '-640w.jpg')}
                  />
                  <img
                    src={coverUrl?.replace('.jpg', '-640w.jpg')}
                    alt={title}
                  />
                </picture>
                <div css={articleDetails}>
                  <h5 css={articleTitle}>{title}</h5>
                  <p css={articleContent}>{description}</p>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default BlogSection
