/* Copyright 2013 - 2024 Waiterio LLC */
import IS_DEVELOPMENT from './IS_DEVELOPMENT.js'
import IS_STAGING from './IS_STAGING.js'
import IS_TESTING from './IS_TESTING.js'
import IS_BROWSER from './IS_BROWSER.js'
import IS_NODE from './IS_NODE.js'

export default function getPolyblogApiUrl() {
  let url = 'https://api.polyblog.io'

  if (IS_BROWSER && window.POLYBLOG_API_URL === '{{{POLYBLOG_API_URL}}}') {
    delete window.POLYBLOG_API_URL
  }

  if (IS_NODE && process.env.POLYBLOG_API_URL === '{{{POLYBLOG_API_URL}}}') {
    delete process.env.POLYBLOG_API_URL
  }

  if (IS_BROWSER && window.POLYBLOG_API_URL) {
    url = `${window.POLYBLOG_API_URL}`
  } else if (IS_NODE && process.env.POLYBLOG_API_URL) {
    url = process.env.POLYBLOG_API_URL
  } else if (IS_STAGING) {
    url = 'https://api.polyblog-staging.io'
  } else if (IS_DEVELOPMENT || IS_TESTING) {
    // url = 'https://api.polyblog-staging.io';
    // url = 'http://192.168.1.64:7300';
    url = 'http://localhost:7300'
  }

  // if (IS_STAGING || IS_DEVELOPMENT) {
  //   console.debug('Polyblog.url = ' + url)
  // }

  return url
}
