/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'
import { Navigate } from 'react-router-dom'
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'

export const paths = [{ path: '/food-truck-pos-system/' }]

const FoodTruckPosRedirect = () => {
  const locale = getBrowserLanguage() || 'en'
  return <Navigate exact to={`/${locale}/food-truck-pos-system/`} />
}

export default FoodTruckPosRedirect
