/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  AED: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 588,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 1188,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 1788,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 99,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 149,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 199,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  ALL: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 1188,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 2280,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 3480,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 190,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 290,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 390,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  AOA: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 34800,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 70800,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 106800,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 5900,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 8900,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 11900,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  ARS: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 18000,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 34800,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 58800,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 2900,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 4900,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 6900,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  AUD: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 228,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 468,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 708,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 39,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 59,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 79,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  BAM: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 180,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 348,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 588,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 29,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 49,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 69,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  BDT: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 7080,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 14280,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 21480,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 1190,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 1790,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 2390,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  BGN: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 180,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 348,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 588,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 29,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 49,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 69,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  BOB: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 708,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 1428,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 2148,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 119,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 179,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 239,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  BRL: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 468,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 948,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 1428,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 79,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 119,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 159,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  BWP: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 1188,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 2280,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 3480,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 190,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 290,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 390,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  CAD: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 468,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 708,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 948,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 59,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 79,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 99,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  CHF: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 708,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 1068,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 1428,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 89,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 119,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 149,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  CLP: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 94800,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 190800,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 286800,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 15900,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 23900,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 31900,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  CNY: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 948,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 1908,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 2868,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 159,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 239,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 319,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  COP: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 276000,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 636000,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 996000,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 53000,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 83000,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 113000,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  CRC: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 82800,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 166800,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 250800,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 13900,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 20900,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 27900,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  CZK: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 3480,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 7080,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 10680,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 590,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 890,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 1190,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  DKK: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 2280,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 4680,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 7080,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 390,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 590,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 790,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  DOP: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 5880,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 11880,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 17880,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 990,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 1490,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 1990,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  DZD: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 8280,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 16680,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 25080,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 1390,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 2090,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 2790,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  EGP: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 3480,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 5880,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 8280,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 150,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 290,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 490,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  ETB: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 2280,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 4680,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 7080,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 390,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 590,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 790,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  EUR: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 348,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 588,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 828,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 49,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 69,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 89,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  GBP: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 348,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 588,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 828,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 49,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 69,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 89,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  GTQ: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 948,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 1908,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 2868,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 159,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 239,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 319,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  HKD: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 1428,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 2868,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 4308,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 239,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 359,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 479,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  HNL: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 2280,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 4680,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 7080,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 390,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 590,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 790,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  HRK: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 948,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 1908,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 2868,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 159,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 239,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 319,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  HUF: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 34800,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 70800,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 106800,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 5900,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 8900,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 11900,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  IDR: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 1068000,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 2148000,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 3228000,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 179000,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 269000,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 359000,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  ILS: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 948,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 1908,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 2868,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 159,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 239,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 319,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  INR: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 9480,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 19080,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 28680,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 1590,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 2390,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 3190,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  JMD: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 18000,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 34800,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 58800,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 2900,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 4900,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 6900,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  JPY: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 22800,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 46800,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 70800,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 3900,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 5900,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 7900,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  KRW: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 228000,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 468000,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 708000,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 39000,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 59000,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 79000,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  KZT: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 22800,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 46800,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 70800,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 3900,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 5900,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 7900,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  LKR: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 11880,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 22800,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 34800,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 1900,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 2900,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 3900,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  MAD: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 948,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 1908,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 2868,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 159,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 239,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 319,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  MXN: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 4680,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 7080,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 9480,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 590,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 790,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 990,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  MYR: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 588,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 1188,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 1788,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 99,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 149,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 199,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  NAD: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 1308,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 2628,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 3948,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 219,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 329,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 439,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  NGN: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 28680,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 57480,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 86280,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 4790,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 7190,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 9590,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  NIO: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 2628,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 5268,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 7908,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 439,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 659,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 879,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  NOK: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 2280,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 4680,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 7080,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 390,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 590,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 790,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  NPR: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 9480,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 19080,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 28680,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 1590,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 2390,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 3190,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  NZD: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 348,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 708,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 1068,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 59,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 89,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 119,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  PEN: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 348,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 708,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 1068,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 59,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 89,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 119,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  PHP: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 4680,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 9480,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 14280,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 790,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 1190,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 1590,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  PKR: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 8280,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 16680,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 25080,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 1390,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 2090,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 2790,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  PLN: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 468,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 948,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 1428,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 79,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 119,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 159,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  PYG: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 588000,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 1188000,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 1788000,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 99000,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 149000,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 199000,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  QAR: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 588,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 1188,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 1788,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 99,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 149,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 199,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  RON: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 468,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 948,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 1428,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 79,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 119,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 159,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  RSD: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 10680,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 21480,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 32280,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 1790,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 2690,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 3590,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  RUB: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 9480,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 19080,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 28680,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 1590,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 2390,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 3190,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  SAR: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 468,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 948,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 1428,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 79,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 119,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 159,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  SEK: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 1800,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 3480,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 5880,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 290,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 490,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 690,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  SGD: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 348,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 708,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 1068,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 59,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 89,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 119,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  THB: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 2988,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 5988,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 8988,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 499,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 749,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 999,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  TRY: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 348,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 708,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 1068,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 59,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 89,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 119,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  TWD: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 5880,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 11880,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 17880,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 990,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 1490,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 1990,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  UAH: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 2148,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 4308,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 6468,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 359,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 539,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 719,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  USD: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 468,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 708,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 948,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 59,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 79,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 99,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  UYU: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 5508,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 11028,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 16548,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 919,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 1379,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 1839,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  VND: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 2028000,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 4068000,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 6108000,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 339000,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 509000,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 679000,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  XOF: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 52680,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 105480,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 158400,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 8790,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 13200,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 17600,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
  ZAR: [
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Small',
      amount: 1548,
      maxNumberOfOrders: 600,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Medium',
      amount: 3108,
      maxNumberOfOrders: 1200,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Large',
      amount: 4668,
      maxNumberOfOrders: null,
      billingFrequency: 'YEARLY',
    },
    {
      name: 'Free',
      amount: 0,
      maxNumberOfOrders: 100,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Small',
      amount: 259,
      maxNumberOfOrders: 600,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Medium',
      amount: 389,
      maxNumberOfOrders: 1200,
      billingFrequency: 'MONTHLY',
    },
    {
      name: 'Large',
      amount: 520,
      maxNumberOfOrders: null,
      billingFrequency: 'MONTHLY',
    },
  ],
}
