/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IconMaintenance = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-maintenance'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 512 512"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="m323.511 198.076c-9.995-5.783-22.511 1.458-22.511 12.984v66.44l-45 33.75-45-33.75v-66.44c0-11.546-12.534-18.758-22.511-12.984-41.629 24.081-67.489 68.884-67.489 116.924 0 45.091 22.78 87.328 60 112.257v69.743c0 8.284 6.716 15 15 15h120c8.284 0 15-6.716 15-15v-69.743c37.22-24.929 60-67.166 60-112.257 0-48.04-25.86-92.843-67.489-116.924z" />
      <path d="m467 0h-422c-24.813 0-45 20.187-45 45v75h512v-75c0-24.813-20.187-45-45-45zm-391 90c-8.284 0-15-6.716-15-15s6.716-15 15-15 15 6.716 15 15-6.716 15-15 15zm60 0c-8.284 0-15-6.716-15-15s6.716-15 15-15 15 6.716 15 15-6.716 15-15 15zm60 0c-8.284 0-15-6.716-15-15s6.716-15 15-15 15 6.716 15 15-6.716 15-15 15zm240 0h-90c-8.284 0-15-6.716-15-15s6.716-15 15-15h90c8.284 0 15 6.716 15 15s-6.716 15-15 15z" />
      <path d="m0 435c0 24.813 20.187 45 45 45h106v-37.722c-37.842-31.215-60-77.68-60-127.278 0-58.714 31.6-113.467 82.467-142.892 13.891-8.04 31.066-8.073 45.013-.03 13.893 8.012 22.52 22.949 22.52 38.982v51.44l15 11.25 15-11.25v-51.44c0-16.033 8.627-30.97 22.516-38.98 13.93-8.034 31.101-8.023 45.02.03 50.864 29.423 82.464 84.176 82.464 142.89 0 49.598-22.158 96.063-60 127.278v37.722h106c24.813 0 45-20.187 45-45v-285h-512z" />
    </svg>
  )
}

export default IconMaintenance
