/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint react/no-unescaped-entities: ["error", {"forbid": ['>', '"', '}'] }] */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import getLocale from '@waiterio/geo-client/getLocale.js'
import getCurrencyByLanguage from '@monorepo/shared/currencies/getCurrencyByLanguage.js'
import getCurrencySymbol from '@monorepo/shared/currencies/getCurrencySymbol.js'
import getCurrencyNameForCurrenciesWithRedundantSymbols from '@monorepo/shared/currencies/getCurrencyNameForCurrenciesWithRedundantSymbols.js'
import colLg3 from '@waiterio/styles/bootstrap/colLg3.js'
import colMd6 from '@waiterio/styles/bootstrap/colMd6.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import currencies2plans from '@waiterio/www/currencies2plans.js'

const commissionsSection = css`
  background-color: #f5f7fb;
  padding: 100px 0;
  text-align: center;

  @media (max-width: 1199px) {
    padding: 80px 0;
  }

  @media (max-width: 767px) {
    padding: 50px 0;
  }
`

const headingTitle = css`
  margin-bottom: 50px;

  @media (max-width: 992px) {
    margin-bottom: 0px;
  }

  @media (max-width: 767px) {
    margin-bottom: 0px;
  }
`

const textHeading = css`
  color: var(--color-primary);
  margin-bottom: 14px;
  margin-top: 28px;
  line-height: 46px;
  font-size: 38px;
  text-transform: capitalize;
  text-align: center;

  @media (max-width: 992px) {
    font-size: 32px;
    line-height: 38px;
  }

  @media (max-width: 768px) {
    margin-top: 28px;
    font-size: 26px;
    line-height: 32px;
  }
`

const headingSubtitle = css`
  margin-bottom: 50px;
  padding: 0 80px;
  @media (max-width: 992px) {
    margin-bottom: 20px;
    padding: 0;
  }
`

const planBox = css`
  background: white;
  border: 1px solid #ededed;
  overflow: hidden;
`

const planHeader = css`
  background-color: #3e91d2;
  border-radius: 0% 0% 50% 50% / 0% 0% 33% 33%;
  color: white;
  display: flex;
  flex-direction: column;
  margin: 0 -33px;
  padding: 35px 20px;
`

const planPrice = css`
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  margin-inline-start: -25px;
`

const planCurrencySymbol = css`
  font-size: 24px;
  font-weight: 400;
  padding: 5px;
  vertical-align: super;
  text-transform: lowercase;
`

const planName = css`
  letter-spacing: 6px;
  text-transform: uppercase;
`

const planBillingPeriod = css`
  font-size: 16px;
  font-weight: 400;
  text-transform: lowercase;
`

const commissionPerMonth = css`
  line-height: 48px;
  margin-top: 20px;
  text-transform: lowercase;
`

const planFooter = css`
  padding: 30px 0;
  z-index: 9;
`

const planSubscribeButton = css`
  background-color: #3e91d2;
  border-radius: 4px;
  color: white;
  display: inline-block;
  font-size: 16px;
  padding: 12px 36px;
  &:hover,
  &:focus {
    background: var(--color-green);
    color: white;
  }
`

const Plan = ({
  amount,
  billingFrequency,
  countryCode,
  currencyCode,
  currencySymbol,
  name,
  locale,
}) => {
  const { t } = useTranslation(locale)

  if (billingFrequency === 'YEARLY') {
    amount /= 12
  }

  let currencyName =
    getCurrencyNameForCurrenciesWithRedundantSymbols(currencyCode)

  if (currencyCode === 'USD' && countryCode === 'US') {
    currencyName = null
  }

  amount = '' + amount
  let kilo

  if (amount.endsWith('000')) {
    kilo = amount.substring(amount.length - 3, amount.length)
    amount = amount.substring(0, amount.length - 3)
  }

  let commission = ((amount / 100) * 20).toFixed(1)

  return (
    <div css={[colSm12, colMd6, colLg3, { marginTop: 30 }]}>
      <div css={planBox}>
        <div css={planHeader}>
          <span css={planName}>{t(name, locale)}</span>
          <span css={planPrice}>
            <span css={planCurrencySymbol}>{currencySymbol}</span>
            {amount}
            {kilo && <span css={{ fontSize: 18 }}>{kilo}</span>}
          </span>
          <div
            css={{
              textAlign: 'center',
              textTransform: 'uppercase',
              fontSize: 14,
              marginTop: -5,
              marginBottom: 15,
            }}
          >
            {currencyName}
          </div>
          <span css={planBillingPeriod}>{t('Per Month')}</span>
        </div>
        <div css={commissionPerMonth}>{t('Your commission')}</div>
        <div css={commissionPerMonth}>
          {currencySymbol}
          {commission} {t('Per Month')}
        </div>
        <div css={planFooter}>
          <a
            css={planSubscribeButton}
            href="mailto://info@waiterio.com"
            target="_blank"
            rel="noreferrer"
          >
            {t('Sign up')}
          </a>
        </div>
      </div>
    </div>
  )
}

const CommissionsSection = ({ locale }) => {
  locale = locale || 'en'
  const { t } = useTranslation(locale)
  let [loading, setLoading] = useState()
  let currencyCodeDefault = getCurrencyByLanguage(locale) || 'USD'
  let currencySymbolDefault = getCurrencySymbol(currencyCodeDefault)
  let [countryCode, setCountryCode] = useState()
  let [currencyCode, setCurrencyCode] = useState(currencyCodeDefault)
  let [currencySymbol, setCurrencySymbol] = useState(currencySymbolDefault)
  let [selectedBillingFrequency, setSelectedBillingFrequency] = useState()

  useEffect(() => {
    const getCurrency = async () => {
      try {
        if (!countryCode) {
          setLoading(true)

          let { countryCode, currencyCode, currencySymbol } = await getLocale()

          setCountryCode(countryCode)
          setCurrencyCode(currencyCode)
          setCurrencySymbol(currencySymbol)
          setLoading(false)
        }
      } catch (error) {
        console.log('error', error)
        setLoading(false)
      }
    }

    getCurrency()
  }, [countryCode])

  let plans = []
  let billingFrequencies = []

  if (currencyCode && currencySymbol && currencies2plans[currencyCode]) {
    plans = currencies2plans[currencyCode].map(plan => ({
      ...plan,
      currencySymbol,
    }))
    billingFrequencies = [
      ...new Set(plans.map(({ billingFrequency }) => billingFrequency)),
    ]

    if (!selectedBillingFrequency) {
      if (billingFrequencies.includes('YEARLY')) {
        selectedBillingFrequency = 'YEARLY'
      } else if (billingFrequencies.includes('MONTHLY')) {
        selectedBillingFrequency = 'MONTHLY'
      }
    }
    plans = plans.filter(
      ({ billingFrequency }) => billingFrequency === selectedBillingFrequency,
    )
    plans = plans.filter(({ amount }) => amount > 0)
  }

  return (
    <section id="commissions" css={commissionsSection}>
      <div css={container}>
        <div css={row}>
          <div css={colSm12}>
            <div css={headingTitle}>
              <h3 css={textHeading}>
                20% {t('__referral_commissions_title__')}
              </h3>
              <p css={headingSubtitle}>
                {t('__referral_commissions_content__')}
              </p>
            </div>
          </div>
        </div>
        {loading && <div className="loader" />}
        {!loading && billingFrequencies.length > 1 && (
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                margin: 16,
                boxSizing: 'border-box',
                marginTop: 29,
                borderRadius: '4px',
                border: '2px solid var(--color-primary)',
              }}
            >
              <div
                css={{
                  height: '100%',
                  padding: '12px 16px',
                  textTransform: 'uppercase',
                  cursor:
                    selectedBillingFrequency === 'YEARLY'
                      ? 'default'
                      : 'inherit',
                  color:
                    selectedBillingFrequency === 'YEARLY'
                      ? 'white'
                      : 'var(--color-primary)',
                  backgroundColor:
                    selectedBillingFrequency === 'YEARLY'
                      ? 'var(--color-primary)'
                      : 'default',
                }}
                onClick={() => setSelectedBillingFrequency('YEARLY')}
              >
                {t('Billed yearly')}
              </div>
              <div
                css={{
                  height: '100%',
                  padding: '12px 16px',
                  textTransform: 'uppercase',
                  cursor:
                    selectedBillingFrequency === 'MONTHLY'
                      ? 'default'
                      : 'inherit',
                  color:
                    selectedBillingFrequency === 'MONTHLY'
                      ? 'white'
                      : 'var(--color-primary)',
                  backgroundColor:
                    selectedBillingFrequency === 'MONTHLY'
                      ? 'var(--color-primary)'
                      : 'default',
                }}
                onClick={() => setSelectedBillingFrequency('MONTHLY')}
              >
                {t('Billed monthly')}
              </div>
            </div>
          </div>
        )}
        {!loading && (
          <div css={[row, { justifyContent: 'center' }]}>
            {plans.map(
              ({
                amount,
                billingFrequency,
                currencySymbol,
                name,
                maxNumberOfOrders,
              }) => (
                <Plan
                  key={name}
                  amount={amount}
                  billingFrequency={billingFrequency}
                  countryCode={countryCode}
                  currencyCode={currencyCode}
                  currencySymbol={currencySymbol}
                  locale={locale}
                  maxNumberOfOrders={maxNumberOfOrders}
                  name={name}
                />
              ),
            )}
          </div>
        )}
      </div>
    </section>
  )
}

export default CommissionsSection
