/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IconWindows = props => {
  const { className, width = 30, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-windows'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 448 512"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M0 93.7l183.6-25.3v177.4H0V93.7zm0 324.6l183.6 25.3V268.4H0v149.9zm203.8 28L448 480V268.4H203.8v177.9zm0-380.6v180.1H448V32L203.8 65.7z" />
    </svg>
  )
}

export default IconWindows
