/* Copyright 2013 - 2024 Waiterio LLC */
import * as Sentry from '@sentry/browser'
import ENVIRONMENT from '@monorepo/env/ENVIRONMENT.js'
import IS_DEVELOPMENT from '@monorepo/env/IS_DEVELOPMENT.js'
import IS_TESTING from '@monorepo/env/IS_TESTING.js'

let sentry

export default function getSentryBrowser() {
  if (!sentry && !IS_DEVELOPMENT && !IS_TESTING) {
    let dsn

    if (typeof window !== 'undefined') {
      dsn = window.SENTRY_DSN

      if (!dsn) {
        console.warn('Missing window.SENTRY_DSN')
      }
    }

    Sentry.init({
      dsn,
      environment: ENVIRONMENT,
    })

    sentry = Sentry
  }

  return sentry
}
