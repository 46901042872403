/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import faviconUrl from '@waiterio/shared/faviconUrl.js'
import BannerSection from '@waiterio/www/pages/BannerSectionWithCoverImage.js'
import OverviewSection from '@waiterio/www/pages/FoodTruckPosPage/OverviewSection.js'
import ClosingSection from '@waiterio/www/pages/ClosingSection.js'
import ImproveServiceSection from '@waiterio/www/pages/FoodTruckPosPage/ImproveServiceSection.js'
import IncreaseYourSalesSection from '@waiterio/www/pages/FoodTruckPosPage/IncreaseYourSalesSection.js'
import TakeBetterDecisionsSection from '@waiterio/www/pages/FoodTruckPosPage/TakeBetterDecisionsSection.js'
import BlogSection from '@waiterio/www/pages/HomePage/BlogSection.js'
import ReviewsSection from '@waiterio/www/pages/HomePage/ReviewsSection.js'
import languages from '../../languages.js'
import Layout from '../../components/LayoutComponent.js'
import slugsFoodTruckPosSystem from '../../slugsFoodTruckPosSystem.js'

const textHeading = css`
  color: var(--color-primary);
  margin-bottom: 14px;
  margin-top: 28px;
  line-height: 46px;
  font-size: 38px;
  text-transform: capitalize;

  @media (max-width: 992px) {
    font-size: 32px;
    line-height: 38px;
  }

  @media (max-width: 768px) {
    text-align: center;
    margin-top: 28px;
    font-size: 26px;
    line-height: 32px;
  }
`

export const Head = ({ locale }) => {
  const { t } = useTranslation(locale)

  const title = `${t('__food_truck_banner_title__')} | ${t(
    '__food_truck_meta_title__',
    locale,
  )}`
  const description = `${t('__food_truck_meta_description__')}`

  const url = `https://www.waiterio.com/${locale}/${slugsFoodTruckPosSystem[locale]}/`

  return (
    <>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={faviconUrl} />
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={faviconUrl} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={faviconUrl} />
    </>
  )
}

export const paths = languages.map(locale => ({
  path: `/${locale}/${
    slugsFoodTruckPosSystem[locale] || slugsFoodTruckPosSystem.en
  }/`,
  props: {
    locale,
  },
}))

const FoodTruckPosPage = ({ locale = 'en' }) => {
  const { t } = useTranslation(locale)
  let [didScroll, setDidScroll] = useState(false)

  useEffect(() => {
    function onScroll(event) {
      if (window.pageYOffset > 100 && !didScroll) {
        setDidScroll(true)
      } else if (window.pageYOffset < 100 && didScroll) {
        setDidScroll(false)
      }
    }

    window.addEventListener('scroll', onScroll)

    return function cleanup() {
      window.removeEventListener('scroll', onScroll)
    }
  }, [didScroll])

  let headerStyle = {
    boxShadow: '0 0 30px 0 rgba(0,0,0,.1)',
  }

  if (!didScroll) {
    headerStyle = {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    }
  }

  return (
    <Layout headerStyle={headerStyle} locale={locale}>
      <BannerSection
        title={t('__food_truck_banner_title__')}
        subtitle={t('__food_truck_banner_subtitle__')}
        heroImageUrl="https://www.imagelato.com/images/food-truck-hero-cb8f0107.jpg"
        imageAlt="man-setting-up-his-food-truck"
        buttonText={t('Try it for free')}
      />

      <OverviewSection locale={locale} />

      <div
        css={{
          margin: '120px 0px',
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          marginLeft: 15,
          marginRight: 15,
        }}
      >
        <h3
          css={[
            textHeading,
            { color: '#626262', fontWeight: '500', fontSize: 42 },
          ]}
        >
          {t("That's where waiterio comes in", locale)}
        </h3>
      </div>

      <IncreaseYourSalesSection locale={locale} />

      <ImproveServiceSection
        locale={locale}
        imageUrl="https://www.imagelato.com/images/food-truck-improve-service-53d6b85b.jpg"
      />

      <TakeBetterDecisionsSection
        locale={locale}
        imageUrl="https://www.imagelato.com/images/food-truck-take-better-decisions-eb8e70b4.jpg"
      />

      <ReviewsSection locale={locale} />

      <BlogSection locale={locale} />

      <ClosingSection
        sectionId="food-truck"
        locale={locale}
        title={t('__food_truck_closing_title__')}
        subtitle={t('__food_truck_closing_subtitle__')}
      />
    </Layout>
  )
}

export default FoodTruckPosPage
