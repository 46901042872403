/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import colLg6 from '@waiterio/styles/bootstrap/colLg6.js'
import primaryHeading from '@waiterio/www/styles/primaryHeading.js'

const sectionContainer = css`
  margin: 180px 0px;

  @media (max-width: 992px) {
    margin: 120px 0px;
  }

  @media (max-width: 768px) {
    margin: 60px 0px;
  }
`

const contentText = css`
  margin: 0;
  max-width: 400px;

  @media (max-width: 992px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 768px) {
    max-width: 400px;
    text-align: center;
  }
`

const ctaButton = css`
  background-color: var(--color-primary);
  border-radius: 4px;
  color: white;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  padding: 12px 36px;
  text-align: center;
  &:hover,
  &:focus {
    color: white;
    background: var(--color-green);
  }
`

const ImproveServiceSection = ({ locale, imageUrl }) => {
  const { t } = useTranslation(locale)
  return (
    <section css={sectionContainer}>
      <div css={container}>
        <div css={row}>
          <div css={[colSm12, colLg6]}>
            <picture>
              <source
                type="image/webp"
                media="(min-width: 1024px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 512px)"
                srcSet={imageUrl.replace('.jpg', '-1024w.webp')}
              />
              <source
                type="image/webp"
                media="(min-width: 768px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 384px)"
                srcSet={imageUrl.replace('.jpg', '-768w.webp')}
              />
              <source
                type="image/webp"
                srcSet={imageUrl.replace('.jpg', '-640w.webp')}
              />

              <source
                type="image/jpg"
                media="(min-width: 1024px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 512px)"
                srcSet={imageUrl.replace('.jpg', '-1024w.jpg')}
              />
              <source
                type="image/jpg"
                media="(min-width: 768px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 384px)"
                srcSet={imageUrl.replace('.jpg', '-768w.jpg')}
              />
              <source
                type="image/jpg"
                srcSet={imageUrl.replace('.jpg', '-640w.jpg')}
              />

              <img
                css={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  maxHeight: 680,

                  '@media(max-width: 992px)': {
                    maxHeight: 500,
                  },
                  '@media(max-width: 768px)': {
                    maxHeight: 400,
                  },
                  '@media(max-width: 479px)': {
                    maxHeight: 350,
                  },
                }}
                src={imageUrl.replace('.jpg', '-1024w.jpg')}
                alt="food-truck-owner-serving-food"
              />
            </picture>
          </div>
          <div css={[colSm12, colLg6]}>
            <div css={{ '@media(min-width: 993px)': { marginLeft: 20 } }}>
              <h2
                css={[
                  primaryHeading,
                  {
                    '@media(max-width: 992px)': {
                      textAlign: 'center',
                      marginTop: 32,
                    },
                  },
                ]}
              >
                {t('__food_truck_improveservice_title__')}
              </h2>
              <p
                css={[
                  contentText,
                  { '@media(max-width: 992px)': { textAlign: 'center' } },
                ]}
              >
                {t('__food_truck_improveservice_subtitle__')}
              </p>
              <div
                css={{
                  marginTop: 14,
                  display: 'flex',
                  flexWrap: 'wrap',
                  width: '100%',
                  '@media(max-width: 992px)': {
                    flexDirection: 'column',
                    marginTop: 28,
                    div: {
                      marginRight: 0,
                      width: '100%',
                    },
                  },
                }}
              >
                <div
                  css={{
                    width: 250,
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 14,
                    paddingBottom: 14,
                  }}
                >
                  <h4 css={{ marginBottom: 5 }}>
                    {t(
                      '__food_truck_improveservice_increaserepeatbusiness_title__',
                      locale,
                    )}
                  </h4>
                  <p css={{ margin: 0 }}>
                    {t(
                      '__food_truck_improveservice_increaserepeatbusiness_content__',
                      locale,
                    )}
                  </p>
                </div>

                <div
                  css={{
                    width: 250,
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 14,
                    paddingBottom: 14,
                  }}
                >
                  <h4 css={{ marginBottom: 5 }}>
                    {t(
                      '__food_truck_improveservice_reduceerrors_title__',
                      locale,
                    )}
                  </h4>
                  <p css={{ margin: 0 }}>
                    {t(
                      '__food_truck_improveservice_reduceerrors_content__',
                      locale,
                    )}
                  </p>
                </div>

                <div
                  css={{
                    width: 250,
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 14,
                    paddingBottom: 14,
                  }}
                >
                  <h4 css={{ marginBottom: 5 }}>
                    {t(
                      '__food_truck_improveservice_improveefficiency_title__',
                      locale,
                    )}
                  </h4>
                  <p css={{ margin: 0 }}>
                    {t(
                      '__food_truck_improveservice_improveefficiency_content__',
                      locale,
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div css={[row, { marginTop: 30 }]}>
          <div css={colSm12}>
            <div css={{ display: 'flex', justifyContent: 'center' }}>
              <a href="https://app.waiterio.com/signup" css={ctaButton}>
                {t('Try it for free')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ImproveServiceSection
