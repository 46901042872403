/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css } from '@emotion/react'

export const bannerSection = css`
  background: #f5f7fb;
  padding: 100px 0 0;
  position: relative;
  min-height: calc(100vh + 9vw);

  @media (max-width: 1199px) {
    padding: 80px 0 0;
  }

  @media (max-width: 767px) {
    padding: 50px 0 0;
  }
`

export const bannerHeadingContainer = css`
  padding: 200px 0 150px;
  text-align: center;

  @media (max-width: 767px) {
    padding: 200px 0;
  }
`

export const bannerBackground = css`
  position: absolute;
  top: 0;
  background: var(--color-primary);
  min-height: 600px;
  height: calc(100vh + 9vw);
  width: 100%;
`

export const bannerForeground = css`
  position: relative;
  padding-top: 21.5vh;
  text-align: center;
`

export const bannerTitle = css`
  color: white;
  font-size: 64px;
  font-weight: 800;
  line-height: normal;
  text-transform: capitalize;

  @media (max-width: 992px) {
    font-size: 54px;
  }

  @media (max-width: 767px) {
    font-size: 44px;
  }

  @media (max-width: 479px) {
    font-size: 36px;
  }
`

export const bannerSubtitle = css`
  color: white;
  font-size: 16px;
  font-weight: normal;
  margin: 0 100px 20px 100px;
  padding: 15px 50px 0 50px;
  text-align: center;

  @media (max-width: 992px) {
    margin: 20px 0 20px 0;
    padding: 0;
  }
`
