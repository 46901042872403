/* Copyright 2013 - 2024 Waiterio LLC */
import getCookie from '@monorepo/shared/getCookie.js'
import setCookie from '@monorepo/shared/setCookie.js'

const ReferrerCookie = () => {
  if (typeof window !== 'undefined') {
    let referrerCookie = getCookie('referrer')
    let referrersCookie = getCookie('referrers')

    let referrer = window?.document?.referrer
    let referrerQueryParameter

    if (typeof window !== 'undefined') {
      referrerQueryParameter =
        new URLSearchParams(window?.location?.search).get('referrer') || ''
      referrerQueryParameter = referrerQueryParameter
        .replace(/https?:\/\//, '')
        .replace(/http?:\/\//, '')
        .replace(/\/$/, '')

      if (referrerQueryParameter) {
        referrer = decodeURIComponent(referrerQueryParameter)
      }
    }

    if (referrer) {
      referrer = referrer
        .replace(/https?:\/\//, '')
        .replace(/http?:\/\//, '')
        .replace(/\/$/, '')
    }

    if (!referrerCookie) {
      setCookie('referrer', referrer)
    }

    if (!referrersCookie?.includes(referrer)) {
      referrersCookie += (referrersCookie ? ',' : '') + referrer
      setCookie('referrers', referrersCookie)
    }
  }
}

export default ReferrerCookie
