/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IconAngleLeft = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-angle-left'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 256 512"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z" />
    </svg>
  )
}

export default IconAngleLeft
