/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IconPrint = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-print'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M19.008 7.992h-14.016c-1.656 0-3 1.344-3 3v6h4.008v4.008h12v-4.008h4.008v-6c0-1.656-1.344-3-3-3zM16.008 19.008h-8.016v-5.016h8.016v5.016zM19.008 12c-0.552 0-1.008-0.456-1.008-1.008s0.456-0.984 1.008-0.984c0.552 0 0.984 0.432 0.984 0.984s-0.432 1.008-0.984 1.008zM18 3h-12v4.008h12v-4.008z" />
    </svg>
  )
}

export default IconPrint
