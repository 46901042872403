/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IconCompass = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-compass'

  return (
    <svg
      viewBox="0 0 512 512"
      width={width}
      height={height}
      className={className}
      style={style}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <g>
        <path
          d="M280.5,280.5l-49-49c-0.9-0.9-2.5-0.7-3.2,0.4l-49.5,98.5c-1.1,1.8,1,3.9,2.8,2.8l98.5-49.5
            C281.2,283,281.4,281.4,280.5,280.5z"
        />
        <path
          d="M256,48C141.1,48,48,141.1,48,256c0,114.9,93.1,208,208,208c114.9,0,208-93.1,208-208C464,141.1,370.9,48,256,48z
            M296.6,297.3L137,377.8c-1.8,1.1-3.9-1-2.8-2.8l80.6-159.6c0.2-0.3,0.4-0.5,0.7-0.7L375,134.2c1.8-1.1,3.9,1,2.8,2.8l-80.6,159.6
            C297.1,296.9,296.9,297.1,296.6,297.3z"
        />
      </g>
    </svg>
  )
}

export default IconCompass
