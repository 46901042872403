/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint react/no-unescaped-entities: ["error", {"forbid": ['>', '"', '}'] }] */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import container from '@waiterio/styles/bootstrap/container.js'
import { subtitle, subHeader } from '@waiterio/www/styles/section.js'
import primaryHeading from '@waiterio/www/styles/primaryHeading.js'
import tabletOrderingIcon from '../../images/tablet-ordering.svg'
import tableManagementIcon from '../../images/table-management.svg'
import menuManagementIcon from '../../images/menu-management.svg'
import staffManagementIcon from '../../images/staff-management.svg'
import paymentAndDiscountIcon from '../../images/payment-and-discount.svg'
import reportAnaylsisIcon from '../../images/report-analysis.svg'
import customerSupportIcon from '../../images/customer-support.svg'

const sectionContainer = css`
  margin: 160px 0px;

  @media (max-width: 1199px) {
    margin: 128px 0px;
  }

  @media (max-width: 992px) {
    margin: 96px 0px;
  }

  @media (max-width: 767px) {
    margin: 64px 0px;
  }

  @media (max-width: 576px) {
    margin: 32px 0px;
  }
`

const sectionBox = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  padding: 60px;

  @media (max-width: 992px) {
    padding: 30px;
  }
`

const subSectionContainer = css({
  scrollMarginTop: 120,
  marginTop: 120,
  display: 'flex',
  flex: 1,
  justifyContent: 'space-around',
  '@media(max-width: 992px)': {
    marginTop: 60,
    flexWrap: 'wrap',
    justifyContent: 'center',
    img: {
      order: 2,
      maxWidth: 200,
      marginTop: 40,
    },
  },
})

const FeaturesSection = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <section id="restaurant-pos-system-features" css={sectionContainer}>
      <div css={container}>
        <div css={sectionBox}>
          <h2 css={primaryHeading}>{t('__pos_system_features_title__')}</h2>
          <p css={subtitle}>{t('__pos_system_features_subtitle__')}</p>
          <div id="features-order-management" css={subSectionContainer}>
            <div css={{ maxWidth: 400, margin: '0px 10px' }}>
              <h3 css={subHeader}>
                {t('__pos_system_features_ordermanagement_title__')}
              </h3>
              <ul css={{ textAlign: 'left', marginLeft: -18 }}>
                <li>
                  {t(
                    '__pos_system_features_ordermanagement_bullet_1__',
                    locale,
                  )}
                </li>
                <li css={{ marginTop: 8 }}>
                  {t(
                    '__pos_system_features_ordermanagement_bullet_2__',
                    locale,
                  )}
                </li>
                <li css={{ marginTop: 8 }}>
                  {t(
                    '__pos_system_features_ordermanagement_bullet_3__',
                    locale,
                  )}
                </li>
                <li css={{ marginTop: 8 }}>
                  {t(
                    '__pos_system_features_ordermanagement_bullet_4__',
                    locale,
                  )}
                </li>
                <li css={{ marginTop: 8 }}>
                  {t(
                    '__pos_system_features_ordermanagement_bullet_5__',
                    locale,
                  )}
                </li>
              </ul>
            </div>
            <img
              src={tabletOrderingIcon}
              alt="taking order on tablet"
              css={{ maxWidth: 250, height: '100%', margin: '0px 10px' }}
            />
          </div>

          <div id="features-table-management" css={subSectionContainer}>
            <img
              src={tableManagementIcon}
              alt="manage tables in restaurant"
              css={{ maxWidth: 250, height: '100%', margin: '0px 10px' }}
            />
            <div css={{ maxWidth: 400, margin: '0px 10px' }}>
              <h3 css={subHeader}>
                {t('__pos_system_features_tablemanagement_title__')}
              </h3>
              <ul css={{ textAlign: 'left', marginLeft: -18 }}>
                <li>
                  {t(
                    '__pos_system_features_tablemanagement_bullet_1__',
                    locale,
                  )}
                </li>
                <li css={{ marginTop: 8 }}>
                  {t(
                    '__pos_system_features_tablemanagement_bullet_2__',
                    locale,
                  )}
                </li>
                <li css={{ marginTop: 8 }}>
                  {t(
                    '__pos_system_features_tablemanagement_bullet_3__',
                    locale,
                  )}
                </li>
                <li css={{ marginTop: 8 }}>
                  {t(
                    '__pos_system_features_tablemanagement_bullet_4__',
                    locale,
                  )}
                </li>
              </ul>
            </div>
          </div>

          <div id="features-menu-management" css={subSectionContainer}>
            <div css={{ maxWidth: 400, margin: '0px 10px' }}>
              <h3 css={subHeader}>
                {t('__pos_system_features_menumanagement_title__')}
              </h3>
              <ul css={{ textAlign: 'left', marginLeft: -18 }}>
                <li>{t('__pos_system_features_menumanagement_bullet_1__')}</li>
                <li css={{ marginTop: 8 }}>
                  {t('__pos_system_features_menumanagement_bullet_2__')}
                </li>
                <li css={{ marginTop: 8 }}>
                  {t('__pos_system_features_menumanagement_bullet_3__')}
                </li>
                <li css={{ marginTop: 8 }}>
                  {t('__pos_system_features_menumanagement_bullet_4__')}
                </li>
                <li css={{ marginTop: 8 }}>
                  {t('__pos_system_features_menumanagement_bullet_5__')}
                </li>
                <li css={{ marginTop: 8 }}>
                  {t('__pos_system_features_menumanagement_bullet_6__')}
                </li>
              </ul>
            </div>
            <img
              src={menuManagementIcon}
              alt="manage restaurant menu"
              css={{ maxWidth: 250, height: '100%', margin: '0px 10px' }}
            />
          </div>

          <div id="features-staff-management" css={subSectionContainer}>
            <img
              src={staffManagementIcon}
              alt="manage restaurant staff"
              css={{ maxWidth: 250, height: '100%', margin: '0px 10px' }}
            />
            <div css={{ maxWidth: 400, margin: '0px 10px' }}>
              <h3 css={subHeader}>
                {t('__pos_system_features_staffmanagement_title__')}
              </h3>
              <ul css={{ textAlign: 'left', marginLeft: -18 }}>
                <li>
                  {t(
                    '__pos_system_features_staffmanagement_bullet_1__',
                    locale,
                  )}
                </li>
                <li css={{ marginTop: 8 }}>
                  {t(
                    '__pos_system_features_staffmanagement_bullet_2__',
                    locale,
                  )}
                </li>
                <li css={{ marginTop: 8 }}>
                  {t(
                    '__pos_system_features_staffmanagement_bullet_3__',
                    locale,
                  )}
                </li>
                <li css={{ marginTop: 8 }}>
                  {t(
                    '__pos_system_features_staffmanagement_bullet_4__',
                    locale,
                  )}
                </li>
                <li css={{ marginTop: 8 }}>
                  {t(
                    '__pos_system_features_staffmanagement_bullet_5__',
                    locale,
                  )}
                </li>
              </ul>
            </div>
          </div>

          <div id="features-payment-and-discount" css={subSectionContainer}>
            <div css={{ maxWidth: 400, margin: '0px 10px' }}>
              <h3 css={subHeader}>
                {t('__pos_system_features_paymentanddiscount_title__')}
              </h3>
              <ul css={{ textAlign: 'left', marginLeft: -18 }}>
                <li>
                  {t(
                    '__pos_system_features_paymentanddiscount_bullet_1__',
                    locale,
                  )}
                  <a
                    href="#pos-system-accept-all-payment-methods"
                    css={{ marginLeft: 4 }}
                  >
                    {t('Learn more')}
                  </a>
                </li>
                <li css={{ marginTop: 8 }}>
                  {t(
                    '__pos_system_features_paymentanddiscount_bullet_2__',
                    locale,
                  )}
                </li>
                <li css={{ marginTop: 8 }}>
                  {t(
                    '__pos_system_features_paymentanddiscount_bullet_3__',
                    locale,
                  )}
                </li>
                <li css={{ marginTop: 8 }}>
                  {t(
                    '__pos_system_features_paymentanddiscount_bullet_4__',
                    locale,
                  )}
                </li>
              </ul>
            </div>
            <img
              src={paymentAndDiscountIcon}
              alt="taking credit card payment and giving discount"
              css={{ maxWidth: 250, height: '100%', margin: '0px 10px' }}
            />
          </div>

          <div id="features-sales-report-analysis" css={subSectionContainer}>
            <img
              src={reportAnaylsisIcon}
              alt="report analysis"
              css={{ maxWidth: 250, height: '100%', margin: '0px 10px' }}
            />
            <div css={{ maxWidth: 400, margin: '0px 10px' }}>
              <h3 css={subHeader}>
                {t('__pos_system_features_salesreports_title__')}
              </h3>
              <ul css={{ textAlign: 'left', marginLeft: -18 }}>
                <li>{t('__pos_system_features_salesreports_bullet_1__')}</li>
                <li css={{ marginTop: 8 }}>
                  {t('__pos_system_features_salesreports_bullet_2__')}
                </li>
                <li css={{ marginTop: 8 }}>
                  {t('__pos_system_features_salesreports_bullet_3__')}
                </li>
                <li css={{ marginTop: 8 }}>
                  {t('__pos_system_features_salesreports_bullet_4__')}
                </li>
                <li css={{ marginTop: 8 }}>
                  {t('__pos_system_features_salesreports_bullet_5__')}
                </li>
              </ul>
            </div>
          </div>

          <div id="features-quick-support" css={subSectionContainer}>
            <div
              id="feature-quick-support"
              css={{ maxWidth: 400, margin: '0px 10px' }}
            >
              <h3 css={subHeader}>
                {t('__pos_system_features_quicksupport_title__')}
              </h3>
              <ul css={{ textAlign: 'left', marginLeft: -18 }}>
                <li>{t('__pos_system_features_quicksupport_bullet_1__')}</li>
                <li css={{ marginTop: 8 }}>
                  {t('__pos_system_features_quicksupport_bullet_2__')}
                </li>
                <li css={{ marginTop: 8 }}>
                  {t('__pos_system_features_quicksupport_bullet_3__')}
                  <a href={`/docs/${locale}/`} css={{ marginLeft: 4 }}>
                    {t('Learn more')}
                  </a>
                </li>
                <li css={{ marginTop: 8 }}>
                  {t('__pos_system_features_quicksupport_bullet_4__')}
                </li>
              </ul>
            </div>
            <img
              src={customerSupportIcon}
              alt="giving customer support"
              css={{ maxWidth: 250, height: '100%', margin: '0px 10px' }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturesSection
