/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint react/no-unescaped-entities: ["error", {"forbid": ['>', '"', '}'] }] */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import IconWindows from '@stiloso/icons/IconWindows.js'
import IconAndroid from '@stiloso/icons/IconNewAndroid.js'
import IconApple from '@stiloso/icons/IconFabApple.js'
import container from '@waiterio/styles/bootstrap/container.js'
import { subtitle, subHeader } from '@waiterio/www/styles/section.js'
import primaryHeading from '@waiterio/www/styles/primaryHeading.js'
import imacIcon from '../../images/imac.svg'
import windowsIcon from '../../images/windows.svg'
import androidTabletIcon from '../../images/android-tablet.svg'
import iphoneIcon from '../../images/iphone.svg'

const content = css({
  textAlign: 'left',
  '@media(max-width: 992px)': {
    textAlign: 'center',
  },
})

const sectionContainer = css`
  margin: 160px 0px;

  @media (max-width: 1199px) {
    margin: 128px 0px;
  }

  @media (max-width: 992px) {
    margin: 96px 0px;
  }

  @media (max-width: 767px) {
    margin: 64px 0px;
  }

  @media (max-width: 576px) {
    margin: 32px 0px;
  }
`

const sectionBox = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  padding: 60px;

  @media (max-width: 992px) {
    padding: 30px;
  }
`

const HardwareSection = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <section id="hardware-section" css={sectionContainer}>
      <div css={container}>
        <div css={sectionBox}>
          <h2 css={primaryHeading}>{t('__pos_system_hardware_title__')}</h2>
          <p css={subtitle}>{t('__pos_system_hardware_content__')}</p>

          <div
            css={{
              padding: 60,
              marginTop: 60,
              marginLeft: -60,
              marginRight: -60,
              '@media(max-width: 992px)': {
                padding: 30,
                marginLeft: -30,
                marginRight: -30,
              },
            }}
          >
            <h3 css={subHeader}>
              {t('__pos_system_hardware_anydevice_title__')}
            </h3>
            <p css={content}>
              {t('__pos_system_hardware_anydevice_content__')}
            </p>

            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 60,
                '@media(max-width: 992px)': {
                  marginTop: 30,
                  flexDirection: 'column',
                  justifyContent: 'center',
                  img: {
                    marginTop: 60,
                    maxWidth: 100,
                  },
                },
              }}
            >
              <div css={{ ':hover': { color: '#7D7D7D', fill: '#7D7D7D' } }}>
                <img
                  src={iphoneIcon}
                  alt="hardware supports iphone"
                  css={{ maxWidth: 120, height: 'auto' }}
                />
                <div>
                  <IconApple css={{ width: 45, height: 40 }} />
                  <div>iOS</div>
                </div>
              </div>
              <div css={{ ':hover': { color: '#7D7D7D', fill: '#7D7D7D' } }}>
                <img
                  src={imacIcon}
                  alt="hardware support imac"
                  css={{ maxWidth: 120, height: 'auto' }}
                />
                <div>
                  <IconApple css={{ width: 45, height: 40 }} />
                  <div>Mac OS X</div>
                </div>
              </div>
              <div css={{ ':hover': { color: '#3DDC84', fill: '#3DDC84' } }}>
                <img
                  src={androidTabletIcon}
                  alt="hardware support tablet"
                  css={{ maxWidth: 120, height: 'auto' }}
                />
                <div>
                  <IconAndroid css={{ width: 45, height: 40 }} />
                  <div>Android</div>
                </div>
              </div>
              <div css={{ ':hover': { color: '#00A2ED', fill: '#00A2ED' } }}>
                <img
                  src={windowsIcon}
                  alt="hardware support windows"
                  css={{ maxWidth: 120, height: 'auto' }}
                />
                <div>
                  <IconWindows css={{ width: 45, height: 40 }} />
                  <div>Windows</div>
                </div>
              </div>
            </div>
          </div>

          <div
            css={{
              marginTop: 60,
              '@media(max-width: 992px)': { marginTop: 30, marginBottom: -30 },
            }}
          >
            <h3 css={subHeader}>
              {t('__pos_system_hardware_anyprinter_title__')}
            </h3>
            <p css={content}>
              {t('__pos_system_hardware_anyprinter_content__')}
            </p>

            <div
              css={{
                marginTop: 60,
                display: 'flex',
                justifyContent: 'space-between',
                '@media(max-width: 992px)': {
                  marginTop: 30,
                  flexDirection: 'column',
                  alignItems: 'center',
                  img: {
                    marginTop: 40,
                  },
                },
                '@media(max-width: 576px)': {
                  img: {
                    maxWidth: 200,
                  },
                },
              }}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.amazon.com/Brother-QL-1110NWB-Professional-Wireless-Connectivity/dp/B079G51QT9/ref=sr_1_1?dchild=1&keywords=brother+thermal+printer&qid=1606840083&sr=8-1"
              >
                <picture>
                  <source
                    type="image/webp"
                    srcSet="https://www.imagelato.com/images/thermal-printer-1-3fe0d59e.webp"
                  />
                  <img
                    src="https://www.imagelato.com/images/thermal-printer-1-3fe0d59e.png"
                    alt="waiterio supports wireless thermal printer brother"
                    css={{ maxWidth: 300, height: 'auto' }}
                  />
                </picture>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.amazon.com/Epson-TM-T88V-Thermal-Receipt-Printer/dp/B003JVT1AW/ref=sr_1_1?dchild=1&keywords=epson+thermal+printer&qid=1606839325&sr=8-1"
              >
                <picture>
                  <source
                    type="image/webp"
                    srcSet="https://www.imagelato.com/images/thermal-printer-2-d13a2c0a.webp"
                  />
                  <img
                    src="https://www.imagelato.com/images/thermal-printer-2-d13a2c0a.png"
                    alt="waiterio supports epson thermal printer"
                    css={{ maxWidth: 300, height: 'auto' }}
                  />
                </picture>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.amazon.com/Star-MicronicsTSP143IIU-GRY-US-ECO/dp/B003NE43EY/ref=sr_1_2?dchild=1&keywords=tsp100&qid=1606839790&sr=8-2"
              >
                <picture>
                  <source
                    type="image/webp"
                    srcSet="https://www.imagelato.com/images/thermal-printer-3-430c03d2.webp"
                  />
                  <img
                    src="https://www.imagelato.com/images/thermal-printer-3-430c03d2.png"
                    alt="waiterio supports star micronics thermal printer"
                    css={{ maxWidth: 300, height: 'auto' }}
                  />
                </picture>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HardwareSection
