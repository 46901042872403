/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint max-len: 0 */
/* eslint react/no-unescaped-entities: ["error", {"forbid": ['>', '}'] }] */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import faviconUrl from '@waiterio/shared/faviconUrl.js'
import colLg8 from '@waiterio/styles/bootstrap/colLg8.js'
import colLg10 from '@waiterio/styles/bootstrap/colLg10.js'
import colSm10 from '@waiterio/styles/bootstrap/colSm10.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import Layout from '../components/LayoutComponent.js'
import underlinePartial from '../styles/underlinePartial.js'
import languages from '../languages.js'

const style = `

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: inherit;
  }

  h1 { font-size: 52px; line-height: 58px; }
  h2 { font-size: 40px; line-height: 46px; }
  h3 { font-size: 36px; line-height: 44px; }
  h4 { font-size: 24px; line-height: 24px; }
  h5 { font-size: 20px; line-height: 30px; }
  h6 { font-size: 18px; line-height: 28px; }

  p {
    margin-top: 0;
      margin-bottom: 1rem;
    font-size: 15px;
  }

  ul {
      margin: 0;
      padding: 0;
  }

  li {
      list-style: none;
  }
`

const headingTitle = css`
  margin-bottom: 80px;
  margin-top: 90px;
  text-align: center;
`

const title = css`
  ${underlinePartial}
  font-size: 52px;
  font-weight: 700;
  line-height: 58px;
  margin-bottom: 25px;
  margin-top: 0px;
  text-align: center;
`

export const Head = ({ locale }) => {
  const { t } = useTranslation(locale)

  const title = `${t('Terms and Conditions')} | ${t(
    'Waiterio Restaurant Point of Sale',
    locale,
  )}`
  const description = t('Terms and Conditions', locale)
  const url = `https://www.waiterio.com/${locale}/terms-and-conditions/`

  return (
    <>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={faviconUrl} />
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={faviconUrl} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={faviconUrl} />
    </>
  )
}

export const paths = languages.map(locale => ({
  path: `/${locale}/terms-and-conditions/`,
  props: {
    locale,
  },
}))

const TermsAndConditionsPage = ({ locale }) => (
  <Layout locale={locale}>
    <style>{style}</style>
    <div css={{ height: 90 }} />
    <section>
      <div css={container}>
        <div css={[row, { justifyContent: 'center' }]}>
          <div css={[colSm10, colLg8]}>
            <div css={headingTitle}>
              <h1 css={title}>TERMS OF SERVICE</h1>
              <div>
                <p>
                  Please read these Terms and Conditions ("Terms", "Terms and
                  Conditions") carefully before using the Waiterio mobile
                  application (the "Service") operated by Waiterio LLC ("us",
                  "we", or "our").
                </p>
                <p>
                  Your access to and use of the Service is conditioned on your
                  acceptance of and compliance with these Terms. These Terms
                  apply to all visitors, users and others who access or use the
                  Service.
                </p>
                <p>
                  <strong>
                    By accessing or using the Service you agree to be bound by
                    these Terms. If you disagree with any part of the terms then
                    you may not access the Service.
                  </strong>
                </p>
                <p>Last updated: May 06, 2015</p>
              </div>
            </div>
          </div>
        </div>
        <div css={[row, { justifyContent: 'center' }]}>
          <div css={[colSm12, colLg10]}>
            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              Purchases
            </h4>
            <p>
              <strong>
                You are encouraged to familiarise yourself with your rights
                contained within the Sale of Goods Act 1979, Unfair Contract
                Terms Act 1977 and the Unfair Terms in Consumer Contracts
                Regulations 1999.
              </strong>
            </p>
            <p>
              If you wish to purchase any product or service made available
              through the Service ("Purchase"), you may be asked to supply
              certain information relevant to your Purchase including, without
              limitation, your payment card number, the expiration date of your
              payment card, your billing address, and your shipping information.
            </p>
            <p>
              You represent and warrant that: (i) you have the legal right to
              use any payment card(s) or other payment method(s) in connection
              with any Purchase; and that (ii) the information you supply to us
              is true, correct and complete. You expressly agree that Waiterio
              LLC is not responsible for any loss or damage arising from the
              submission of false or inaccurate information.
            </p>
            <p>
              By submitting such information, you grant us the right to provide
              the information to third parties for purposes of facilitating the
              completion of Purchases.
            </p>

            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              Availability, Errors and Inaccuracies
            </h4>
            <p>
              We are constantly updating our offerings of products and services
              on the Service. The products or services available on our Service
              may be mispriced, described inaccurately, or unavailable, and we
              may experience delays in updating information on the Service and
              in our advertising on other web sites. You expressly agree that
              any such offer of a product or service does not constitute a legal
              offer capable of attracting legal consequences.
            </p>
            <p>
              We cannot and do not guarantee the accuracy or completeness of any
              information, including prices, product images, specifications,
              availability, and services. We reserve the right to change or
              update information and to correct errors, inaccuracies, or
              omissions at any time without prior notice. Section "Availability,
              Errors and Inaccuracies" is without prejudice to existing
              statutory rights.
            </p>

            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              Content
            </h4>
            <p>
              Our Service allows you to post, link, store, share and otherwise
              make available certain information, text, graphics, videos, or
              other material ("Content"). You are responsible for the Content
              that you post to the Service, including its legality, reliability,
              and appropriateness.
            </p>
            <p>
              By posting Content to the Service, you grant us the right and
              license to use, modify, publicly perform, publicly display,
              reproduce, and distribute such Content on and through the Service.
              You retain any and all of your rights to any Content you submit,
              post or display on or through the Service and you are responsible
              for protecting those rights. You agree that this license includes
              the right for us to make your Content available to other users of
              the Service, who may also use your Content subject to these Terms.
            </p>
            <p>
              You represent and warrant that: (i) the Content is yours (you own
              it) or you have the right to use it and grant us the rights and
              license as provided in these Terms, and (ii) the posting of your
              Content on or through the Service does not violate the privacy
              rights, publicity rights, copyrights, contract rights or any other
              rights of any person.
            </p>

            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              Accounts
            </h4>
            <p>
              When you create an account with us, you must provide us
              information that is accurate, complete, and current at all times.
              Failure to do so constitutes a breach of the Terms, which may
              result in immediate termination of your account on our Service.
            </p>
            <p>
              You are responsible for safeguarding the password that you use to
              access the Service and for any activities or actions under your
              password, whether your password is with our Service or a
              third-party service.
            </p>
            <p>
              You agree not to disclose your password to any third party. You
              must notify us immediately upon becoming aware of any breach of
              security or unauthorized use of your account.
            </p>

            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              Intellectual Property
            </h4>
            <p>
              The Service and its original content (excluding Content provided
              by users), features and functionality are and will remain the
              exclusive property of Waiterio LLC and its licensors. The Service
              is protected by copyright, trademark, and other laws of both the
              United Kingdom and foreign countries. Our trademarks and trade
              dress may not be used in connection with any product or service
              without the prior written consent of Waiterio LLC.
            </p>
            <p>
              When you upload content, you give to Waiterio LLC a worldwide,
              non-exclusive, royalty-free, transferable licence (with right to
              sub-licence) to use, reproduce, distribute, prepare derivative
              works of, display, and perform that Content in connection with the
              provision of the Service and otherwise in connection with the
              provision of the Service and Waiterio LLC business.
            </p>

            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              Links To Other Web Sites
            </h4>
            <p>
              Our Service may contain links to third-party web sites or services
              that are not owned or controlled by Waiterio LLC.
            </p>
            <p>
              Waiterio LLC has no control over, and assumes no responsibility
              for, the content, privacy policies, or practices of any third
              party web sites or services. You further acknowledge and agree
              that Waiterio LLC shall not be responsible or liable, directly or
              indirectly, for any damage or loss caused or alleged to be caused
              by or in connection with use of or reliance on any such content,
              goods or services available on or through any such web sites or
              services.
            </p>
            <p>
              We strongly advise you to read the terms and conditions and
              privacy policies of any third-party web sites or services that you
              visit.
            </p>

            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              Termination
            </h4>
            <p>
              We may terminate or suspend your account immediately, without
              prior notice or liability, for any reason whatsoever, including
              without limitation if you breach the Terms.
            </p>
            <p>
              Upon termination, your right to use the Service will immediately
              cease. If you wish to terminate your account, you may simply
              discontinue using the Service.
            </p>
            <p>
              All provisions of the Terms which by their nature should survive
              termination shall survive termination, including, without
              limitation, ownership provisions, warranty disclaimers, indemnity
              and limitations of liability.
            </p>

            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              Indemnification
            </h4>
            <p>
              You agree to defend, indemnify and hold harmless Waiterio LLC and
              its licensee and licensors, and their employees, contractors,
              agents, officers and directors, from and against any and all
              claims, damages, obligations, losses, liabilities, costs or debt,
              and expenses (including but not limited to attorney's fees),
              resulting from or arising out of a) your use and access of the
              Service, by you or any person using your account and password; b)
              a breach of these Terms, or c) Content posted on the Service.
            </p>

            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              Limitation Of Liability
            </h4>
            <p>
              In no event shall Waiterio LLC, nor its directors, employees,
              partners, agents, suppliers, or affiliates, be liable for any
              indirect, incidental, special, consequential or punitive damages,
              including without limitation, loss of profits, data, use,
              goodwill, or other intangible losses, resulting from (i) your
              access to or use of or inability to access or use the Service;
              (ii) any conduct or content of any third party on the Service;
              (iii) any content obtained from the Service; and (iv) unauthorized
              access, use or alteration of your transmissions or content,
              whether based on warranty, contract, tort (including negligence)
              or any other legal theory, whether or not we have been informed of
              the possibility of such damage, and even if a remedy set forth
              herein is found to have failed of its essential purpose.
            </p>

            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              Disclaimer
            </h4>
            <p>
              Your use of the Service is at your sole risk. The Service is
              provided on an "AS IS" and "AS AVAILABLE" basis. The Service is
              provided without warranties of any kind, whether express or
              implied, including, but not limited to, implied warranties of
              merchantability, fitness for a particular purpose,
              non-infringement or course of performance.
            </p>
            <p>
              Waiterio LLC its subsidiaries, affiliates, and its licensors do
              not warrant that a) the Service will function uninterrupted,
              secure or available at any particular time or location; b) any
              errors or defects will be corrected; c) the Service is free of
              viruses or other harmful components; or d) the results of using
              the Service will meet your requirements.
            </p>

            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              Exclusions
            </h4>
            <p>
              Without limiting the generality of the foregoing and
              notwithstanding any other provision of these terms, under no
              circumstances will Waiterio LLC ever be liable to you or any other
              person for any indirect, incidental, consequential, special,
              punitive or exemplary loss or damage arising from, connected with,
              or relating to your use of the Service, these Terms, the subject
              matter of these Terms, the termination of these Terms or
              otherwise, including but not limited to personal injury, loss of
              data, business, markets, savings, income, profits, use,
              production, reputation or goodwill, anticipated or otherwise, or
              economic loss, under any theory of liability (whether in contract,
              tort, strict liability or any other theory or law or equity),
              regardless of any negligence or other fault or wrongdoing
              (including without limitation gross negligence and fundamental
              breach) by Waiterio LLC or any person for whom Waiterio LLC is
              responsible, and even if Waiterio LLC has been advised of the
              possibility of such loss or damage being incurred.
            </p>

            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              Governing Law
            </h4>
            <p>
              These Terms shall be governed and construed in accordance with the
              laws of England and Wales, without regard to its conflict of law
              provisions.
            </p>
            <p>
              Our failure to enforce any right or provision of these Terms will
              not be considered a waiver of those rights. If any provision of
              these Terms is held to be invalid or unenforceable by a court, the
              remaining provisions of these Terms will remain in effect. These
              Terms constitute the entire agreement between us regarding our
              Service, and supersede and replace any prior agreements we might
              have between us regarding the Service.
            </p>

            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              Changes
            </h4>
            <p>
              We reserve the right, at our sole discretion, to modify or replace
              these Terms at any time. If a revision is material we will try to
              provide at least 30 days notice prior to any new terms taking
              effect. What constitutes a material change will be determined at
              our sole discretion.
            </p>
            <p>
              By continuing to access or use our Service after those revisions
              become effective, you agree to be bound by the revised terms. If
              you do not agree to the new terms, you must stop using the
              service.
            </p>

            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              Privacy Policy and Cookie Policy
            </h4>
            <p>
              Please refer to our Privacy Policy and Cookies Policy. You agree
              that they constitute part of these terms. You must read our
              Privacy Policy and Cookies Policy before you use the Service.
            </p>

            <h4 css={{ fontWeight: 700, marginTop: 20, marginBottom: 10 }}>
              Contact Us
            </h4>
            <p>
              If you have any questions about these Terms, please contact us.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default TermsAndConditionsPage
