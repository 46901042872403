/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint max-len: 0 */

import React from 'react'

const IconPapillon = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-papillon'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M9.816 11.976c0-0.384 0-0.768 0-1.152 0-0.576 0.384-0.936 0.936-0.936 0.792 0 1.584 0 2.376 0 0.552 0 0.936 0.36 0.936 0.912 0 0.792 0 1.584 0 2.376 0 0.552-0.384 0.936-0.936 0.936-0.792 0-1.584 0-2.376 0-0.552 0-0.936-0.384-0.936-0.936 0-0.408 0-0.792 0-1.2zM13.656 12.768c1.176 0.432 2.328 0.84 3.504 1.248 0 0 0.024 0 0.024-0.024-0.432-0.192-0.864-0.384-1.296-0.6-0.72-0.312-1.416-0.648-2.112-0.96-0.192-0.072-0.12-0.192-0.144-0.312s0.048-0.168 0.144-0.216c1.752-0.792 3.504-1.608 5.256-2.4 0.048-0.024 0.096-0.048 0.12-0.096-1.824 0.648-3.672 1.296-5.544 1.944 0.024-0.48 0-0.96 0.072-1.416 0.096-0.624 0.504-1.032 1.104-1.224 1.224-0.432 2.448-0.84 3.696-1.272 1.152-0.384 2.328-0.792 3.504-1.2 0.216-0.072 0.408-0.048 0.528 0.144 0.168 0.24 0.336 0.48 0.456 0.744 0.36 0.816 0.552 1.68 0.696 2.568 0.168 1.080 0.24 2.184 0.168 3.288-0.096 1.248-0.264 2.496-0.744 3.648-0.12 0.264-0.264 0.504-0.456 0.744-0.168 0.24-0.408 0.312-0.72 0.216-2.496-0.648-4.872-1.608-7.152-2.808-0.192-0.096-0.36-0.24-0.528-0.36-0.408-0.336-0.624-0.792-0.6-1.344 0-0.072 0-0.168 0-0.264 0 0 0 0 0.024-0.048zM10.32 11.472c-1.872-0.648-3.696-1.296-5.544-1.944 0 0 0 0.024 0 0.024 0.096 0.048 0.216 0.096 0.336 0.144 1.68 0.792 3.384 1.56 5.064 2.328 0.096 0.048 0.12 0.096 0.144 0.192 0.024 0.216-0.072 0.288-0.24 0.36-1.056 0.48-2.112 0.984-3.168 1.464-0.048 0.024-0.096 0.048-0.144 0.096 1.176-0.408 2.352-0.816 3.528-1.248 0.072 0.624-0.048 1.176-0.528 1.584-0.168 0.168-0.384 0.312-0.6 0.432-2.28 1.2-4.656 2.16-7.152 2.808-0.288 0.072-0.504 0-0.696-0.192-0.288-0.312-0.456-0.672-0.576-1.056-0.312-0.864-0.48-1.752-0.576-2.664-0.096-0.936-0.096-1.872-0.024-2.808 0.12-1.248 0.312-2.496 0.792-3.696 0.096-0.24 0.24-0.48 0.384-0.696 0.216-0.288 0.36-0.336 0.72-0.216 2.376 0.816 4.728 1.632 7.104 2.448 0.768 0.24 1.152 0.816 1.176 1.608 0 0.336 0 0.672 0 1.032z" />
    </svg>
  )
}

export default IconPapillon
