/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint react/no-unescaped-entities: ["error", {"forbid": ['>', '"', '}'] }] */
/** @jsx jsx */
import React, { useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import container from '@waiterio/styles/bootstrap/container.js'
import { sectionContainer, sectionBox } from '@waiterio/www/styles/section.js'
import primaryHeading from '@waiterio/www/styles/primaryHeading.js'
import increaseRevenueIcon from '../../images/increase-revenue.svg'
import quickServiceIcon from '../../images/quick-service.svg'
import betterDecisionMakingIcon from '../../images/better-decision-making.svg'

const ctaButton = css({
  cursor: 'pointer',
  padding: '12px 36px',
  marginLeft: 'auto',
  marginRight: 'auto',
  borderRadius: 4,
  color: 'white',
  backgroundColor: 'var(--color-primary)',
  ':hover': {
    backgroundColor: 'var(--color-green)',
    color: 'white',
  },
})

const WhyUseWaiterioSection = ({ locale }) => {
  const { t } = useTranslation(locale)
  const [firstUse, setFirstUse] = useState(true)
  const [secondUse, setSecondUse] = useState(false)
  const [thirdUse, setThirdUse] = useState(false)

  return (
    <section id="home-whyusewaiterio-section" css={sectionContainer}>
      <div css={container}>
        <div css={sectionBox}>
          <h2 css={primaryHeading}>
            {t('__pos_system_whyusewaiterio_title__')}
          </h2>
          <div
            css={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 60,
              marginBottom: 60,
              h3: {
                padding: '14px 28px',
                fontSize: 16,
                fontWeight: 'bold',
                marginTop: 'auto',
                marginBottom: 'auto',
                cursor: 'pointer',
              },
              '@media(max-width: 768px)': {
                marginTop: 30,
                marginBottom: 30,
                h3: {
                  padding: '7px 14px',
                },
              },
            }}
          >
            <h3
              onClick={() => {
                setFirstUse(true)
                setSecondUse(false)
                setThirdUse(false)
              }}
              css={{
                borderTop: '2px solid black',
                borderBottom: '2px solid black',
                borderLeft: '2px solid black',
                backgroundColor: firstUse && 'var(--color-primary)',
                color: firstUse && 'white',
                borderColor: firstUse && 'var(--color-primary)',
                ':hover': {
                  color: !firstUse && 'var(--color-primary)',
                  borderColor: 'var(--color-primary)',
                },
              }}
            >
              {t('__home_whyusewaiterio_growyourrevenue_title__')}
            </h3>

            <h3
              onClick={() => {
                setSecondUse(true)
                setFirstUse(false)
                setThirdUse(false)
              }}
              css={{
                borderTop: '2px solid black',
                borderBottom: '2px solid black',
                borderLeft: '2px solid black',
                backgroundColor: secondUse && 'var(--color-primary)',
                color: secondUse && 'white',
                borderColor: secondUse && 'var(--color-primary)',
                ':hover': {
                  color: !secondUse && 'var(--color-primary)',
                  borderColor: 'var(--color-primary)',
                },
              }}
            >
              {t('__home_whyusewaiterio_fastercustomerservice_title__')}
            </h3>

            <h3
              onClick={() => {
                setThirdUse(true)
                setFirstUse(false)
                setSecondUse(false)
              }}
              css={{
                borderTop: '2px solid black',
                borderBottom: '2px solid black',
                borderLeft: '2px solid black',
                borderRight: '2px solid black',
                backgroundColor: thirdUse && 'var(--color-primary)',
                color: thirdUse && 'white',
                borderColor: thirdUse && 'var(--color-primary)',
                ':hover': {
                  ':hover': {
                    color: !thirdUse && 'var(--color-primary)',
                    borderColor: 'var(--color-primary)',
                  },
                },
              }}
            >
              {t(
                '__home_whyusewaiterio_betterbusinessdecisions_title__',
                locale,
              )}
            </h3>
          </div>
          <div
            css={{
              display: 'flex',
              marginTop: 30,
              marginBottom: 30,
              '@media(max-width: 992px)': {
                img: {
                  maxWidth: 200,
                },
                p: {
                  maxWidth: 300,
                },
              },
              '@media(max-width: 768px)': {
                marginTop: 15,
                marginBottom: 15,
                img: {
                  maxWidth: 150,
                },
                div: {
                  flexDirection: 'column',
                  alignItems: 'center',
                },
              },
            }}
          >
            <div
              css={{
                display: firstUse ? 'flex' : 'none',
                flex: 1,
                justifyContent: 'space-around',
                margin: 10,
                textAlign: 'center',
              }}
            >
              <img
                src={increaseRevenueIcon}
                alt="grow-revenue"
                css={{ width: 240, height: 'auto' }}
              />
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <p css={{ maxWidth: 400 }}>
                  {t('__home_whyusewaiterio_growyourrevenue_content__')}
                </p>
                <a href="https://app.waiterio.com/signup" css={ctaButton}>
                  {t('Try it for free')}
                </a>
              </div>
            </div>

            <div
              css={{
                display: secondUse ? 'flex' : 'none',
                flex: 1,
                justifyContent: 'space-around',
                margin: 10,
                textAlign: 'center',
              }}
            >
              <img
                src={quickServiceIcon}
                alt="quick-service"
                css={{ width: 240, height: 'auto' }}
              />
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <p css={{ maxWidth: 400 }}>
                  {t(
                    '__home_whyusewaiterio_fastercustomerservice_content__',
                    locale,
                  )}
                </p>
                <a href="https://app.waiterio.com/signup" css={ctaButton}>
                  {t('Try it for free')}
                </a>
              </div>
            </div>

            <div
              css={{
                display: thirdUse ? 'flex' : 'none',
                flex: 1,
                justifyContent: 'space-around',
                margin: 10,
                textAlign: 'center',
              }}
            >
              <img
                src={betterDecisionMakingIcon}
                alt="better-decision-making"
                css={{ width: 240, height: 'auto' }}
              />
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <p css={{ maxWidth: 400 }}>
                  {t(
                    '__home_whyusewaiterio_betterbusinessdecisions_content__',
                    locale,
                  )}
                </p>
                <a href="https://app.waiterio.com/signup" css={ctaButton}>
                  {t('Try it for free')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyUseWaiterioSection
