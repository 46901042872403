/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint react/no-unescaped-entities: ["error", {"forbid": ['>', '"', '}'] }] */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import colMd6 from '@waiterio/styles/bootstrap/colMd6.js'
import colLg4 from '@waiterio/styles/bootstrap/colLg4.js'
import colXl4 from '@waiterio/styles/bootstrap/colXl4.js'
import containerFluid from '@waiterio/styles/bootstrap/containerFluid.js'
import row from '@waiterio/styles/bootstrap/row.js'

const Screenshot = ({ description, image, thumbnail }) => (
  <a
    href={image}
    css={[colSm12, colMd6, colLg4, colXl4, { padding: 0 }]}
    target="_blank"
    rel="noreferrer"
  >
    <img css={{ display: 'block' }} src={thumbnail} alt={description} />
  </a>
)

const screenshotSelector = locale => [
  {
    description: 'Manage the <b>tables</b>',
    image: `https://www.imagelato.com/images/portfolio-computer-tables-${locale}.jpg`,
    thumbnail: `https://www.imagelato.com/images/portfolio-thumbnail-computer-tables-${locale}.jpg`,
  },
  {
    description: 'Control the <b>orders</b>',
    image: `https://www.imagelato.com/images/portfolio-computer-orders-${locale}.jpg`,
    thumbnail: `https://www.imagelato.com/images/portfolio-thumbnail-computer-orders-${locale}.jpg`,
  },
  {
    description: 'Take an <b>order</b>',
    image: `https://www.imagelato.com/images/portfolio-computer-order-${locale}.jpg`,
    thumbnail: `https://www.imagelato.com/images/portfolio-thumbnail-computer-order-${locale}.jpg`,
  },
  {
    description: 'Receive a <b>payment</b>',
    image: `https://www.imagelato.com/images/portfolio-computer-payment-${locale}.jpg`,
    thumbnail: `https://www.imagelato.com/images/portfolio-thumbnail-computer-payment-${locale}.jpg`,
  },
  {
    description: 'Invite the <b>co-workers</b>',
    image: `https://www.imagelato.com/images/portfolio-computer-staff-${locale}.jpg`,
    thumbnail: `https://www.imagelato.com/images/portfolio-thumbnail-computer-staff-${locale}.jpg`,
  },
  {
    description: 'Customize the <b>menu</b>',
    image: `https://www.imagelato.com/images/portfolio-computer-menu-${locale}.jpg`,
    thumbnail: `https://www.imagelato.com/images/portfolio-thumbnail-computer-menu-${locale}.jpg`,
  },
]

const ScreenshotsSection = ({ locale }) => {
  const { t } = useTranslation(locale)
  let screenshots = screenshotSelector(locale)

  return (
    <section id="screenshots">
      <div css={[containerFluid, { padding: 0 }]}>
        <div css={row}>
          {screenshots.map(({ description, image, thumbnail }) => (
            <Screenshot
              key={image}
              description={t(description)}
              image={image}
              thumbnail={thumbnail}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default ScreenshotsSection
