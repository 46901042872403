/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint react/no-unescaped-entities: ["error", {"forbid": ['>', '"', '}'] }] */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import colLg4 from '@waiterio/styles/bootstrap/colLg4.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import primaryHeading from '@waiterio/www/styles/primaryHeading.js'

const reviewsSection = css`
  background-color: white;
  margin-bottom: 50px;
  padding: 100px 0;
  text-align: center;

  @media (max-width: 1199px) {
    padding: 80px 0 0;
  }

  @media (max-width: 767px) {
    padding: 50px 0 0;
  }
`

const reviewsTitleWrap = css`
  margin: 50px 0;
  @media (max-width: 992px) {
    margin-bottom: 0px;
  }
`

const reviewBox = css`
  position: relative;
  margin-top: 40px;
  padding: 30px;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.06);
`
const reviewImage = css`
  border-radius: 50%;
  width: 120px;
  height: 120px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
`

const reviewContent = css`
  line-height: 28px;
  margin-bottom: 16px;
  margin-top: 10px;
`

const ReviewsSection = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <section css={reviewsSection}>
      <div css={container}>
        <div css={row}>
          <div css={colSm12}>
            <div css={reviewsTitleWrap}>
              <h3 css={primaryHeading}>{t('__home_reviews_title__')}</h3>
            </div>
          </div>
        </div>
        <div css={row}>
          <div css={[colSm12, colLg4, { marginTop: 30 }]}>
            <div css={reviewBox}>
              <picture>
                <source
                  type="image/webp"
                  srcSet="https://www.imagelato.com/images/restaurant-logo-shanghai-8afe24b8-240w.webp"
                />
                <img
                  css={reviewImage}
                  src="https://www.imagelato.com/images/restaurant-logo-shanghai-8afe24b8-120w.jpg"
                  alt="shanghaiLogo"
                />
              </picture>

              <div css={{ margin: '80px 0px 0px' }}>
                <p css={{ fontWeight: 'bold' }}>
                  {t('Favorite feature')}:{' '}
                  <span css={{ color: 'var(--color-primary)' }}>
                    {t('__pos_system_features_staffmanagement_title__')}
                  </span>
                </p>
                <p css={reviewContent}>
                  <q>{t('__home_reviews_1_content__')}</q>
                </p>
                <h5 css={{ fontWeight: 'lighter' }}>Carlos Balderas</h5>
                <h5 css={{ fontWeight: 'bold' }}>Shanghai Tres Ríos</h5>
                <h5 css={{ fontWeight: 'lighter' }}>Culiacán, Mexico</h5>
              </div>
            </div>
          </div>
          <div css={[colSm12, colLg4, { marginTop: 30 }]}>
            <div css={reviewBox}>
              <picture>
                <source
                  type="image/webp"
                  srcSet="https://www.imagelato.com/images/restaurant-logo-mrbreakfast-d47c3d65-240w.webp"
                />
                <img
                  css={reviewImage}
                  src="https://www.imagelato.com/images/restaurant-logo-mrbreakfast-d47c3d65-240w.jpg"
                  alt="mrBreakFastLogo"
                />
              </picture>

              <div css={{ margin: '80px 0px 0px' }}>
                <p css={{ fontWeight: 'bold' }}>
                  {t('Favorite feature')}:{' '}
                  <span css={{ color: 'var(--color-primary)' }}>
                    {t('__pos_system_onlineordering_title__')}
                  </span>
                </p>
                <p css={reviewContent}>
                  <q>{t('__home_reviews_2_content__')}</q>
                </p>
                <h5 css={{ fontWeight: 'lighter' }}>Matthew Johnson (Mr.)</h5>
                <h5 css={{ fontWeight: 'bold' }}>MrBreakfastJa</h5>
                <h5 css={{ fontWeight: 'lighter' }}>Kingston, Jamaica</h5>
              </div>
            </div>
          </div>
          <div css={[colSm12, colLg4, { marginTop: 30 }]}>
            <div css={reviewBox}>
              <picture>
                <source
                  type="image/webp"
                  media="(-webkit-min-device-pixel-ratio: 2)"
                  srcSet="https://www.imagelato.com/images/restaurant-logo-delucca-12809b29-240w.webp"
                />
                <source
                  type="image/webp"
                  srcSet="https://www.imagelato.com/images/restaurant-logo-delucca-12809b29-120w.webp"
                />
                <source
                  type="image/jpeg"
                  media="(-webkit-min-device-pixel-ratio: 2)"
                  srcSet="https://www.imagelato.com/images/restaurant-logo-delucca-12809b29-240w.jpg"
                />
                <img
                  css={reviewImage}
                  src="https://www.imagelato.com/images/restaurant-logo-delucca-12809b29-120w.jpg"
                  alt="deluccaLogo"
                />
              </picture>

              <div css={{ margin: '80px 0px 0px' }}>
                <p css={{ fontWeight: 'bold' }}>
                  {t('Favorite feature')}:{' '}
                  <span css={{ color: 'var(--color-primary)' }}>
                    {t('__pos_system_features_salesreports_title__')}
                  </span>
                </p>
                <p css={reviewContent}>
                  <q>{t('__home_reviews_3_content__')}</q>
                </p>
                <h5 css={{ fontWeight: 'lighter' }}>Lucas Carpi</h5>
                <h5 css={{ fontWeight: 'bold' }}>DeLucca Ristorante</h5>
                <h5 css={{ fontWeight: 'lighter' }}>Embarcacion, Argentina</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ReviewsSection
