/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint react/no-unescaped-entities: ["error", {"forbid": ['>', '"', '}'] }] */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import container from '@waiterio/styles/bootstrap/container.js'
import { subtitle } from '@waiterio/www/styles/section.js'
import primaryHeading from '@waiterio/www/styles/primaryHeading.js'
import slugsWebsiteBuilder from '../../slugsWebsiteBuilder.js'
import onlineOrderingOverviewIcon from '../../images/online-ordering-overview.svg'

const learnMoreButton = css`
  background-color: var(--color-primary);
  border-radius: 4px;
  color: white;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  margin: auto;
  padding: 12px 36px;
  text-align: center;
  &:hover,
  &:focus {
    color: white;
    background: var(--color-green);
  }
`

const sectionContainer = css`
  margin: 160px 0px;

  @media (max-width: 1199px) {
    margin: 128px 0px;
  }

  @media (max-width: 992px) {
    margin: 96px 0px;
  }

  @media (max-width: 767px) {
    margin: 64px 0px;
  }

  @media (max-width: 576px) {
    margin: 32px 0px;
  }
`

const sectionBox = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  padding: 60px;

  @media (max-width: 992px) {
    padding: 30px;
  }
`

const OnlineOrderingOverviewSection = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <section id="online-ordering-overview-section" css={sectionContainer}>
      <div css={container}>
        <div css={sectionBox}>
          <h2 css={primaryHeading}>
            {t('__pos_system_onlineordering_title__')}
          </h2>
          <p css={subtitle}>{t('__pos_system_onlineordering_content__')}</p>
          <a
            href={`/${locale}/${
              slugsWebsiteBuilder[locale] || slugsWebsiteBuilder.en
            }#online-ordering-section`}
            css={learnMoreButton}
          >
            {t('Learn more')}
          </a>
          <img
            css={{
              width: 300,
              height: 300,
              margin: '32px auto',
              '@media(max-width: 768px)': {
                width: 200,
                height: 200,
              },
            }}
            src={onlineOrderingOverviewIcon}
            alt="online ordering overview"
          />
        </div>
      </div>
    </section>
  )
}

export default OnlineOrderingOverviewSection
