/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'

const underlinePartial = css`
  position: relative;
  padding-bottom: 20px;

  :before {
    content: '';
    position: absolute;
    bottom: 0;
    border-radius: 4px;
    left: 50%;
    margin-left: -40px;
    width: 80px;
    height: 4px;
    background: var(--color-primary);
  }
`

export default underlinePartial
