/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import faviconUrl from '@waiterio/shared/faviconUrl.js'
import Layout from '../../components/LayoutComponent.js'
import slugsPosSystem from '../../slugsPosSystem.js'
import languages from '../../languages.js'
import BannerSection from './BannerSection.js'
import HowItWorksSection from './HowItWorksSection.js'
import WhyUseWaiterioSection from './WhyUseWaiterioSection.js'
// import ScreenshotsSection from './ScreenshotsSection'
import FeaturesSection from './FeaturesSection.js'
import HardwareSection from './HardwareSection.js'
import AcceptAllPaymentMethodsSection from './AcceptAllPaymentMethodsSection.js'
import OnlineOrderingOverviewSection from './OnlineOrderingOverviewSection.js'
import QuestionsSection from './QuestionsSection.js'
import ClientsSection from './ClientsSection.js'
import ClosingSection from '../ClosingSection.js'

export const Head = ({ locale }) => {
  const { t } = useTranslation(locale)

  const title = `${t('__pos_system_first_meta_title__')} | ${t(
    '__pos_system_second_meta_title__',
    locale,
  )}`
  const description = `${t('__pos_system_meta_description__')}`
  const url = `https://www.waiterio.com/${locale}/${slugsPosSystem[locale]}/`

  return (
    <>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={faviconUrl} />
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={faviconUrl} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={faviconUrl} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org/',
            '@type': 'SoftwareApplication',
            name: title,
            description,
            operatingSystem: ['Android', 'iOS', 'Windows', 'macOS', 'Linux'],
            applicationCategory: 'Business',
            inLanguage: locale,
            aggregateRating: {
              '@type': 'AggregateRating',
              ratingValue: '4.258823394775391',
              ratingCount: '1720',
            },
            offers: {
              '@type': 'Offer',
              price: 0,
              priceCurrency: 'USD',
              category: 'free',
            },
            author: {
              '@id': 'https://www.waiterio.com/#corporation',
            },
            publisher: {
              '@id': 'https://www.waiterio.com/#corporation',
            },
          }),
        }}
      />
    </>
  )
}

export const paths = languages.map(locale => ({
  path: `/${locale}/${slugsPosSystem[locale] || slugsPosSystem.en}/`,
  props: {
    locale,
  },
}))

const PosSystemPage = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <Layout locale={locale}>
      <BannerSection locale={locale} />
      <WhyUseWaiterioSection locale={locale} />
      <HowItWorksSection locale={locale} />
      <FeaturesSection locale={locale} />
      <HardwareSection locale={locale} />
      <AcceptAllPaymentMethodsSection locale={locale} />
      <OnlineOrderingOverviewSection locale={locale} />
      <QuestionsSection locale={locale} />
      <ClientsSection locale={locale} />
      <ClosingSection
        sectionId="pos-system"
        locale={locale}
        title={t('__pos_system_closing_title__')}
        subtitle={t('__pos_system_closing_content__')}
      />
    </Layout>
  )
}

export default PosSystemPage
