/* Copyright 2013 - 2024 Waiterio LLC */
export default function getCurrencyByLanguage(languageCode) {
  languageCode = languageCode?.toLowerCase()

  /* istanbul ignore next */
  switch (languageCode) {
    case 'af':
      return 'ZAR'
    case 'ar':
      return 'EGP'
    case 'az':
      return 'AZN'
    case 'bg':
      return 'BGN'
    case 'bn':
      return 'BDT'
    case 'bo':
      return 'CNY'
    case 'bs':
      return 'BAM'
    case 'da':
      return 'DKK'
    case 'ca':
      return 'EUR'
    case 'cs':
      return 'CZK'
    case 'de':
      return 'EUR'
    case 'el':
      return 'EUR'
    case 'en':
      return 'USD'
    case 'es':
      return 'MXN'
    case 'et':
      return 'EUR'
    case 'fa':
      return 'IRR'
    case 'fi':
      return 'EUR'
    case 'fr':
      return 'EUR'
    case 'gl':
      return 'EUR'
    case 'gu':
      return 'INR'
    case 'he':
      return 'ILS'
    case 'hi':
      return 'INR'
    case 'hu':
      return 'HUF'
    case 'hr':
      return 'HRK'
    case 'hy':
      return 'AMD'
    case 'id':
      return 'IDR'
    case 'it':
      return 'EUR'
    case 'is':
      return 'ISK'
    case 'ka':
      return 'GEL'
    case 'km':
      return 'KHR'
    case 'kn':
      return 'INR'
    case 'ko':
      return 'KRW'
    case 'ja':
      return 'JPY'
    case 'lo':
      return 'LAK'
    case 'lt':
      return 'EUR'
    case 'lv':
      return 'EUR'
    case 'mk':
      return 'MKD'
    case 'mn':
      return 'MNT'
    case 'mr':
      return 'INR'
    case 'ms':
      return 'MYR'
    case 'mt':
      return 'EUR'
    case 'my':
      return 'MMK'
    case 'ne':
      return 'NPR'
    case 'nl':
      return 'USD'
    case 'no':
      return 'NOK'
    case 'pa':
      return 'INR'
    case 'pl':
      return 'PLN'
    case 'pt':
      return 'BRL'
    case 'sk':
      return 'EUR'
    case 'sl':
      return 'EUR'
    case 'sq':
      return 'ALL'
    case 'sr':
      return 'RSD'
    case 'sv':
      return 'SEK'
    case 'ro':
      return 'RON'
    case 'ru':
      return 'RUB'
    case 'ta':
      return 'LKR'
    case 'te':
      return 'INR'
    case 'th':
      return 'THB'
    case 'tr':
      return 'TRY'
    case 'uk':
      return 'UAH'
    case 'ur':
      return 'PKR'
    case 'uz':
      return 'UZS'
    case 'vi':
      return 'VND'
    case 'zh':
      return 'CNY'

    default:
      return null
  }
}
