/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  cs: 'restaurace-online-objednávání',
  da: 'restaurant-online-bestilling',
  de: 'restaurant-online-bestellen',
  el: 'parangelía-se-apeftheías-sýndesi-estiatório',
  en: 'restaurant-online-ordering',
  es: 'pedidos-en-línea-de-restaurantes',
  fr: 'commande-en-ligne-de-restaurant',
  id: 'restoran-memesan-secara-online',
  it: 'orderinazione-online-restaurante',
  nl: 'restaurant-online-bestellen',
  pl: 'zamawianie-online-restauracji',
  pt: 'pedido-online-de-restaurante',
  ru: 'onlayn-zakaz-v-restorane',
  th: 'Kār-s̄ạ̀ng-sụ̄̂x-r̂ān-xāh̄ār-xxnlịn̒',
  tl: 'pag-order-ng-online-sa-restawran',
  tr: 'çevrimiçi-restoran-siparişi',
  zh: 'cāntīng–zàixiàn-dìnggòu',
}
