/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'
import { useRoutes } from 'react-router-dom'
import getRoutes from 'tagliatelle/getRoutes.js'
import context from './webpackContext.mjs'

const Routes = () => {
  let routes = getRoutes({ context })
  return useRoutes(routes)
}

export default Routes
