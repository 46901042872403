/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint react/no-unescaped-entities: ["error", {"forbid": ['>', '"', '}'] }] */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import container from '@waiterio/styles/bootstrap/container.js'
import IconCompass from '@stiloso/icons/IconCompass.js'
import IconLanguage from '@stiloso/icons/IconLanguage.js'
import IconSpeed from '@stiloso/icons/IconSpeed.js'
import IconDevices from '@stiloso/icons/IconDevices.js'
import primaryHeading from '@waiterio/www/styles/primaryHeading.js'
import { sectionContainer, sectionBox } from '@waiterio/www/styles/section.js'

const UsefulFeaturesSection = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <section
      id="restaurant-website-builder-useful-features"
      css={sectionContainer}
    >
      <div css={container}>
        <div css={sectionBox}>
          <h2 css={primaryHeading}>
            {t('__website_builder_usefulfeatures_title__')}
          </h2>

          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              marginTop: 30,
              '@media(max-width: 992px)': {
                svg: {
                  width: 60,
                },
              },
              '@media(max-width: 576px)': {
                svg: {
                  width: 50,
                  marginTop: -4,
                },
              },
            }}
          >
            <div
              css={{
                maxWidth: 500,
                margin: '14px auto',
                textAlign: 'left',
                display: 'flex',
              }}
            >
              <span>
                <IconDevices
                  css={{ fill: 'var(--color-primary)', width: 70, height: 60 }}
                />
              </span>
              <div css={{ marginLeft: 20 }}>
                <h3 css={{ textTransform: 'capitalize', marginBottom: 4 }}>
                  {t(
                    '__website_builder_usefulfeatures_worksonalldevices_title__',
                    locale,
                  )}
                </h3>
                <span>
                  {t(
                    '__website_builder_usefulfeatures_worksonalldevices_content__',
                    locale,
                  )}
                </span>
              </div>
            </div>

            <div
              css={{
                maxWidth: 500,
                margin: '14px auto',
                textAlign: 'left',
                display: 'flex',
              }}
            >
              <span>
                <IconLanguage
                  css={{ fill: 'var(--color-primary)', width: 70, height: 60 }}
                />
              </span>
              <div css={{ marginLeft: 20 }}>
                <h3 css={{ textTransform: 'capitalize', marginBottom: 4 }}>
                  {t(
                    '__website_builder_usefulfeatures_multiplelanguagesupport_title__',
                    locale,
                  )}
                </h3>
                <span>
                  {t(
                    '__website_builder_usefulfeatures_multiplelanguagesupport_content__',
                    locale,
                  )}
                </span>
              </div>
            </div>

            <div
              css={{
                maxWidth: 500,
                margin: '14px auto',
                textAlign: 'left',
                display: 'flex',
              }}
            >
              <span>
                <IconCompass
                  css={{ fill: 'var(--color-primary)', width: 70, height: 60 }}
                />
              </span>
              <div css={{ marginLeft: 20 }}>
                <h3 css={{ textTransform: 'capitalize', marginBottom: 4 }}>
                  {t(
                    '__website_builder_usefulfeatures_googlemapintegration_title__',
                    locale,
                  )}
                </h3>
                <span>
                  {t(
                    '__website_builder_usefulfeatures_googlemapintegration_content__',
                    locale,
                  )}
                </span>
              </div>
            </div>

            <div
              css={{
                maxWidth: 500,
                margin: '14px auto',
                textAlign: 'left',
                display: 'flex',
              }}
            >
              <span>
                <IconSpeed
                  css={{ fill: 'var(--color-primary)', width: 70, height: 60 }}
                />
              </span>
              <div css={{ marginLeft: 20 }}>
                <h3 css={{ textTransform: 'capitalize', marginBottom: 4 }}>
                  {t(
                    '__website_builder_usefulfeatures_superfastwebsites_title__',
                    locale,
                  )}
                </h3>
                <span>
                  {t(
                    '__website_builder_usefulfeatures_superfastwebsites_content__',
                    locale,
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default UsefulFeaturesSection
