/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IconMoneyBill = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-money-bill'

  return (
    <svg
      viewBox="0 0 640 512"
      style={style}
      className={className}
      width={width}
      height={height}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zM48 400v-64c35.35 0 64 28.65 64 64H48zm0-224v-64h64c0 35.35-28.65 64-64 64zm272 176c-44.19 0-80-42.99-80-96 0-53.02 35.82-96 80-96s80 42.98 80 96c0 53.03-35.83 96-80 96zm272 48h-64c0-35.35 28.65-64 64-64v64zm0-224c-35.35 0-64-28.65-64-64h64v64z" />
    </svg>
  )
}

export default IconMoneyBill
