/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'
import { Navigate } from 'react-router-dom'
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'

export const paths = [{ path: '/referral/' }]

const ReferralRedirect = () => {
  const locale = getBrowserLanguage() || 'en'
  return <Navigate exact to={`/${locale}/referral/`} />
}

export default ReferralRedirect
