/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint react/no-unescaped-entities: ["error", {"forbid": ['>', '"', '}'] }] */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import IconDone from '@stiloso/icons/IconDone.js'
import container from '@waiterio/styles/bootstrap/container.js'
import { sectionContainer, sectionBox } from '@waiterio/www/styles/section.js'
import primaryHeading from '@waiterio/www/styles/primaryHeading.js'

const MoreOnlineOrderingFeaturesSection = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <section
      id="restaurant-website-builder-more-online-ordering-features"
      css={sectionContainer}
    >
      <div css={container}>
        <div css={sectionBox}>
          <h2 css={primaryHeading}>
            {t('__website_builder_moreonlineorderingfeatures_title__')}
          </h2>

          <div
            css={{
              display: 'flex',
              flexWrap: 'wrap',
              marginTop: 30,
              p: {
                maxWidth: 450,
                textAlign: 'left',
              },
            }}
          >
            <div
              css={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}
            >
              <div css={{ margin: '18px 10px' }}>
                <IconDone
                  css={{ width: 28, height: 28, fill: 'var(--color-primary)' }}
                />
              </div>
              <p>
                <strong>
                  {t(
                    '__website_builder_moreonlineorderingfeatures_ordertiming_title__',
                    locale,
                  )}
                  :{' '}
                </strong>
                {t(
                  '__website_builder_moreonlineorderingfeatures_ordertiming_content__',
                  locale,
                )}
              </p>
            </div>

            <div
              css={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}
            >
              <div css={{ margin: '18px 10px' }}>
                <IconDone
                  css={{ width: 28, height: 28, fill: 'var(--color-primary)' }}
                />
              </div>
              <p>
                <strong>
                  {t(
                    '__website_builder_moreonlineorderingfeatures_multiplelocationsupport_title__',
                    locale,
                  )}
                  :{' '}
                </strong>
                {t(
                  '__website_builder_moreonlineorderingfeatures_multiplelocationsupport_content__',
                  locale,
                )}
              </p>
            </div>

            <div
              css={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}
            >
              <div css={{ margin: '18px 10px' }}>
                <IconDone
                  css={{ width: 28, height: 28, fill: 'var(--color-primary)' }}
                />
              </div>
              <p>
                <strong>
                  {t(
                    '__website_builder_moreonlineorderingfeatures_orderbeforehand_title__',
                    locale,
                  )}
                  :{' '}
                </strong>
                {t(
                  '__website_builder_moreonlineorderingfeatures_orderbeforehand_content__',
                  locale,
                )}
              </p>
            </div>

            <div
              css={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}
            >
              <div css={{ margin: '18px 10px' }}>
                <IconDone
                  css={{ width: 28, height: 28, fill: 'var(--color-primary)' }}
                />
              </div>
              <p>
                <strong>
                  {t(
                    '__website_builder_moreonlineorderingfeatures_contactlessdelivery_title__',
                    locale,
                  )}
                  :{' '}
                </strong>
                {t(
                  '__website_builder_moreonlineorderingfeatures_contactlessdelivery_content__',
                  locale,
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MoreOnlineOrderingFeaturesSection
