/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import captureExceptionBrowser from '@monorepo/telemetry/captureExceptionBrowser.js'
import ErrorBoundaryWithoutSentry from '@waiterio/components/ErrorBoundaryWithoutSentry.js'

const ErrorBoundary = ({ children }) => (
  <ErrorBoundaryWithoutSentry onCatchError={captureExceptionBrowser}>
    {children}
  </ErrorBoundaryWithoutSentry>
)

export default ErrorBoundary
