/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'
import NavigationComponent from './NavigationComponent.js'
import Footer from './FooterComponent.js'

const LayoutComponent = ({ app, children, headerStyle, locale }) => (
  <>
    <NavigationComponent app={app} locale={locale} style={headerStyle} />
    {children}
    <Footer locale={locale} />
  </>
)

export default LayoutComponent
