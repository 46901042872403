/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  cs: 'food-truck-pos-system',
  da: 'food-truck-pos-system',
  de: 'software-der-foodtruck-kassensystem',
  el: 'fortigo-trofimon-pos-system',
  en: 'food-truck-pos-system',
  es: 'punto-de-venta-camion-restaurante',
  fr: 'systeme-de-caisse-camion-restaurant-pdv',
  id: 'truk-makanan-pos-system',
  it: 'pos-software-camion-ristorante',
  nl: 'de-foodtruck-kassa-systeem',
  pl: 'food-truck-pos-system',
  pt: 'software-para-food-truck',
  ru: 'food-truck-pos-system',
  th: 'brrthuk-xahar-pos-rabo',
  tl: 'food-truck-pos-sistema',
  tr: 'seyyar-satici-pos-sistemi',
  zh: 'shiwu-kache-pos-xìtǒng',
}
