/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint jsx-a11y/label-has-associated-control: 0 */
/** @jsx jsx */
import React, { useState } from 'react'
import { css, jsx } from '@emotion/react'
import { Link } from 'react-router-dom'
import { useTranslation } from '@multilocale/react/index.js'
import IconRestaurant from '@stiloso/icons/IconRestaurant.js'
import IconForkSpoon from '@stiloso/icons/IconForkSpoon.js'
import IconIosPlay from '@stiloso/icons/IconIosPlay.js'
import IconChefHat from '@stiloso/icons/IconHotDish.js'
import colLg4 from '@waiterio/styles/bootstrap/colLg4.js'
import colLg6 from '@waiterio/styles/bootstrap/colLg6.js'
import colMd6 from '@waiterio/styles/bootstrap/colMd6.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import VideoDialog from '@waiterio/www/dialogs/VideoDialog.js'

const countersSection = css`
  background-color: var(--color-primary);
  color: white;
  margin-top: 30px;
  padding-top: 80px;
  position: relative;
`

const counterWrap = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;

  @media (max-width: 768px) {
    svg {
      width: 60px;
      height: 60px;
    }

    h3 {
      font-size: 32px;
      line-height: 36px;
    }
  }

  @media (max-width: 576px) {
    svg {
      width: 50px;
      height: 50px;
    }

    h3 {
      font-size: 28px;
      line-height: 26px;
    }

    span {
      font-size: 16px;
    }
  }
`

const counterName = css`
  font-size: 18px;
  text-transform: uppercase;
`

const counterNumber = css`
  color: white;
  font-size: 40px;
  font-weight: 700;
  margin: 14px 0;
  line-height: 40px;
`

const countersInfo = css`
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  display: inline-block;
  margin-top: 30px;
  padding-top: 40px;
  position: relative;
  width: 100%;

  @media (max-width: 992px) {
    text-align: center;
  }
`

const countersButton = css`
  background-color: white;
  border-radius: 4px;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 30px;
  margin-top: 15px;
  padding: 12px 36px;
  &:hover,
  &:focus {
    color: white;
    background: var(--color-green);
  }
`

const countersImageWrapper = css`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  min-height: 200px;
`

const countersImage = css`
  margin-bottom: -135px;
  margin-top: -30px;
  vertical-align: middle;
  width: inherit;

  @media (max-width: 1399px) {
    margin-top: 0;
    width: 118%;
  }

  @media (max-width: 1365px) {
    margin-top: 10px;
    width: 111%;
  }

  @media (max-width: 1199px) {
    margin-top: 0;
    width: 100%;
  }

  @media (max-width: 992px) {
    margin-bottom: -87px;
  }
`

const countersWaveBox = css`
  position: absolute;
  top: 45%;
  left: 61%;
`

const countersPlayWrapper = css`
  background: white;
  display: block;
  width: 80px;
  height: 80px;
  text-align: center;
  color: var(--color-primary);
  border-radius: 100%;
  line-height: 80px;
  z-index: 9;
  position: relative;
  fill: var(--color-primary);
  cursor: pointer;
  :hover {
    fill: var(--color-green);
  }

  @media (max-width: 1365px) {
    right: 70px;
  }

  @media (max-width: 1199px) {
    top: -70px;
    right: 90px;
  }

  @media (max-width: 768px) {
    top: 20px;
    right: 110px;
  }

  @media (max-width: 479px) {
    top: -30px;
    right: 80px;
  }

  @media (max-width: 390px) {
    top: -50px;
    right: 80px;
  }
`

const countersWaves = css`
  position: absolute;
  width: 14rem;
  height: 14rem;
  left: -80px;
  top: -80px;
  z-index: 2;
  float: right;
`

const countersWave = css`
  @keyframes waves {
    0% {
      transform: scale(0.2, 0.2);
      opacity: 0;
    }
    50% {
      opacity: 0.9;
    }
    100% {
      transform: scale(0.9, 0.9);
      opacity: 0;
    }
  }

  position: absolute;
  width: 15rem;
  height: 15rem;
  background: rgba(255, 255, 255, 0.2);
  opacity: 0;
  border-radius: 320px;
  background-clip: padding-box;
  animation: waves 3s ease-in-out infinite;

  @media (max-width: 1365px) {
    right: 60px;
  }

  @media (max-width: 1199px) {
    top: -70px;
    right: 80px;
  }

  @media (max-width: 768px) {
    top: 20px;
    right: 95px;
  }

  @media (max-width: 479px) {
    top: -30px;
    right: 65px;
  }

  @media (max-width: 390px) {
    top: -50px;
    right: 65px;
  }
`
const iconPlayIcon = css`
  width: 32px;
  height: 32px;
  margin-left: 5px;
  vertical-align: middle;
`

const CountersSection = props => {
  const { locale } = props
  const { t } = useTranslation(locale)
  let [videoDialog, setVideoDialog] = useState(props.videoDialog)
  return (
    <section css={countersSection}>
      <div css={container}>
        <div css={row}>
          <div css={[colSm12, colMd6, colLg4]}>
            <div css={counterWrap}>
              <IconRestaurant css={{ width: 80, height: 65, fill: 'white' }} />
              <h3 css={counterNumber}>40,000+</h3>
              <span css={counterName}>{t('Restaurants')}</span>
            </div>
          </div>
          <div css={[colSm12, colMd6, colLg4]}>
            <div css={counterWrap}>
              <IconForkSpoon css={{ width: 80, height: 65, fill: 'white' }} />
              <h3 css={counterNumber}>2.6+ {t('Million')}</h3>
              <span css={counterName}>{t('Menu items')}</span>
            </div>
          </div>
          <div css={[colSm12, colMd6, colLg4]}>
            <div css={counterWrap}>
              <IconChefHat css={{ width: 80, height: 65, fill: 'white' }} />
              <h3 css={counterNumber}>5+ {t('Million')}</h3>
              <span css={counterName}>{t('Meal orders')}</span>
            </div>
          </div>
        </div>
        <div css={countersInfo}>
          <div css={row}>
            <div css={colLg6}>
              <p css={{ margin: '0 0 16px 0' }}>
                {t('__home_counters_content_1__')}
              </p>
              <p css={{ margin: '0 0 16px 0' }}>
                {t('__home_counters_content_2__')}
              </p>
              <Link css={countersButton} to={`/${locale}/downloads/`}>
                {t('Downloads')}
              </Link>
            </div>
            <div css={colLg6}>
              <div css={countersImageWrapper}>
                <picture>
                  <source
                    type="image/webp"
                    srcSet="https://www.imagelato.com/images/youtube-placeholder-video-luigi-225c7967-714w.webp"
                  />
                  <img
                    css={countersImage}
                    src="https://www.imagelato.com/images/youtube-placeholder-video-luigi-225c7967-714w.jpg"
                    alt="this is luigi and he has a restaurant"
                  />
                </picture>
                <div css={countersWaveBox}>
                  <div
                    css={countersPlayWrapper}
                    onClick={() => setVideoDialog(true)}
                    aria-label="Youtube Waiterio video"
                  >
                    <IconIosPlay css={iconPlayIcon} />
                  </div>

                  {videoDialog && (
                    <VideoDialog close={() => setVideoDialog(false)} />
                  )}

                  <div css={countersWaves}>
                    <div css={[countersWave, { animationDelay: '0s' }]} />
                    <div css={[countersWave, { animationDelay: '1s' }]} />
                    <div css={[countersWave, { animationDelay: '2s' }]} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CountersSection
