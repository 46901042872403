/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint max-len: 0 */

import React from 'react'

const IconHotDish = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-hot-dish'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M12.24 1.872c-0.048 0.024-0.816 0.168-1.728 0.264-3.744 0.456-4.512 0.624-4.512 0.888 0 0.432 0.96 0.84 3.96 1.608 1.584 0.408 2.976 0.84 3.144 0.936 0.432 0.264-1.8 1.656-3.36 2.088-1.68 0.432-1.584 0.744 0.432 1.536 0.816 0.312 1.488 0.648 1.488 0.696 0 0.12-0.336 0.48-0.72 0.816l-0.768 0.672 0.816-0.24c2.712-0.744 3.648-2.136 1.44-2.136-0.696 0-1.464-0.144-1.728-0.312-0.648-0.384 0.168-0.792 2.952-1.512 2.736-0.72 4.344-1.44 4.344-2.040 0-0.504-1.056-0.696-4.608-0.912-2.424-0.12-3.48-0.6-2.856-1.2 0.288-0.336 2.832-0.792 3.864-0.768 0.336 0 0.672-0.048 0.744-0.192 0.12-0.192-2.712-0.408-2.904-0.192zM9 13.056c-0.672 0.432-2.040 0.984-3.072 1.2-4.392 0.888-5.856 2.376-2.328 2.448 7.536 0.12 16.104 0.096 17.232-0.048 1.272-0.168 1.296-0.192 0.6-0.624-0.36-0.264-0.624-0.672-0.528-0.888 0.24-0.672-1.2-1.248-2.76-1.056-1.248 0.144-1.536 0.048-1.968-0.624-0.336-0.528-0.816-0.768-1.584-0.72-0.6 0-1.824-0.12-2.76-0.24-1.44-0.216-1.824-0.144-2.832 0.552zM0.336 17.976c0 0.312 3.6 1.824 5.256 2.184 1.32 0.264 1.368 0.672 0.216 0.984-0.48 0.12-0.816 0.384-0.696 0.624 0.144 0.576 13.632 0.576 13.8 0 0.096-0.24-0.336-0.6-0.984-0.864l-1.104-0.432 1.608-0.408c2.4-0.624 5.232-1.728 5.232-2.088 0-0.168-4.704-0.312-11.664-0.312-6.624 0-11.664 0.144-11.664 0.312z" />
    </svg>
  )
}

export default IconHotDish
