/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import colLg6 from '@waiterio/styles/bootstrap/colLg6.js'
import colMd6 from '@waiterio/styles/bootstrap/colMd6.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import IconHandUsd from '@stiloso/icons/IconHandUsd.js'
import IconRestaurant from '@stiloso/icons/IconRestaurant.js'
import IconMaintenance from '@stiloso/icons/IconMaintenance.js'
import IconEasy from '@stiloso/icons/IconEasy.js'
import { sectionContainer } from '@waiterio/www/styles/section.js'
import heading from '@waiterio/www/styles/heading.js'

const sectionBox = css`
  background: white;
  border: 1px solid #f2f2f2;
  overflow: hidden;
  padding: 40px 15px 25px 15px;
  position: relative;
  text-align: center;
  margin-bottom: 30px;
`

const iconContainer = css`
  background: #f4f4f4;
  border-radius: 90px;
  color: var(--color-primary);
  display: inline-block;
  fill: var(--color-primary);
  height: 86px;
  line-height: 86px;
  text-align: center;
  width: 86px;
  vertical-align: middle;
`

const subHeader = css`
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 15px;
  margin-top: 25px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  z-index: 9;
`

const WhyUseWebsiteBuilderSection = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <section css={sectionContainer}>
      <div css={container}>
        <div css={[row, { paddingBottom: 30 }]}>
          <div css={colSm12}>
            <div
              css={{
                textAlign: 'center',
                backgroundColor: 'var(--color-primary)',
                padding: '30px 0px',
              }}
            >
              <h2 css={heading}>
                {t('__website_builder_specialities_title__')}
              </h2>
            </div>
          </div>
        </div>
        <div css={row}>
          <div css={[colSm12, colMd6, colLg6]}>
            <div css={sectionBox}>
              <div css={iconContainer}>
                <IconHandUsd
                  css={{
                    width: 50,
                    height: 50,
                    marginTop: -12,
                    verticalAlign: 'middle',
                  }}
                />
              </div>
              <h5 css={subHeader}>
                {t('__website_builder_specialities_free_title__')}
              </h5>
              <p>{t('__website_builder_specialities_free_content__')}</p>
            </div>
          </div>
          <div css={[colSm12, colMd6, colLg6]}>
            <div css={sectionBox}>
              <div css={iconContainer}>
                <IconRestaurant
                  css={{
                    width: 60,
                    height: 60,
                    marginTop: -6,
                    verticalAlign: 'middle',
                  }}
                />
              </div>
              <div>
                <h5 css={subHeader}>
                  {t(
                    '__website_builder_specialities_builtforrestaurants_title__',
                    locale,
                  )}
                </h5>
                <p>
                  {t(
                    '__website_builder_specialities_builtforrestaurants_content__',
                    locale,
                  )}
                </p>
              </div>
            </div>
          </div>
          <div css={[colSm12, colMd6, colLg6]}>
            <div css={sectionBox}>
              <div css={iconContainer}>
                <IconEasy
                  css={{
                    width: 60,
                    height: 60,
                    marginTop: -6,
                    verticalAlign: 'middle',
                  }}
                />
              </div>
              <h5 css={subHeader}>
                {t('__website_builder_specialities_easytouse_title__')}
              </h5>
              <p>
                {t(
                  '__website_builder_specialities_easytouse_content__',
                  locale,
                )}
              </p>
            </div>
          </div>
          <div css={[colSm12, colMd6, colLg6]}>
            <div css={sectionBox}>
              <div css={iconContainer}>
                <IconMaintenance
                  css={{ width: 50, height: 50, verticalAlign: 'middle' }}
                />
              </div>
              <h5 css={subHeader}>
                {t(
                  '__website_builder_specialities_nomaintenancerequired_title__',
                  locale,
                )}
              </h5>
              <p>
                {t(
                  '__website_builder_specialities_nomaintenancerequired_content__',
                  locale,
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyUseWebsiteBuilderSection
