/* Copyright 2013 - 2024 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import getArticles from '@polyblog/client/getArticles.js'

export default function useArticles(parameters, options) {
  return useQuery({
    queryKey: ['articles', { ...parameters }],
    queryFn: () => getArticles(parameters),
    ...options,
  })
}
