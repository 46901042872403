/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css } from '@emotion/react'
import underlinePartial from './underlinePartial.js'

export const sectionContainer = css`
  background: #f5f7fb;
  padding: 60px 0;

  @media (max-width: 1199px) {
    padding: 40px 0;
  }

  @media (max-width: 767px) {
    padding: 25px 0;
  }
`

export const sectionBox = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: white;
  padding: 60px;
  text-align: center;

  @media (max-width: 992px) {
    padding: 30px;
  }
`

export const title = css`
  ${underlinePartial}
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 25px;
  text-transform: capitalize;

  @media (max-width: 992px) {
    line-height: 32px;
    font-size: 32px;
  }
`

export const subtitle = css`
  padding: 0 80px;
  line-height: 32px;
  margin: 32px 16px;

  @media (max-width: 992px) {
    padding: 0;
    line-height: 26px;
    margin: 16px 8px;
  }
`

export const subHeader = css`
  font-size: 30px;
  text-align: left;
  text-transform: capitalize;

  @media (max-width: 992px) {
    text-align: center;
    line-height: 32px;
    font-size: 26px;
  }
`

export const stepNumber = css`
  background: #f4f4f4;
  border-radius: 90px;
  color: var(--color-primary);
  display: inline-block;
  fill: var(--color-primary);
  margin-top: 60px;
  font-size: 48px;
  height: 86px;
  line-height: 86px;
  text-align: center;
  width: 86px;
  vertical-align: middle;
`

export const stepName = css`
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  padding: 20px;
`

export const stepContent = css`
  padding: 20px 40px 0px 40px;
`
