/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import faviconUrl from '@waiterio/shared/faviconUrl.js'
import Layout from '../components/LayoutComponent.js'
import languages from '../languages.js'
import PricingSection from './PricingSection.js'

export const Head = ({ locale }) => {
  const { t } = useTranslation(locale)

  const title = `${t('Pricing')} | ${t(
    'Waiterio Restaurant Point of Sale',
    locale,
  )}`
  const description = `${t('__pricing_meta_description__')}`
  const url = `https://www.waiterio.com/${locale}/pricing/`

  return (
    <>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={faviconUrl} />
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={faviconUrl} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={faviconUrl} />
    </>
  )
}

export const paths = languages.map(locale => ({
  path: `/${locale}/pricing/`,
  props: {
    locale,
  },
}))

const PricingPage = ({ locale }) => (
  <Layout locale={locale}>
    <div css={{ height: 90, backgroundColor: '#f5f7fb' }} />
    <PricingSection locale={locale} />
  </Layout>
)

export default PricingPage
