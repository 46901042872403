/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'

const IconIosPlay = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-ios-play'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 512 512"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M128 104.3v303.4c0 6.4 6.5 10.4 11.7 7.2l240.5-151.7c5.1-3.2 5.1-11.1 0-14.3L139.7 97.2c-5.2-3.3-11.7.7-11.7 7.1z" />
    </svg>
  )
}

export default IconIosPlay
