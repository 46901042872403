/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import primaryHeading from '@waiterio/www/styles/primaryHeading.js'
import orderTrackingIcon from '../../images/order-tracking.svg'
import restaurantAvailabilityIcon from '../../images/restaurant-availability.svg'
import multipleDevicesIcon from '../../images/multiple-devices.svg'
import fastSystem from '../../images/fast-system.svg'

const sectionContainer = css`
  margin: 120px 0px;

  @media (max-width: 992px) {
    margin: 80px 0px;
  }

  @media (max-width: 768px) {
    margin: 40px 0px;
  }
`

const sectionBox = css`
  display: flex;
  justify-content: space-around;
  margin-top: 120px;

  @media (max-width: 992px) {
    flex-direction: column;
    margin-top: 60px;
    justify-content: center;
    align-items: center;
  }
`

const imageBox = css`
  margin: 0 10px;

  @media (max-width: 992px) {
    order: 2;
  }
`

const image = css`
  width: 250px;
  height: 250px;

  @media (max-width: 992px) {
    order: 2;
    margin: 30px;
    width: 180px;
    height: 180px;
  }

  @media (max-width: 576px) {
    width: 150px;
    height: 150px;
  }
`

const subHeader = css`
  font-size: 30px;
  text-align: left;
  text-transform: capitalize;

  @media (max-width: 992px) {
    text-align: center;
    line-height: 32px;
    font-size: 26px;
  }

  @media (max-width: 768px) {
    font-size: 24px;
  }
`

const textContent = css`
  max-width: 400px;

  @media (max-width: 992px) {
    max-width: 350px;
    text-align: center;
  }
`

const FeaturesSection = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <section css={sectionContainer}>
      <div css={container}>
        <div css={row}>
          <div css={[colSm12, { textAlign: 'center' }]}>
            <h2 css={primaryHeading}>
              {t('__online_ordering_features_title__')}
            </h2>
            <p css={{ maxWidth: 400, textAlign: 'center', margin: 'auto' }}>
              {t('__online_ordering_features_subtitle__')}
            </p>
          </div>
        </div>

        <div css={sectionBox}>
          <div css={{ margin: 'auto 10px' }}>
            <h3 css={subHeader}>
              {t('__online_ordering_features_tracking_title__')}
            </h3>
            <p css={textContent}>
              {t('__online_ordering_features_tracking_content__')}
            </p>
          </div>
          <div css={imageBox}>
            <img src={orderTrackingIcon} alt="order tracking" css={image} />
          </div>
        </div>

        <div css={sectionBox}>
          <div css={imageBox}>
            <img
              src={restaurantAvailabilityIcon}
              alt="set restaurant availibity"
              css={image}
            />
          </div>
          <div css={{ margin: 'auto 10px' }}>
            <h3 css={subHeader}>
              {t('__online_ordering_features_setavailability_title__')}
            </h3>
            <p css={textContent}>
              {t(
                '__online_ordering_features_setavailability_content__',
                locale,
              )}
            </p>
          </div>
        </div>

        <div css={sectionBox}>
          <div css={{ margin: 'auto 10px' }}>
            <h3 css={subHeader}>
              {t(
                '__online_ordering_features_managefromanywhere_title__',
                locale,
              )}
            </h3>
            <p css={textContent}>
              {t(
                '__online_ordering_features_managefromanywhere_content__',
                locale,
              )}
            </p>
          </div>
          <div css={imageBox}>
            <img
              src={multipleDevicesIcon}
              alt="manage from multiple devices"
              css={image}
            />
          </div>
        </div>

        <div css={sectionBox}>
          <div css={imageBox}>
            <img src={fastSystem} alt="fast system" css={image} />
          </div>
          <div css={{ margin: 'auto 10px' }}>
            <h3 css={subHeader}>
              {t('__online_ordering_features_superfastsystem_title__')}
            </h3>
            <p css={textContent}>
              {t(
                '__online_ordering_features_superfastsystem_content__',
                locale,
              )}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturesSection
