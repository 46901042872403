/* Copyright 2013 - 2024 Waiterio LLC */
import ClientError from './ClientError.js'

export default function failure(error) {
  let outputError = ''

  if (error?.method) {
    outputError += `${error.method} `
  }

  if (error?.url) {
    outputError += `${error.url} `
  }

  if (error?.status) {
    outputError += `${error.status} `
  }

  if (error?.name) {
    outputError += `${error.name} `
  }

  if (error?.message) {
    let outputMessage = error.message

    outputMessage = outputMessage
      .split('\n')
      .map(line => `\t${line}`)
      .join('\n')

    outputError += `\nMESSAGE\n${outputMessage}`
  }

  if (error?.stack) {
    let outputStack = error.stack

    outputStack = outputStack
      .split('\n')
      .map(line => `\t${line}`)
      .join('\n')

    outputError += `\nSTACK\n${outputStack}`
  }

  outputError = outputError.trim()

  console.error(outputError)

  throw new ClientError(error)
}
