/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import colMd12 from '@waiterio/styles/bootstrap/colMd12.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import {
  bannerSection,
  bannerBackground,
  bannerForeground,
  bannerTitle,
  bannerSubtitle,
} from '@waiterio/www/styles/banner.js'
import button from '@waiterio/www/styles/button.js'
import LaptopFrameComponent from '../../components/LaptopFrameComponent.js'

const BannerSection = ({ locale }) => {
  const { t } = useTranslation(locale)

  const fallbackImage = event => {
    let href = event?.target?.getAttribute('href')

    if (event?.target?.src?.indexOf(`${locale}.jpg`) > 0) {
      event.target.src = event.target.src.replace(`${locale}.jpg`, 'en.jpg')
    } else if (href?.indexOf(`${locale}.jpg`) > 0) {
      event.target.setAttribute('href', href.replace(`${locale}.jpg`, 'en.jpg'))
    }
  }

  return (
    <section css={bannerSection}>
      <div css={bannerBackground}>
        <svg
          viewBox="0 0 100 9"
          css={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
          }}
        >
          <path d="M0,0 C30,12 70,12 100,0 L100,12 L 0,12" fill="#f5f7fb" />
        </svg>
      </div>
      <div css={container}>
        <div css={bannerForeground}>
          <div css={row}>
            <div css={colMd12}>
              <h1 css={bannerTitle}>{t('Restaurant POS system')}</h1>
              <h2 css={bannerSubtitle}>
                {t(
                  'simple point of sale system for restaurants and bars',
                  locale,
                )}
              </h2>
              <a href="https://app.waiterio.com/signup" css={button}>
                {t('Try it for free')}
              </a>
            </div>
            <div css={colMd12}>
              <div css={{ position: 'relative', marginTop: 50 }}>
                <LaptopFrameComponent />
                <img
                  css={{
                    position: 'absolute',
                    top: '11.42%',
                    left: '13.37%',
                    width: '73.52%',
                  }}
                  src={`https://www.imagelato.com/images/screenshot-2e0628e9-order-computer-${locale}.jpg`}
                  onError={fallbackImage}
                />
                <svg
                  viewBox="0 0 1024 640"
                  css={{
                    position: 'absolute',
                    left: 0,
                    bottom: '5%',
                    width: '55%',
                  }}
                >
                  <rect
                    x="0"
                    y="0"
                    width="1024"
                    height="640"
                    rx="60"
                    css={{ fill: 'white' }}
                  />
                  <circle
                    cx="60"
                    cy="320"
                    r="25"
                    height="100"
                    css={{ fill: '#f6f6f6' }}
                  />
                  <image
                    x="80"
                    y="50"
                    width="864"
                    height="540"
                    href={`https://www.imagelato.com/images/screenshot-2e0628e9-orders-tablet-${locale}.jpg`}
                  />
                </svg>
                <div
                  css={{
                    position: 'absolute',
                    bottom: 0,
                    left: '42%',
                    width: '16.2%',
                  }}
                >
                  <img
                    css={{ border: '0.4vw solid white', borderRadius: '1vw' }}
                    src={`https://www.imagelato.com/images/screenshot-2e0628e9-tables-smartphone-tall-${locale}.jpg`}
                    onError={fallbackImage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BannerSection
