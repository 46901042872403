/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import faviconUrl from '@waiterio/shared/faviconUrl.js'
import Layout from '../../components/LayoutComponent.js'
import languages from '../../languages.js'
import BannerSection from './BannerSection.js'
import HowItWorksSection from './HowItWorksSection.js'
import RestaurantManagementOverviewSection from './RestaurantManagementOverviewSection.js'
import AdvantagesSection from './AdvantagesSection.js'
import CallToActionSection from './CallToActionSection.js'
import slugsMenuWithQRCode from '../../slugsMenuWithQRCode.js'

export const Head = ({ locale }) => {
  const { t } = useTranslation(locale)

  const title = `Waiterio | ${t('__menu_with_qrcode_banner_title__')}`
  const description = `${t('__menu_with_qrcode_banner_subtitle__')}`

  const url = `https://www.waiterio.com/${locale}/${slugsMenuWithQRCode[locale]}/`

  return (
    <>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={faviconUrl} />
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={faviconUrl} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={faviconUrl} />
    </>
  )
}

export const paths = languages.map(locale => ({
  path: `/${locale}/${slugsMenuWithQRCode[locale] || slugsMenuWithQRCode.en}/`,
  props: {
    locale,
  },
}))

const MenuWithQrCodePage = ({ locale }) => (
  <Layout locale={locale}>
    <BannerSection locale={locale} />
    <HowItWorksSection locale={locale} />
    <RestaurantManagementOverviewSection locale={locale} />
    <AdvantagesSection locale={locale} />
    <CallToActionSection locale={locale} />
  </Layout>
)

export default MenuWithQrCodePage
