/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  cs: 'restaurace-pos-system',
  da: 'restaurant-pos-system',
  de: 'software-restaurant-kassensystem',
  el: 'estiatorio-pos-system',
  en: 'restaurant-pos-system',
  es: 'tpv-software-restaurante',
  fr: 'systeme-de-caisse-restaurant-pdv',
  id: 'restoran-pos-system',
  it: 'pos-software-ristorante',
  nl: 'restaurant-kassa-systeem',
  pl: 'restauracji-pos-system',
  pt: 'software-para-restaurante',
  ru: 'avtomatizatsii-restorana-sistema',
  th: 'ranaharn-pos-rabob',
  tl: 'restawran-pos-sistema',
  tr: 'restoran-pos-sistemi',
  zh: 'cantīng-de-pos-xìtong',
}
