/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint react/no-unescaped-entities: ["error", {"forbid": ['>', '"', '}'] }] */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'

const clientsSections = css`
  background-color: white;
  padding: 70px 0 100px 0;

  @media (max-width: 1199px) {
    padding: 50px 0 70px 0;
  }

  @media (max-width: 767px) {
    padding: 30px 0 50px 0;
  }
`

const clientImage = css`
  vertical-align: middle;
  width: '100%';
`
const ClientsSection = () => (
  <section css={clientsSections}>
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        backgroundColor: 'white',
        marginTop: 60,
        img: {
          width: 165,
          height: 165,
        },
        div: {
          paddingLeft: 16,
          paddingRight: 16,
        },
        '@media(max-width: 992px)': {
          marginTop: 30,
          div: {
            marginTop: 30,
          },
        },
      }}
    >
      <div>
        <a
          href="https://restaurantebiostalatona.waiterio.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <picture>
            <source
              type="image/webp"
              srcSet="https://www.imagelato.com/images/restaurant-logo-bios-fca36363-320w.webp"
            />
            <img
              css={clientImage}
              src="https://www.imagelato.com/images/restaurant-logo-bios-fca36363-320w.jpg"
              alt="Bios restaurant logo"
            />
          </picture>
        </a>
      </div>
      <div>
        <a
          href="https://www.supadupa.ro/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <picture>
            <source
              type="image/webp"
              srcSet="https://www.imagelato.com/images/restaurant-logo-supadupa-5049b459-320w.webp"
            />
            <img
              css={clientImage}
              src="https://www.imagelato.com/images/restaurant-logo-supadupa-5049b459-320w.jpg"
              alt="supadupa restaurant logo"
            />
          </picture>
        </a>
      </div>
      <div>
        <a
          href="https://kamisushi.waiterio.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <picture>
            <source
              type="image/webp"
              srcSet="https://www.imagelato.com/images/restaurant-logo-kamisushi-d3b3e63a-320w.webp"
            />
            <img
              css={clientImage}
              src="https://www.imagelato.com/images/restaurant-logo-kamisushi-d3b3e63a-320w.jpg"
              alt="Bio's restaurant logo"
            />
          </picture>
        </a>
      </div>
      <div>
        <a
          href="https://larosa.waiterio.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <picture>
            <source
              type="image/webp"
              srcSet="https://www.imagelato.com/images/restaurant-logo-laRosa-9b178ad9-320w.webp"
            />
            <img
              css={clientImage}
              src="https://www.imagelato.com/images/restaurant-logo-laRosa-9b178ad9-320w.jpg"
              alt="Kamisushi restaurant logo"
            />
          </picture>
        </a>
      </div>
      <div>
        <a
          href="https://www.facebook.com/stolowka.rodzinna"
          target="_blank"
          rel="noopener noreferrer"
        >
          <picture>
            <source
              type="image/webp"
              srcSet="https://www.imagelato.com/images/restaurant-logo-rodzinna-83109ff2-320w.webp"
            />
            <img
              css={clientImage}
              src="https://www.imagelato.com/images/restaurant-logo-rodzinna-83109ff2-320w.jpg"
              alt="Rodzinna restaurant logo"
            />
          </picture>
        </a>
      </div>
      <div>
        <a
          href="https://dnoodles.waiterio.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <picture>
            <source
              type="image/webp"
              srcSet="https://www.imagelato.com/images/restaurant-logo-dnoodles-d2671832-320w.webp"
            />
            <img
              css={clientImage}
              src="https://www.imagelato.com/images/restaurant-logo-dnoodles-d2671832-320w.jpg"
              alt="Dnoodles restaurant logo"
            />
          </picture>
        </a>
      </div>
    </div>
  </section>
)

export default ClientsSection
