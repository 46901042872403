/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, useRef, useState } from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import useDocuments from '@globo/react/useDocuments.js'
import docsSlugs from '@waiterio/docs/slugs.js'
import globoWebsiteId from '@waiterio/shared/globoWebsiteId.js'
import IconCollapse from '@stiloso/icons/IconCollapse.js'
import IconExpand from '@stiloso/icons/IconExpand.js'
import primaryHeading from '@waiterio/www/styles/primaryHeading.js'

const questionTitle = css({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 600,
  cursor: 'pointer',
  backgroundColor: '#f5f7fb',
  borderRadius: 4,
  marginTop: 30,
  ':focus, :hover': {
    color: 'var(--color-primary)',
  },
})

const iconCollapseExpand = css({
  background: 'var(--color-primary)',
  fill: 'white',
  width: 48,
  height: 48,
  padding: 14,
})

const questionContent = css({
  overflow: 'hidden',
  background: 'white',
  border: '1px solid #eeeeee',
  borderTop: 'none',
  boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.06)',
  transition: 'all .5s ease-in-out',
  p: {
    marginBottom: 16,
    marginTop: 0,
  },
  a: {
    color: 'var(--color-primary)',
  },
})

const sectionContainer = css`
  margin: 160px 0px;

  @media (max-width: 1199px) {
    margin: 128px 0px;
  }

  @media (max-width: 992px) {
    margin: 96px 0px;
  }

  @media (max-width: 767px) {
    margin: 64px 0px;
  }

  @media (max-width: 576px) {
    margin: 32px 0px;
  }
`

const QuestionsSection = ({ locale }) => {
  const { t } = useTranslation(locale)
  let { data: documents } = useDocuments({
    websiteId: globoWebsiteId,
    locale: docsSlugs[locale] ? locale : 'en',
  })

  if (documents) {
    documents = documents.map(document => {
      let splits = document?.content?.split('</p>') || []
      splits = splits.map(split => split.replace('<p>', ''))
      let content = ''
      let url
      let i = 0

      while (i < splits.length && !url) {
        let split = splits[i]
        if (content.length < 1000) {
          content += split
        } else {
          url = `/docs/${document.locale}/${document.slugLocalized}/`
        }
        i += 1
      }

      return {
        ...document,
        content,
        url,
      }
    })
  }

  return (
    <section css={sectionContainer}>
      <div css={[{ padding: 60, '@media(max-width: 992px)': { padding: 30 } }]}>
        <div css={{ textAlign: 'center' }}>
          <h2 css={primaryHeading}>{t('__home_questions_title__')}</h2>
        </div>
        <div css={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <div css={{ margin: '0 auto', maxWidth: 1140, width: '100%' }}>
            {documents?.map(({ title, content, url }) => (
              <QuestionAccordion
                key={title}
                title={title}
                content={content}
                locale={locale}
                url={url}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default QuestionsSection

const QuestionAccordion = props => {
  let { locale, content, title, url } = props
  const { t } = useTranslation(locale)
  let [expanded, setExpanded] = useState(props.expanded)
  let [height, setHeight] = useState(0)
  const contentRef = useRef()

  useEffect(() => {
    function changeHeight(event) {
      const { scrollHeight } = contentRef.current

      setHeight(scrollHeight)
    }

    window.addEventListener('resize', changeHeight)

    changeHeight()

    return function cleanup() {
      window.removeEventListener('resize', changeHeight)
    }
  }, [contentRef])

  const onClick = () => {
    const { scrollHeight } = contentRef.current

    setExpanded(expanded => !expanded)
    setHeight(scrollHeight)
  }

  if (url) {
    content += `
        <br />
        <br />
        <a href=${url}>${t('Read the rest of the question')}</a></>
      `
  }

  return (
    <div>
      <div css={questionTitle} onClick={onClick}>
        <div css={{ flexGrow: 1, padding: '0 15px' }}>{title}</div>
        {expanded ? (
          <IconCollapse css={iconCollapseExpand} />
        ) : (
          <IconExpand css={iconCollapseExpand} />
        )}
      </div>
      <div
        ref={contentRef}
        css={[
          questionContent,
          {
            height: expanded ? height : 0,
          },
        ]}
      >
        <div
          css={{ padding: 15 }}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </div>
    </div>
  )
}
