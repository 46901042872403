/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import heading from '@waiterio/www/styles/heading.js'

const sectionBox = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 120px;
  background-color: var(--color-primary);

  @media (max-width: 992px) {
    padding: 60px;

    h2 {
      line-height: 38px;
      text-align: center;
      font-size: 32px;
    }

    p {
      margin: 16px 8px;
    }
  }
`

const ClosingSection = ({ sectionId, locale, title, subtitle }) => {
  const { t } = useTranslation(locale)

  return (
    <section id={`${sectionId}-closing-section`}>
      <div css={sectionBox}>
        <h2 css={heading}>{title}</h2>
        <p css={{ textAlign: 'center', color: 'white', margin: '36px 0px' }}>
          {subtitle}
        </p>
        <a
          href="https://app.waiterio.com/signup"
          target="_blank"
          rel="noreferrer"
          css={{
            backgroundColor: 'white',
            borderRadius: 4,
            color: 'black',
            display: 'inline-block',
            fontSize: 16,
            fontWeight: '500',
            padding: '12px 36px',
            textAlign: 'center',
            ':hover, :focus': {
              color: 'white',
              background: 'var(--color-green)',
            },
          }}
        >
          {t('Try it for free')}
        </a>
      </div>
    </section>
  )
}

export default ClosingSection
