/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint max-len: 0 */

import React from 'react'

const IconForkSpoon = props => {
  const { className, width = 24, height = 24, onClick, style } = props
  const dataTestId = props['data-testid'] || 'icon-fork-spoon'

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      onClick={onClick}
      data-testid={dataTestId}
    >
      <path d="M16.104 3.528c-1.176 0.6-2.112 2.448-2.112 4.248 0 1.224 1.080 3.168 1.944 3.456 1.272 0.456 1.368 0.744 0.888 2.376-1.392 4.632-0.6 8.064 1.68 7.2 0.528-0.24 1.104-1.704 1.008-2.64-0.216-1.968-0.576-4.272-0.84-5.232-0.36-1.176-0.336-1.2 0.72-1.728 1.080-0.552 2.232-2.808 2.064-4.008-0.456-3.072-3-4.8-5.352-3.672zM2.976 4.56c-0.048 0.528-0.12 1.536-0.144 2.28-0.12 1.92 0.84 3.96 2.136 4.464 1.2 0.504 1.2 0.504 0.528 3.024-0.552 2.208-0.696 4.176-0.36 5.232 0.264 0.84 1.632 1.272 2.52 0.768 0.84-0.432 0.912-2.928 0.168-5.664-0.648-2.52-0.624-2.904 0.408-3.408 1.344-0.624 2.208-2.328 2.16-4.392 0-2.088-0.288-3.288-0.816-3.096-0.264 0.072-0.408 1.032-0.408 2.592-0.024 1.368-0.144 2.664-0.312 2.904-0.528 0.912-0.864-0.096-0.864-2.808 0-2.256-0.096-2.784-0.48-2.784-0.48 0-0.672 0.384-0.552 1.104 0.216 1.152 0.048 4.368-0.216 4.632-0.576 0.552-1.104-0.936-0.936-2.64 0.168-1.776-0.048-3.096-0.504-3.096-0.168 0-0.312 1.2-0.264 2.616 0.024 2.784-0.216 3.816-0.744 3-0.168-0.264-0.288-1.392-0.288-2.52s-0.096-2.28-0.216-2.568c-0.336-0.84-0.792-0.624-0.816 0.36z" />
    </svg>
  )
}

export default IconForkSpoon
