/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint react/no-unescaped-entities: ["error", {"forbid": ['>', '"', '}'] }] */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import colLg3 from '@waiterio/styles/bootstrap/colLg3.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import underlinePartial from '../../styles/underlinePartial.js'

const howItWorksSection = css`
  background: #f5f7fb;
  padding: 100px 0;

  @media (max-width: 1199px) {
    padding: 80px 0;
  }

  @media (max-width: 767px) {
    padding: 50px 0;
  }
`

const howItWorksBox = css`
  background: white;
  padding: 60px 15px;
  text-align: center;
`

const title = css`
  ${underlinePartial}
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 25px;
`

const subtitle = css`
  padding: 0 80px;
  font-size: 24px;
  line-height: 36px;
  margin: 0;

  @media (max-width: 992px) {
    padding: 0;
  }
`

const stepNumber = css`
  background: #f4f4f4;
  border-radius: 90px;
  color: var(--color-primary);
  display: inline-block;
  fill: var(--color-primary);
  margin-top: 60px;
  font-size: 48px;
  height: 86px;
  line-height: 86px;
  text-align: center;
  width: 86px;
  vertical-align: middle;
`

const stepName = css`
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  padding: 20px;
`

const stepContent = css`
  padding: 20px 40px 0px 40px;
`

const HowItWorksSection = ({ locale }) => {
  const { t } = useTranslation(locale)
  return (
    <section id="how-it-works" css={howItWorksSection}>
      <div css={container}>
        <div css={howItWorksBox}>
          <div css={row}>
            <div css={colSm12}>
              <h3 css={title}>{t('__home_howitworks_title__')}</h3>
              <p css={subtitle}>{t('__home_howitworks_subtitle__')}</p>
            </div>
          </div>
          <div css={[row, { paddingTop: 40 }]}>
            <div css={colLg3}>
              <div css={stepNumber}>1</div>
              <div css={stepName}>{t('__home_howitworks_step_1_title__')}</div>
              <div css={stepContent}>
                {t('__home_howitworks_step_1_name__')}
              </div>
            </div>
            <div css={colLg3}>
              <div css={stepNumber}>2</div>
              <div css={stepName}>{t('__home_howitworks_step_2_title__')}</div>
              <div css={stepContent}>
                {t('__home_howitworks_step_2_name__')}
              </div>
            </div>
            <div css={colLg3}>
              <div css={stepNumber}>3</div>
              <div css={stepName}>{t('__home_howitworks_step_3_title__')}</div>
              <div css={stepContent}>
                {t('__home_howitworks_step_3_name__')}
              </div>
            </div>
            <div css={colLg3}>
              <div css={stepNumber}>4</div>
              <div css={stepName}>{t('__home_howitworks_step_4_title__')}</div>
              <div css={stepContent}>
                {t('__home_howitworks_step_4_name__')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HowItWorksSection
