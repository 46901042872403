/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  cs: 'menu-restaurace-s-qrcode',
  da: 'restaurant-menu-med-qrcode',
  de: 'Restaurantmenü-mit-qrcode',
  el: 'menou-estiatoriou-me-qrcode',
  en: 'restaurant-menu-with-qrcode',
  es: 'menu-de-restaurante-con-qrcode',
  fr: 'scarte-du-restaurant-avec-qrcode',
  id: 'menu-restoran-dengan-qrcode',
  it: 'menu-ristorante-con-qrcode',
  nl: 'restaurantmenu-met-qrcode',
  pl: 'menu-restauracji-z-qrcode',
  pt: 'menu-do-restaurante-com-qrcode',
  ru: 'menyu-restorana-s-qrcode',
  th: 'Menu-ran-xahar-phrxm-qrcode',
  tl: 'menu-ng-restawran-na-may-qrcode',
  tr: 'qrcode-ile-restoran-menusu',
  zh: 'dai-you-er-wei-ma-de-canting-caidan',
}
