/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import faviconUrl from '@waiterio/shared/faviconUrl.js'
import Layout from '../../components/LayoutComponent.js'
import languages from '../../languages.js'
import BannerSection from './BannerSection.js'
import BlogSection from './BlogSection.js'
import ClientsSection from './ClientsSection.js'
// import FeaturesSection from './FeaturesSection'
import CountersSection from './CountersSection.js'
import QuestionsSection from './QuestionsSection.js'
import PricingSection from '../PricingSection.js'
import ReviewsSection from './ReviewsSection.js'
// import ScreenshotsSection from './ScreenshotsSection'
import WhyUseWaiterioSection from './WhyUseWaiterioSection.js'
import ProductsOverviewSection from './ProductsOverviewSection.js'

export const Head = ({ locale }) => {
  const { t } = useTranslation(locale)

  const title = `Waiterio | ${t(
    'Restaurant Management Software',
    locale,
  )} | ${t('Fast And Easy')}`
  const description = `${t('__home_meta_description__')}`
  const url = `https://www.waiterio.com/${locale}/`
  return (
    <>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={faviconUrl} />
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={faviconUrl} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={faviconUrl} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org/',
            '@type': 'WebPage',
            '@id': `https://www.waiterio.com/${locale}/`,
            url: `https://www.waiterio.com/${locale}/`,
            inLanguage: locale,
            isPartOf: {
              '@id': `https://www.waiterio.com/${locale}/#website`,
            },
            author: {
              '@id': 'https://www.waiterio.com/#corporation',
            },
            publisher: {
              '@id': 'https://www.waiterio.com/#corporation',
            },
            translationOfWork: {
              '@id': 'https://www.waiterio.com/en/#website',
            },
            '@graph': [
              {
                '@type': 'WebSite',
                '@id': `https://www.waiterio.com/${locale}/#website`,
                name: 'Waiterio',
                url: `https://www.waiterio.com/${locale}/#website`,
                inLanguage: locale,
                author: {
                  '@id': 'https://www.waiterio.com/#corporation',
                },
                publisher: {
                  '@id': 'https://www.waiterio.com/#corporation',
                },
              },
              {
                '@type': 'Corporation',
                '@id': 'https://www.waiterio.com/#corporation',
                name: 'Waiterio',
                url: 'https://www.waiterio.com/#corporation',
                sameAs: [
                  'https://www.facebook.com/waiterio',
                  'https://www.twitter.com/waiterio',
                  'https://www.linkedin.com/company/waiter-io',
                  'https://www.youtube.com/waiterio',
                ],
              },
            ],
          }),
        }}
      />
    </>
  )
}

export const paths = languages.map(locale => ({
  path: `/${locale}/`,
  props: {
    locale,
  },
}))

const HomePage = ({ locale = 'en' }) => {
  let [didScroll, setDidScroll] = useState(false)

  useEffect(() => {
    function onScroll(event) {
      if (window.pageYOffset > 100 && !didScroll) {
        setDidScroll(true)
      } else if (window.pageYOffset < 100 && didScroll) {
        setDidScroll(false)
      }
    }

    window.addEventListener('scroll', onScroll)

    return function cleanup() {
      window.removeEventListener('scroll', onScroll)
    }
  }, [didScroll])

  let headerStyle = {
    boxShadow: '0 0 30px 0 rgba(0,0,0,.1)',
  }

  if (!didScroll) {
    headerStyle = {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    }
  }

  return (
    <Layout headerStyle={headerStyle} locale={locale}>
      <BannerSection locale={locale} />
      <WhyUseWaiterioSection locale={locale} />
      <ProductsOverviewSection locale={locale} />
      {/* <FeaturesSection locale={locale} /> */}
      {/* <ScreenshotsSection locale={locale} /> */}
      <CountersSection locale={locale} />
      <ReviewsSection locale={locale} />
      <PricingSection locale={locale} />
      <QuestionsSection locale={locale} />
      <BlogSection locale={locale} />
      <ClientsSection locale={locale} />
    </Layout>
  )
}

export default HomePage
