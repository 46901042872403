/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import primaryHeading from '@waiterio/www/styles/primaryHeading.js'
import convenienceIcon from '../../images/convenience.svg'
import takeawayDeliveryIcon from '../../images/takeaway-delivery.svg'
import completeSolutionIcon from '../../images/complete-solution.svg'

const sectionContainer = css`
  margin: 120px 0px;

  @media (max-width: 992px) {
    margin: 80px 0px;
  }

  @media (max-width: 768px) {
    margin: 40px 0px;
  }
`

const learnMoreButton = css`
  background-color: var(--color-primary);
  border-radius: 4px;
  color: white;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  margin: auto;
  padding: 12px 36px;
  text-align: center;
  &:hover,
  &:focus {
    color: white;
    background: var(--color-green);
  }
`

const imageContainer = css`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`

const image = css`
  width: 125px;
  height: 125px;

  @media (max-width: 992px) {
    width: 80px;
    height: 80px;
  }
`

const contentBox = css`
  max-width: 300px;
  margin: 60px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 992px) {
    margin: 40px 20px;
  }
`

const IncreaseYourProfitsSection = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <section css={sectionContainer}>
      <div css={container}>
        <div css={row}>
          <div css={[colSm12, { textAlign: 'center' }]}>
            <h2 css={primaryHeading}>
              {t(
                '__online_ordering_greatlyincreaseyourprofits_title__',
                locale,
              )}
            </h2>
            <p css={{ maxWidth: 400, textAlign: 'center', margin: 'auto' }}>
              {t(
                '__online_ordering_greatlyincreaseyourprofits_subtitle__',
                locale,
              )}
            </p>
          </div>
        </div>

        <div css={row}>
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
              justifyContent: 'space-between',
              '@media(max-width: 992px)': {
                flexDirection: 'column',
                alignItems: 'center',
              },
            }}
          >
            <div css={contentBox}>
              <div css={imageContainer}>
                <img
                  src={convenienceIcon}
                  alt="easily manage everything"
                  css={image}
                />
              </div>
              <h3 css={{ textTransform: 'capitalize' }}>
                {t(
                  '__online_ordering_greatlyincreaseyourprofits_1_title__',
                  locale,
                )}
              </h3>
              <p>
                {t(
                  '__online_ordering_greatlyincreaseyourprofits_1_content__',
                  locale,
                )}
              </p>
              <a href="https://app.waiterio.com/signup" css={learnMoreButton}>
                {t('Learn more')}
              </a>
            </div>

            <div css={contentBox}>
              <div css={imageContainer}>
                <img
                  src={takeawayDeliveryIcon}
                  alt="takeaway and delivery"
                  css={image}
                />
              </div>
              <h3 css={{ textTransform: 'capitalize' }}>
                {t(
                  '__online_ordering_greatlyincreaseyourprofits_2_title__',
                  locale,
                )}
              </h3>
              <p>
                {t(
                  '__online_ordering_greatlyincreaseyourprofits_2_content__',
                  locale,
                )}
              </p>
              <a href="https://app.waiterio.com/signup" css={learnMoreButton}>
                {t('Learn more')}
              </a>
            </div>

            <div css={contentBox}>
              <div css={imageContainer}>
                <img
                  src={completeSolutionIcon}
                  alt="complete solution"
                  css={image}
                />
              </div>
              <h3 css={{ textTransform: 'capitalize' }}>
                {t(
                  '__online_ordering_greatlyincreaseyourprofits_3_title__',
                  locale,
                )}
              </h3>
              <p>
                {t(
                  '__online_ordering_greatlyincreaseyourprofits_3_content__',
                  locale,
                )}
              </p>
              <a href="https://app.waiterio.com/signup" css={learnMoreButton}>
                {t('Learn more')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default IncreaseYourProfitsSection
