/* Copyright 2013 - 2024 Waiterio LLC */
import blue from '@waiterio/styles/blue.js'
import green from '@waiterio/styles/green.js'

const colorPrimary = blue

export default `

  :root {
    --color-base: #666666;

    --color-primary: ${colorPrimary};

    --color-green: ${green};
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body, html {
    height: 100%;
    width: 100%;
    line-height: 26px;
    margin: 0;
    outline: 0;
    color: var(--color-base);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: #419ed6;
  }

  h1, h2, h3, h4, h5, h6 {
    color: #333333;
    margin: 0
  }

  body, html, button {
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Fira Sans','Droid Sans','Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
  }

  a {
    color: var(--color-primary);
    fill: var(--color-primary);
    text-decoration: none;
  }

  a:hover {
    color: var(--color-green);
    fill: var(--color-green);
  }

  img {
    width: 100%;
  }

  iframe {
    border: 0;
    margin: 0;
  }

  .loader:empty {

    top: 50%;
    margin: 0px auto;
    transform: translateY(-50%);

    border-radius: 50%;
    width: 7em;
    height: 7em;

    font-size: 7px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(0, 0, 0, 0.2);
    border-right: 1.1em solid rgba(0, 0, 0, 0.2);
    border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
    border-left: 1.1em solid var(--color-primary);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: rotation 1.1s infinite linear;
    animation: rotation 1.1s infinite linear;
  }

  @-webkit-keyframes rotation {

    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }

  }

  @keyframes rotation {

    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }

  }

`
