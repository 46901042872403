/* Copyright 2013 - 2024 Waiterio LLC */

export default function getRootDomain(windowLocationHostname) {
  let rootDomain

  if (!windowLocationHostname && typeof window !== 'undefined') {
    windowLocationHostname = window.location.hostname
  }

  if (windowLocationHostname) {
    const regex = /.*\.([^.]*[^0-9][^.]*\.[^.]*[^.0-9][^.]*$)/
    rootDomain = windowLocationHostname.replace(regex, '$1')
  }

  return rootDomain
}
