/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import ENVIRONMENT from '@monorepo/env/ENVIRONMENT.js'
import STAGING from '@monorepo/env/STAGING.js'
import DEVELOPMENT from '@monorepo/env/DEVELOPMENT.js'
import PRODUCTION from '@monorepo/env/PRODUCTION.js'
import IS_SCREENSHOTS from '@monorepo/env/IS_SCREENSHOTS.js'

const StageBadge = ({ left, right }) => {
  if (right) {
    left = false
    right = true
  } else {
    left = true
    right = false
  }

  let environment = ENVIRONMENT
  let background = 'transparent'
  let color = 'transparent'

  // environment = STAGING

  if (environment === DEVELOPMENT) {
    environment = 'DEV'
    background = 'yellow'
    color = 'black'
  } else if (environment === STAGING) {
    environment = 'STAGE'
    background = 'blue'
    color = 'white'
  }

  return (
    ENVIRONMENT !== PRODUCTION &&
    !IS_SCREENSHOTS && (
      <div
        css={{
          color,
          background,
          textAlign: 'center',
          position: 'fixed',
          left: left ? '-32px' : null,
          right: right ? '-32px' : null,
          bottom: '-32px',
          opacity: '0.6',
          width: 64,
          height: 64,
          transform: `rotate(${right ? '-45deg' : '45deg'})`,
          lineHeight: '16px',
          fontSize: 12,
          fontWeight: 'bold',
          textTransform: 'uppercase',
        }}
      >
        {environment}
      </div>
    )
  )
}

export default StageBadge
