/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { jsx, css } from '@emotion/react'
import { backdrop, modal, modalOuter } from '@waiterio/components/Modal.js'

const videoWrapper = css({
  position: 'relative',
  paddingBottom: '56.25%',
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
})

const VideoDialog = ({ close }) => {
  let [loaded, setLoaded] = useState(false)

  return (
    <div css={modalOuter}>
      <div css={[modal, { minWidth: '280px', width: '75%' }]}>
        <div
          css={{ display: loaded ? 'none' : 'flex' }}
          style={{ minHeight: 80 }}
        >
          <div
            className="loader"
            css={{ alignSelf: 'center', height: 25, width: 25, minWidth: 25 }}
          />
        </div>
        <div css={[videoWrapper, { display: loaded ? 'block' : 'none' }]}>
          <iframe
            onLoad={_ => setLoaded(true)}
            title="this-is-luigi"
            allowFullScreen
            src="https://www.youtube.com/embed/xkkHJUSXNI0?autoplay=1&mute=1"
          />
        </div>
      </div>
      <div css={backdrop} onClick={close} />
    </div>
  )
}

export default VideoDialog
