/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css } from '@emotion/react'
import unselectable from '@waiterio/styles/unselectable.js'

export default css`
  label: button;
  ${unselectable};
  background-color: white;
  border-radius: 4px;
  color: #333;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  padding: 0 36px;
  text-align: center;
  line-height: 48px;
  @media (hover: hover) {
    &:not(.disabled):hover {
      color: white;
      background: var(--color-green);
      cursor: pointer;
    }
  }
`
