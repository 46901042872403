/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint-disable camelcase */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useSearchParams } from 'react-router-dom'

export const paths = ['/payment', '/payment.html']

const PaymentPage = () => {
  const [searchParams] = useSearchParams()
  const stripe_id = searchParams.get('stripe_id')

  const ckey = '1726kepbkkgaroultpksnvbvs'

  const payment_page_url =
    'https://payments.stunning.co/payment_update/' + ckey + '/' + stripe_id

  return (
    <iframe
      id="form"
      title="form"
      src={payment_page_url}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%',
        border: 'none',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
      }}
    />
  )
}

export default PaymentPage
