/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import IconDone from '@stiloso/icons/IconDone.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import primaryHeading from '@waiterio/www/styles/primaryHeading.js'

const sectionContainer = css`
  margin: 120px 0px;

  @media (max-width: 992px) {
    margin: 80px 0px;
  }

  @media (max-width: 768px) {
    margin: 40px 0px;
  }
`

const iconDone = css`
  width: 28px;
  height: 28px;
  fill: var(--color-primary);
`

const contentBox = css`
  display: flex;
  margin-left: auto;
  margin-right: auto;
`

const PosOverviewSection = ({ locale }) => {
  const { t } = useTranslation(locale)
  return (
    <section css={sectionContainer}>
      <div css={container}>
        <div css={row}>
          <div css={[colSm12, { textAlign: 'center' }]}>
            <h2 css={primaryHeading}>
              {t('__online_ordering_posoverview_title__')}
            </h2>
            <p css={{ maxWidth: 600, textAlign: 'center', margin: 'auto' }}>
              {t('__online_ordering_posoverview_subtitle__')}
            </p>
          </div>
        </div>

        <div
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: 30,
            p: {
              maxWidth: 450,
              textAlign: 'left',
            },
          }}
        >
          <div css={contentBox}>
            <div css={{ margin: '18px 10px' }}>
              <IconDone css={iconDone} />
            </div>
            <p>
              <strong>
                {t(
                  '__online_ordering_posoverview_noadditionalcosts_title__',
                  locale,
                )}
                :{' '}
              </strong>
              {t(
                '__online_ordering_posoverview_noadditionalcosts_content__',
                locale,
              )}
            </p>
          </div>

          <div css={contentBox}>
            <div css={{ margin: '18px 10px' }}>
              <IconDone css={iconDone} />
            </div>
            <p>
              <strong>
                {t(
                  '__online_ordering_posoverview_efficientmanagement_title__',
                  locale,
                )}
                :{' '}
              </strong>
              {t(
                '__online_ordering_posoverview_efficientmanagement_content__',
                locale,
              )}
            </p>
          </div>

          <div css={contentBox}>
            <div css={{ margin: '18px 10px' }}>
              <IconDone css={iconDone} />
            </div>
            <p>
              <strong>
                {t(
                  '__online_ordering_posoverview_updatemenuinstantly_title__',
                  locale,
                )}
                :{' '}
              </strong>
              {t(
                '__online_ordering_posoverview_updatemenuinstantly_content__',
                locale,
              )}
            </p>
          </div>

          <div css={contentBox}>
            <div css={{ margin: '18px 10px' }}>
              <IconDone css={iconDone} />
            </div>
            <p>
              <strong>
                {t(
                  '__online_ordering_posoverview_tracksalesandprofit_title__',
                  locale,
                )}
                :{' '}
              </strong>
              {t(
                '__online_ordering_posoverview_tracksalesandprofit_content__',
                locale,
              )}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PosOverviewSection
