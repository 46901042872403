/* Copyright 2013 - 2024 Waiterio LLC */

export default {
  ar: 'mateam-alnajah',
  ca: 'exit-del-restaurant',
  cs: 'uspech-restaurace',
  da: 'restaurant-succes',
  de: 'erfolgreiches-restaurant',
  el: 'estiatorio-epitychia',
  en: 'successful-restaurant',
  es: 'restaurante-exitoso',
  fr: 'restaurant-prospere',
  hr: 'uspjeh-restorana',
  hu: 'etterem-sikere',
  id: 'restoran-sukses',
  it: 'ristorante-di-successo',
  ja: 'resutoran-no-seiko',
  ko: 'sigdang-seong-gong',
  nl: 'succesvol-restaurant',
  pl: 'udana-restauracja',
  pt: 'restaurante-de-sucesso',
  ro: 'succesul-restaurantului',
  ru: 'uspeshnyy-restoran',
  sr: 'restoran-uspeh',
  sv: 'framgangsrik-restaurang',
  th: 'raan-aa-haan-tee-bpra-sop-kwaam-sam-ret',
  // tl: 'matagumpay-na-restawran',
  tr: 'restoran-basarisi',
  uk: 'restorannyy-uspikh',
  vi: 'thanh-cong-nha-hang',
  zh: 'chenggong-de-can',
}
