/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import colLg4 from '@waiterio/styles/bootstrap/colLg4.js'
import colMd12 from '@waiterio/styles/bootstrap/colMd12.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import primaryHeading from '@waiterio/www/styles/primaryHeading.js'

const section = css`
  margin: 80px 0px;

  @media (max-width: 992px) {
    margin: 64px 0px;
  }

  @media (max-width: 768px) {
    margin: 48px 0px;
  }
`

const HowItWorksSection = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <section css={section}>
      <div css={container}>
        <div css={row}>
          <div css={colMd12}>
            <h2 css={[primaryHeading, { textAlign: 'center' }]}>
              {t('__menu_with_qrcode_howitworks_title__')}
            </h2>
          </div>
        </div>

        <div css={row}>
          <div css={colMd12}>
            <div
              css={{
                marginTop: 32,
                maxWidth: 700,
                fontSize: 18,
                marginLeft: 'auto',
                marginRight: 'auto',
                color: '#959EAD',
                textAlign: 'center',
              }}
            >
              {t('__menu_with_qrcode_howitworks_subtitle__')}
              <div
                css={{
                  color: 'var(--color-primary)',
                  marginTop: 24,
                  fontWeight: 'bold',
                  fontSize: 24,
                  textTransform: 'capitalize',
                }}
              >
                {t('__menu_with_qrcode_howitworks_second_subtitle__')}
              </div>
            </div>
          </div>
        </div>

        <div
          css={[
            row,
            {
              marginTop: 96,
              marginBottom: 96,
              '@media(max-width: 992px)': { marginTop: 48, marginBottom: 48 },
            },
          ]}
        >
          <div css={[colMd12, colLg4]}>
            <div
              css={{
                position: 'relative',
                '@media(max-width: 992px)': {
                  marginTop: 72,
                },
                border: '1px solid rgba(224, 222, 222, 0.67)',
                boxShadow: '0px 5px 4px rgba(105, 105, 105, 0.25)',
                borderRadius: '20px',
              }}
            >
              <div
                css={{
                  borderRadius: 60,
                  background: '#024A75',
                  border: '2px solid rgba(224, 222, 222, 0.67)',
                  width: 85,
                  height: 85,
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: -40,
                  margin: 'auto',
                }}
              >
                <div
                  css={{
                    lineHeight: '75px',
                    textAlign: 'center',
                    fontSize: 48,
                    fontWeight: 'bold',
                    color: 'white',
                  }}
                >
                  1
                </div>
              </div>

              <h3
                css={{
                  margin: '72px 24px 24px 24px',
                  fontSize: 24,
                  lineHeight: '29px',
                  textAlign: 'center',
                  color: 'var(--color-primary)',
                }}
              >
                {t('__menu_with_qrcode_howitworks_step_1_title__')}
              </h3>
              <div css={{ textAlign: 'center', margin: '48px 0px' }}>
                <img
                  src="https://www.imagelato.com/images/menu-with-qrcode-how-it-works-step-1-e1c8c681.png"
                  css={{ height: 200, width: 'auto' }}
                />
              </div>
              <p css={{ margin: 24, textAlign: 'center', color: '#959EAD' }}>
                {t('__menu_with_qrcode_howitworks_step_1_content__')}
              </p>
            </div>
          </div>

          <div css={[colMd12, colLg4]}>
            <div
              css={{
                position: 'relative',
                '@media(max-width: 992px)': {
                  marginTop: 72,
                },
                border: '1px solid rgba(224, 222, 222, 0.67)',
                boxShadow: '0px 5px 4px rgba(105, 105, 105, 0.25)',
                borderRadius: '20px',
              }}
            >
              <div
                css={{
                  borderRadius: 60,
                  background: '#024A75',
                  border: '2px solid rgba(224, 222, 222, 0.67)',
                  width: 85,
                  height: 85,
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: -40,
                  margin: 'auto',
                }}
              >
                <div
                  css={{
                    lineHeight: '75px',
                    textAlign: 'center',
                    fontSize: 48,
                    fontWeight: 'bold',
                    color: 'white',
                  }}
                >
                  2
                </div>
              </div>

              <h3
                css={{
                  margin: '72px 24px 24px 24px',
                  fontSize: 24,
                  lineHeight: '29px',
                  textAlign: 'center',
                  color: 'var(--color-primary)',
                }}
              >
                {t('__menu_with_qrcode_howitworks_step_2_title__')}
              </h3>
              <div css={{ textAlign: 'center', margin: '48px 0px' }}>
                <img
                  src="https://www.imagelato.com/images/menu-with-qrcode-how-it-works-step-2-bd0933b0.png"
                  css={{ height: 200, width: 'auto' }}
                />
              </div>
              <p css={{ margin: 24, textAlign: 'center', color: '#959EAD' }}>
                {t('__menu_with_qrcode_howitworks_step_2_content__')}
              </p>
            </div>
          </div>

          <div css={[colMd12, colLg4]}>
            <div
              css={{
                position: 'relative',
                '@media(max-width: 992px)': {
                  marginTop: 72,
                },
                border: '1px solid rgba(224, 222, 222, 0.67)',
                boxShadow: '0px 5px 4px rgba(105, 105, 105, 0.25)',
                borderRadius: '20px',
              }}
            >
              <div
                css={{
                  borderRadius: 60,
                  background: '#024A75',
                  border: '2px solid rgba(224, 222, 222, 0.67)',
                  width: 85,
                  height: 85,
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: -40,
                  margin: 'auto',
                }}
              >
                <div
                  css={{
                    lineHeight: '75px',
                    textAlign: 'center',
                    fontSize: 48,
                    fontWeight: 'bold',
                    color: 'white',
                  }}
                >
                  3
                </div>
              </div>

              <h3
                css={{
                  margin: '72px 24px 24px 24px',
                  fontSize: 24,
                  lineHeight: '29px',
                  textAlign: 'center',
                  color: 'var(--color-primary)',
                }}
              >
                {t('__menu_with_qrcode_howitworks_step_3_title__')}
              </h3>
              <div css={{ textAlign: 'center', margin: '48px 0px' }}>
                <img
                  src="https://www.imagelato.com/images/menu-with-qrcode-how-it-works-step-3-eca1e753.png"
                  css={{ height: 200, width: 'auto' }}
                />
              </div>
              <p css={{ margin: 24, textAlign: 'center', color: '#959EAD' }}>
                {t('__menu_with_qrcode_howitworks_step_3_content__')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HowItWorksSection
