/* Copyright 2013 - 2024 Waiterio LLC */
import getSentryBrowser from './getSentryBrowser.js'

export default function captureExceptionBrowser(error) {
  const sentry = getSentryBrowser()

  if (sentry && sentry.captureException) {
    sentry.captureException(error)
  }
}
