/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint react/no-unescaped-entities: ["error", {"forbid": ['>', '"', '}'] }] */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import container from '@waiterio/styles/bootstrap/container.js'
import { subtitle } from '@waiterio/www/styles/section.js'
import primaryHeading from '@waiterio/www/styles/primaryHeading.js'

const clientImage = css`
  vertical-align: middle;
  width: '100%';
`

const sectionContainer = css`
  margin: 160px 0px;

  @media (max-width: 1199px) {
    margin: 128px 0px;
  }

  @media (max-width: 992px) {
    margin: 96px 0px;
  }

  @media (max-width: 767px) {
    margin: 64px 0px;
  }

  @media (max-width: 576px) {
    margin: 32px 0px;
  }
`

const sectionBox = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  padding: 60px;

  @media (max-width: 992px) {
    padding: 30px;
  }
`

const ClientsSection = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <section id="clients-section" css={sectionContainer}>
      <div css={container}>
        <div css={sectionBox}>
          <h2 css={primaryHeading}>{t('__pos_system_ourcustomers_title__')}</h2>
          <p css={subtitle}>{t('__pos_system_ourcustomers_subtitle__')}</p>
          <p css={[subtitle, { marginTop: 0, marginBottom: 0 }]}>
            {t('__pos_system_ourcustomers_second_subtitle__')}
          </p>

          <div
            css={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              backgroundColor: 'white',
              marginTop: 60,
              img: {
                width: 165,
                height: 165,
              },
              div: {
                paddingLeft: 16,
                paddingRight: 16,
              },
              '@media(max-width: 992px)': {
                marginTop: 30,
                div: {
                  marginTop: 30,
                },
              },
            }}
          >
            <div>
              <a
                href="https://restaurantebiostalatona.waiterio.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <picture>
                  <source
                    type="image/webp"
                    srcSet="https://www.imagelato.com/images/restaurant-logo-bios-fca36363-320w.webp"
                  />
                  <img
                    css={clientImage}
                    src="https://www.imagelato.com/images/restaurant-logo-bios-fca36363-320w.jpg"
                    alt="Bios restaurant logo"
                  />
                </picture>
              </a>
            </div>
            <div>
              <a
                href="https://www.supadupa.ro/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <picture>
                  <source
                    type="image/webp"
                    srcSet="https://www.imagelato.com/images/restaurant-logo-supadupa-5049b459-320w.webp"
                  />
                  <img
                    css={clientImage}
                    src="https://www.imagelato.com/images/restaurant-logo-supadupa-5049b459-320w.jpg"
                    alt="supadupa restaurant logo"
                  />
                </picture>
              </a>
            </div>
            <div>
              <a
                href="https://kamisushi.waiterio.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <picture>
                  <source
                    type="image/webp"
                    srcSet="https://www.imagelato.com/images/restaurant-logo-kamisushi-d3b3e63a-320w.webp"
                  />
                  <img
                    css={clientImage}
                    src="https://www.imagelato.com/images/restaurant-logo-kamisushi-d3b3e63a-320w.jpg"
                    alt="Bio's restaurant logo"
                  />
                </picture>
              </a>
            </div>
            <div>
              <a
                href="https://larosa.waiterio.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <picture>
                  <source
                    type="image/webp"
                    srcSet="https://www.imagelato.com/images/restaurant-logo-laRosa-9b178ad9-320w.webp"
                  />
                  <img
                    css={clientImage}
                    src="https://www.imagelato.com/images/restaurant-logo-laRosa-9b178ad9-320w.jpg"
                    alt="Kamisushi restaurant logo"
                  />
                </picture>
              </a>
            </div>
            <div>
              <a
                href="https://www.facebook.com/stolowka.rodzinna"
                target="_blank"
                rel="noopener noreferrer"
              >
                <picture>
                  <source
                    type="image/webp"
                    srcSet="https://www.imagelato.com/images/restaurant-logo-rodzinna-83109ff2-320w.webp"
                  />
                  <img
                    css={clientImage}
                    src="https://www.imagelato.com/images/restaurant-logo-rodzinna-83109ff2-320w.jpg"
                    alt="Rodzinna restaurant logo"
                  />
                </picture>
              </a>
            </div>
            <div>
              <a
                href="https://dnoodles.waiterio.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <picture>
                  <source
                    type="image/webp"
                    srcSet="https://www.imagelato.com/images/restaurant-logo-dnoodles-d2671832-320w.webp"
                  />
                  <img
                    css={[clientImage, { marginTop: 16 }]}
                    src="https://www.imagelato.com/images/restaurant-logo-dnoodles-d2671832-320w.jpg"
                    alt="Dnoodles restaurant logo"
                  />
                </picture>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ClientsSection
