/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint react/no-unescaped-entities: ["error", {"forbid": ['>', '"', '}'] }] */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import container from '@waiterio/styles/bootstrap/container.js'
import {
  sectionContainer,
  sectionBox,
  subtitle,
  subHeader,
} from '@waiterio/www/styles/section.js'
import primaryHeading from '@waiterio/www/styles/primaryHeading.js'
import foodDeliveryIcon from '../../images/food-delivery.svg'
import acceptOrRejectOrderIcon from '../../images/accept-or-reject-order.svg'
// import sandglassIcon from '../../images/sandglass.svg'
import OrderTrackingIcon from '../../images/order-tracking.svg'

const subSectionContainer = css({
  marginTop: 120,
  display: 'flex',
  flex: 1,
  justifyContent: 'space-around',
  '@media(max-width: 992px)': {
    marginTop: 60,
    flexWrap: 'wrap',
    justifyContent: 'center',
    img: {
      order: 2,
      maxWidth: 200,
      marginTop: 40,
    },
  },
})

const content = css({
  textAlign: 'left',
  '@media(max-width: 992px)': {
    textAlign: 'center',
  },
})

const OnlineOrderingSection = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <section id="online-ordering-section" css={sectionContainer}>
      <div css={container}>
        <div css={sectionBox}>
          <h2 css={primaryHeading}>
            {t('__website_builder_onlineordering_title__')}
          </h2>
          <p css={subtitle}>
            {t('__website_builder_onlineordering_subtitle__')}
          </p>
          <div css={subSectionContainer}>
            <div css={{ maxWidth: 400, margin: '0px 10px' }}>
              <h3 css={subHeader}>
                {t(
                  '__website_builder_onlineordering_deliveryandtakeaway_title__',
                  locale,
                )}
              </h3>
              <p css={content}>
                {t(
                  '__website_builder_onlineordering_deliveryandtakeaway_content__',
                  locale,
                )}
              </p>
            </div>
            <img
              src={foodDeliveryIcon}
              alt="rider delivering food on bike"
              css={{ maxWidth: 250, height: '100%', margin: '0px 10px' }}
            />
          </div>

          <div css={subSectionContainer}>
            <div css={{ maxWidth: 400, margin: '0px 10px' }}>
              <h3 css={subHeader}>
                {t(
                  '__website_builder_onlineordering_acceptorrejectorders_title__',
                  locale,
                )}
              </h3>
              <p css={content}>
                {t(
                  '__website_builder_onlineordering_acceptorrejectorders_content__',
                  locale,
                )}
              </p>
            </div>
            <img
              src={acceptOrRejectOrderIcon}
              alt="accept or reject incoming meal orders"
              css={{ maxWidth: 250, height: '100%', margin: '0px 10px' }}
            />
          </div>

          <div css={subSectionContainer}>
            <img
              src={OrderTrackingIcon}
              alt="order tracking on phone"
              css={{ maxWidth: 250, height: '100%', margin: '0px 10px' }}
            />
            <div css={{ maxWidth: 400, margin: '0px 10px' }}>
              <h3 css={subHeader}>
                {t(
                  '__website_builder_onlineordering_ordertracking_title__',
                  locale,
                )}
              </h3>
              <p css={content}>
                {t(
                  '__website_builder_onlineordering_ordertracking_content__',
                  locale,
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OnlineOrderingSection
