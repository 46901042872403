/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import colMd6 from '@waiterio/styles/bootstrap/colMd6.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import primaryHeading from '@waiterio/www/styles/primaryHeading.js'
import onlineDeliveryIcon from '../../images/online-delivery.svg'

const sectionContainer = css`
  margin: 120px 0px;

  @media (max-width: 992px) {
    margin: 80px 0px;
  }

  @media (max-width: 768px) {
    margin: 40px 0px;
  }
`

const contentText = css`
  margin: 0;
  max-width: 400px;

  @media (max-width: 992px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 768px) {
    max-width: 400px;
    text-align: center;
  }
`

const imageContainer = css`
  width: 300px;
  height: 300px;
  margin: 0px 80px;

  @media (max-width: 992px) {
    width: 250px;
    height: 250px;
    margin: 0px 40px;
  }

  @media (max-width: 768px) {
    margin: 28px auto;
  }
`

const OverviewSection = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <section css={sectionContainer}>
      <div css={container}>
        <div css={row}>
          <div css={[colSm12, colMd6, { margin: 'auto 0px' }]}>
            <h2 css={primaryHeading}>
              {t('__online_ordering_overview_title__')}
            </h2>
            <p css={contentText}>{t('__online_ordering_overview_content__')}</p>
          </div>
          <div css={[colSm12, colMd6]}>
            <div css={imageContainer}>
              <img
                width="100%"
                height="100%"
                src={onlineDeliveryIcon}
                alt="delivery man"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OverviewSection
