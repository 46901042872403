/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  cs: 'bar-pos-system',
  da: 'bar-pos-system',
  de: 'software-bar-kassensystem',
  el: 'kapileió-pos-system',
  en: 'bar-pos-system',
  es: 'pos-de-barra',
  fr: 'discothèque-logiciel-de-caisse',
  id: 'sistem-bar-pos',
  it: 'pos-software-pub',
  nl: 'bar-kassa-systeem',
  pl: 'bar-pos-system',
  pt: 'software-para-bar',
  ru: 'bar-pos-system',
  th: 'rabb-sxft̒wær̒-bār̒',
  tl: 'bar-pos-sistema',
  tr: 'bar-satici-pos-sistemi',
  zh: 'Jiǔbā-pos-system',
}
