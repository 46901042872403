/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint jsx-a11y/anchor-is-valid: 0 */
/* eslint no-script-url: 0 */
/* eslint react/jsx-no-script-url: 0 */

/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import IconAngleLeft from '@stiloso/icons/IconAngleLeft.js'
import IconAngleRight from '@stiloso/icons/IconAngleRight.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'

const ScreenshotsSection = () => (
  <section
    id="great-screenshots"
    className="overview-block-ptb iq-bg-over iq-over-blue-80 iq-screenshots"
  >
    <div css={container}>
      <div css={row}>
        <div css={colSm12}>
          <div className="heading-title white">
            <h3 className="title iq-tw-7">How Does It Work?</h3>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div css={colSm12}>
          <div className="screenshots-slider popup-gallery">
            <div className="slider-container">
              <div className="slider-content">
                <div className="slider-single">
                  <a href="/images/screenshots/menu.png" className="popup-img">
                    <picture>
                      <source
                        type="image/webp"
                        srcSet="/images/screenshots/menu.png"
                      />
                      <img
                        className="slider-single-image"
                        src="/images/screenshots/menu.png"
                        alt="1"
                      />
                    </picture>
                  </a>
                </div>
                <div className="slider-single">
                  <a href="/images/screenshots/order.png" className="popup-img">
                    <picture>
                      <source
                        type="image/webp"
                        srcSet="/images/screenshots/order.webp"
                      />
                      <img
                        className="slider-single-image"
                        src="/images/screenshots/order.png"
                        alt="2"
                      />
                    </picture>
                  </a>
                </div>
                <div className="slider-single">
                  <a
                    href="/images/screenshots/orders.png"
                    className="popup-img"
                  >
                    <picture>
                      <source
                        type="image/webp"
                        srcSet="/images/screenshots/orders.webp"
                      />
                      <img
                        className="slider-single-image"
                        src="/images/screenshots/orders.png"
                        alt="3"
                      />
                    </picture>
                  </a>
                </div>
                <div className="slider-single">
                  <a href="/images/screenshots/staff.png" className="popup-img">
                    <picture>
                      <source
                        type="image/webp"
                        srcSet="/images/screenshots/staff.webp"
                      />
                      <img
                        className="slider-single-image"
                        src="/images/screenshots/staff.png"
                        alt="4"
                      />
                    </picture>
                  </a>
                </div>
                <div className="slider-single">
                  <a
                    href="/images/screenshots/tables.png"
                    className="popup-img"
                  >
                    <picture>
                      <source
                        type="image/webp"
                        srcSet="/images/screenshots/tables.webp"
                      />
                      <img
                        className="slider-single-image"
                        src="/images/screenshots/tables.png"
                        alt="5"
                      />
                    </picture>
                  </a>
                </div>
                <div className="slider-single">
                  <a
                    href="/images/screenshots/payment.png"
                    className="popup-img"
                  >
                    <picture>
                      <source
                        type="image/webp"
                        srcSet="/images/screenshots/payment.webp"
                      />
                      <img
                        className="slider-single-image"
                        src="/images/screenshots/payment.png"
                        alt="6"
                      />
                    </picture>
                  </a>
                </div>
              </div>
              <a className="slider-left" href="javascript:void(0);">
                <IconAngleLeft
                  css={{
                    width: 40,
                    height: 60,
                    marginRight: -5,
                    marginTop: 0.2,
                    fill: 'white',
                  }}
                />
              </a>
              <a className="slider-right" href="javascript:void(0);">
                <IconAngleRight
                  css={{
                    width: 40,
                    height: 60,
                    marginLeft: -5,
                    marginTop: 0.2,
                    fill: 'white',
                  }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ScreenshotsSection
