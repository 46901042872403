/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, useState, useRef } from 'react'
import { css, jsx } from '@emotion/react'
import { Link } from 'react-router-dom'
import { useTranslation } from '@multilocale/react/index.js'
import IconPapillon from '@stiloso/icons/IconPapillon.js'
import slugsPosSystem from '../slugsPosSystem.js'
import slugsWebsiteBuilder from '../slugsWebsiteBuilder.js'
import slugsOnlineOrdering from '../slugsOnlineOrdering.js'
import slugsFoodTruckPosSystem from '../slugsFoodTruckPosSystem.js'
import slugsBarPosSystem from '../slugsBarPosSystem.js'
import slugsMenuWithQRCode from '../slugsMenuWithQRCode.js'

const navigation = style =>
  css({
    display: 'flex',
    flexWrap: 'nowrap',
    position: 'fixed',
    width: '100%',
    padding: '16px 32px',
    backgroundColor: 'var(--color-primary)',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: 'all .5s ease-in-out',
    zIndex: 1000,
    ...style,
    '@media(max-width:1199px)': {
      padding: '8px 16px',
    },
  })

const papillonSpinner = css({
  borderRadius: 35,
  fill: 'white',
  height: 70,
  width: 70,
  textAlign: 'center',
  marginLeft: -8,
  transition: '1s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
  ':hover': {
    fill: 'white',
    transform: 'rotate(360deg)',
  },
})

const link = css`
  color: white;
  display: inline-block;
  font-size: 16px;
  line-height: 48px;
  height: 48px;
  padding: 0px 16px;
  position: relative;
  :hover {
    color: white;
  }
  @media (max-width: 1199px) {
    padding: 0 8px;
  }
`

const linkUnderlined = css`
  ${link}
  :after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0px;
    height: 2px;
    margin: 0px 16px;
    background-color: white;
    transition: width 0.3s;
  }
  :hover:after {
    width: calc(100% - 30px);
  }
`

const tryItNowButton = css`
  background-color: white;
  border-radius: 4px;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  margin: 8px 0 8px 16px;
  padding: 0 36px;
  &:hover,
  &:focus {
    color: white;
    background: #5cb85c;
    cursor: pointer;
  }
  @media (max-width: 1199px) {
    padding: 0 24px;
    margin-left: 8px;
  }
`

function useHover() {
  const [value, setValue] = useState(false)

  const ref = useRef(null)

  const handleMouseOver = () => setValue(true)
  const handleMouseOut = () => setValue(false)

  useEffect(
    () => {
      const node = ref.current
      if (node) {
        node.addEventListener('mouseover', handleMouseOver)
        node.addEventListener('mouseout', handleMouseOut)

        return () => {
          node.removeEventListener('mouseover', handleMouseOver)
          node.removeEventListener('mouseout', handleMouseOut)
        }
      }
    },
    [ref.current], // eslint-disable-line
  )

  return [ref, value]
}

const ProductsDropdown = ({ locale }) => {
  const { t } = useTranslation(locale)
  const [hoverRef, isHovered] = useHover()

  return (
    <div ref={hoverRef} css={{ display: 'flex', flexDirection: 'column' }}>
      <Link css={link} to={`/${locale}/`}>
        {t('Products')} <span css={{ fontSize: 12 }}>▼</span>
      </Link>
      <div
        css={{
          position: 'absolute',
          background: 'white',
          border: '1px solid #f2f2f2',
          borderRadius: 4,
          display: isHovered ? 'flex' : 'none',
          flexDirection: 'column',
          top: 65,
        }}
      >
        <Link
          css={{
            lineHeight: '48px',
            padding: '0 16px',
            textTransform: 'capitalize',
          }}
          to={`/${locale}/${slugsPosSystem[locale] || slugsPosSystem.en}/`}
        >
          {t('Restaurant POS system')}
        </Link>
        <Link
          css={{
            lineHeight: '48px',
            padding: '0 16px',
            textTransform: 'capitalize',
          }}
          to={`/${locale}/${
            slugsWebsiteBuilder[locale] || slugsWebsiteBuilder.en
          }/`}
        >
          {t('Restaurant website builder')}
        </Link>
        <Link
          css={{
            lineHeight: '48px',
            padding: '0 16px',
            textTransform: 'capitalize',
          }}
          to={`/${locale}/${
            slugsOnlineOrdering[locale] || slugsOnlineOrdering.en
          }/`}
        >
          {t('Restaurant online ordering')}
        </Link>
        <Link
          css={{
            lineHeight: '48px',
            padding: '0 16px',
            textTransform: 'capitalize',
          }}
          to={`/${locale}/${
            slugsMenuWithQRCode[locale] || slugsMenuWithQRCode.en
          }/`}
        >
          {t('Restaurant menu with QR Code')}
        </Link>
      </div>
    </div>
  )
}

const RestaurantTypesDropdown = ({ locale }) => {
  const { t } = useTranslation(locale)
  const [hoverRef, isHovered] = useHover()

  return (
    <div ref={hoverRef} css={{ display: 'flex', flexDirection: 'column' }}>
      <Link css={link} to={`/${locale}/`}>
        {t('Restaurant types')} <span css={{ fontSize: 12 }}>▼</span>
      </Link>
      <div
        css={{
          position: 'absolute',
          background: 'white',
          border: '1px solid #f2f2f2',
          borderRadius: 4,
          display: isHovered ? 'flex' : 'none',
          flexDirection: 'column',
          top: 65,
        }}
      >
        <Link
          css={{
            lineHeight: '48px',
            padding: '0 16px',
            textTransform: 'capitalize',
          }}
          to={`/${locale}/${
            slugsFoodTruckPosSystem[locale] || slugsFoodTruckPosSystem.en
          }/`}
        >
          {t('Food truck')}
        </Link>
        <Link
          css={{
            lineHeight: '48px',
            padding: '0 16px',
            textTransform: 'capitalize',
          }}
          to={`/${locale}/${
            slugsBarPosSystem[locale] || slugsBarPosSystem.en
          }/`}
        >
          {t('Bars and clubs')}
        </Link>
      </div>
    </div>
  )
}

const NavigationDesktopComponent = ({ app = 'pos', locale = 'en', style }) => {
  const { t } = useTranslation(locale)
  let login = 'https://app.waiterio.com/login'
  let signup = 'https://app.waiterio.com/signup'

  if (app === 'builder') {
    login = 'https://builder.waiterio.com/builder/login'
    signup = 'https://app.waiterio.com/signup'
  }

  return (
    <nav css={navigation(style)}>
      <Link css={papillonSpinner} to={`/${locale}/`} aria-label="Waiterio">
        <IconPapillon width="54" height="70" />
      </Link>
      <Link
        css={[linkUnderlined, { fontSize: 20, letterSpacing: '2px' }]}
        to={`/${locale}/`}
        aria-label="Waiterio"
      >
        Waiterio
      </Link>
      <div css={{ flexGrow: 1 }} />
      <ProductsDropdown locale={locale} />
      <RestaurantTypesDropdown locale={locale} />
      <a css={linkUnderlined} href={`/blog/${locale}/`}>
        {t('Blog')}
      </a>
      <a css={linkUnderlined} href={`/docs/${locale}/`}>
        {t('Documentation')}
      </a>
      <Link css={linkUnderlined} to={`/${locale}/pricing/`}>
        {t('Pricing')}
      </Link>
      <Link css={linkUnderlined} to={`/${locale}/downloads/`}>
        {t('Downloads')}
      </Link>
      <a css={linkUnderlined} href={login}>
        {t('Login')}
      </a>
      <a css={tryItNowButton} href={signup}>
        {t('Try it for free')}
      </a>
    </nav>
  )
}

export default NavigationDesktopComponent
