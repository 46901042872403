/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { css, jsx } from '@emotion/react'
import { Link } from 'react-router-dom'
import { useTranslation } from '@multilocale/react/index.js'
import IconPapillon from '@stiloso/icons/IconPapillon.js'
import slugsPosSystem from '../slugsPosSystem.js'
import slugsWebsiteBuilder from '../slugsWebsiteBuilder.js'
import slugsOnlineOrdering from '../slugsOnlineOrdering.js'
import slugsFoodTruckPosSystem from '../slugsFoodTruckPosSystem.js'
import slugsBarPosSystem from '../slugsBarPosSystem.js'
import slugsMenuWithQRCode from '../slugsMenuWithQRCode.js'

const navigation = css({
  label: 'navigation',
  color: 'white',
  backgroundColor: 'var(--color-primary)',
  display: 'flex',
  position: 'fixed',
  justifyContent: 'space-between',
  top: 0,
  zIndex: 1000,
  width: '100%',
  height: 64,
})

const links = expanded =>
  css({
    label: 'links',
    overflow: 'hidden',
    height: expanded ? '100%' : '0px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'var(--color-primary)',
    position: 'fixed',
    top: 64,
    left: 0,
    zIndex: 1000,
    transition: 'height 0.4s ease-in-out',
  })

const link = css({
  label: 'link',
  color: 'white',
  fontSize: 16,
  position: 'relative',
  lineHeight: '18px',
  cursor: 'pointer',
  textTransform: 'capitalize',
  borderBottom: 'solid',
  borderBottomWidth: 1,
  borderBottomColor: '#DCDCDC',
  padding: '15px 15px 12px 0px',
  margin: '0px 20px',
  paddingLeft: 0,
  ':hover': {
    backgroundColor: '#419ed6',
    color: 'white',
  },
})

const toggleMenuContainer = css({
  label: 'drawer-container',
  cursor: 'pointer',
  height: 64,
  padding: '0px 20px',
  position: 'relative',
  width: 64,
})

const drawerIcon = expanded =>
  css({
    label: 'drawer-icon',
    width: 24,
    position: 'absolute',
    top: '40%',

    ':after, :before': {
      content: "''",
      width: 24,
      height: 2,
      position: 'absolute',
      backgroundColor: 'white',
      transition: '.3s ease-in-out',
      top: expanded ? 5 : 0,
      transform: expanded ? 'rotate(45deg)' : 0,
    },

    ':before': {
      top: expanded ? 5 : 10,
      transform: expanded ? 'rotate(-45deg)' : 0,
    },
  })

const NavigationSmartphoneComponent = props => {
  const { app, locale = 'en' } = props
  const { t } = useTranslation(locale)
  let [expanded, setExpanded] = useState(false)

  const toggleDrawer = () => {
    setExpanded(expanded => !expanded)
  }

  let login = 'https://app.waiterio.com/login'
  let signup = 'https://app.waiterio.com/signup'

  if (app === 'builder') {
    login = 'https://builder.waiterio.com/builder/login'
    signup = 'https://app.waiterio.com/signup'
  }

  return (
    <nav css={navigation}>
      <div css={toggleMenuContainer} onClick={toggleDrawer}>
        <div css={drawerIcon(expanded)} data-testid="icon-drawer" />
      </div>

      <div css={links(expanded)}>
        <Link
          css={link}
          to={`/${locale}/${slugsPosSystem[locale] || slugsPosSystem.en}/`}
        >
          {t('Restaurant POS system')}
        </Link>
        <Link
          css={link}
          to={`/${locale}/${
            slugsWebsiteBuilder[locale] || slugsWebsiteBuilder.en
          }/`}
        >
          {t('Restaurant website builder')}
        </Link>
        <Link
          css={link}
          to={`/${locale}/${
            slugsOnlineOrdering[locale] || slugsOnlineOrdering.en
          }/`}
        >
          {t('Restaurant online ordering')}
        </Link>
        <Link
          css={link}
          to={`/${locale}/${
            slugsMenuWithQRCode[locale] || slugsMenuWithQRCode.en
          }/`}
        >
          {t('Restaurant menu with QR Code')}
        </Link>
        <Link
          css={link}
          to={`/${locale}/${
            slugsFoodTruckPosSystem[locale] || slugsFoodTruckPosSystem.en
          }/`}
        >
          {t('Food truck')}
        </Link>
        <Link
          css={link}
          to={`/${locale}/${
            slugsBarPosSystem[locale] || slugsBarPosSystem.en
          }/`}
        >
          {t('Bars and clubs')}
        </Link>
        <a css={link} href={`/blog/${locale}/`}>
          {t('Blog')}
        </a>
        <a css={link} href={`/docs/${locale}/`}>
          {t('Documentation')}
        </a>
        <Link css={link} to={`/${locale}/pricing/`}>
          {t('Pricing')}
        </Link>
        <Link css={link} to={`/${locale}/downloads/`}>
          {t('Downloads')}
        </Link>
        <a css={link} href={login}>
          {t('Login')}
        </a>
        <a css={link} href={signup}>
          {t('Try it for free')}
        </a>
      </div>

      <Link to={`/${locale}/`} css={{ display: 'flex', flexDirection: 'row' }}>
        <IconPapillon
          width="44"
          height="44"
          css={{ fill: 'white', margin: 10, width: 44, height: 44 }}
        />
        <div
          css={[
            {
              color: 'white',
              fontSize: 20,
              letterSpacing: '2px',
              lineHeight: '64px',
              marginLeft: 8,
            },
          ]}
          to={`/${locale}/`}
          aria-label="Waiterio"
        >
          Waiterio
        </div>
      </Link>

      <div css={{ width: 60 }} />
    </nav>
  )
}

export default NavigationSmartphoneComponent
