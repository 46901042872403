/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'
import getPhrases from '@multilocale/client/getPhrases.js'

let defaultLanguage
let organizationId
let project
let dictionaries = {}
let loading
let error

export const init = params => {
  let cache = {}
  if (typeof window !== 'undefined' && window.__MULTILOCALE_CACHE__) {
    cache = window.__MULTILOCALE_CACHE__
  }

  defaultLanguage = params.defaultLanguage
  organizationId = params.organizationId
  project = params.project
  dictionaries = params.dictionaries || cache?.dictionaries || {}
}

export const setDictionaries = dictionaries_ => {
  dictionaries = dictionaries_
}

export const setDefaultLanguage = defaultLanguage_ => {
  defaultLanguage = defaultLanguage_
}

export const initMultilocale = init

let suspender

export const useTranslation = forcedLanguage => {
  let language =
    forcedLanguage || defaultLanguage || getBrowserLanguage() || 'en'

  if (
    organizationId &&
    project &&
    !suspender &&
    typeof dictionaries[language] === 'undefined'
  ) {
    loading = true
    suspender = getPhrases(
      {
        organizationId,
        language,
        project,
        fields: ['key', 'value', 'language'],
      },
      {
        retries: 1,
      },
    )
      .then(phrases => {
        if (!dictionaries[language]) {
          dictionaries[language] = {}
        }

        phrases.forEach(phrase => {
          if (!dictionaries[phrase.language]) {
            dictionaries[phrase.language] = {}
          }
          dictionaries[phrase.language][phrase.key] = phrase.value
        })
        suspender = null
        loading = false
      })
      .catch(error_ => {
        error = error_
        console.error(error)
        suspender = null
        loading = false
        // THE SHOW MUST GO ON
        dictionaries[language] = {}
        return null
      })
  }

  if (loading) {
    throw suspender
    // THE SHOW MUST GO ON
    // } else if (error) {
    // throw error
  } else {
    const translate = (key, fallback) => {
      let value = ''

      if (dictionaries?.[language]?.[key]) {
        value = dictionaries[language][key]
      } else if (fallback?.length >= 0) {
        value = fallback
      } else if (!(key?.startsWith('__') && key?.endsWith('__'))) {
        value = key
      }

      return value
    }

    const Cache = () => {
      const windowVariables = `window.__MULTILOCALE_CACHE__ = ${JSON.stringify({
        dictionaries: {
          [language]: dictionaries[language],
        },
      })};`
      return <script dangerouslySetInnerHTML={{ __html: windowVariables }} />
    }

    return {
      language,
      t: translate,
      translate,
      Cache,
      MultilocaleCache: Cache,
    }
  }
}

export default useTranslation
