/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint react/no-unescaped-entities: ["error", {"forbid": ['>', '"', '}'] }] */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import container from '@waiterio/styles/bootstrap/container.js'
import { subtitle } from '@waiterio/www/styles/section.js'
import primaryHeading from '@waiterio/www/styles/primaryHeading.js'
import creditCardProcessingIcon from '../../images/credit-card-processing.svg'
import cashPaymentIcon from '../../images/cash-payment.svg'
import voucherIcon from '../../images/voucher.svg'

const sectionContainer = css`
  margin: 160px 0px;

  @media (max-width: 1199px) {
    margin: 128px 0px;
  }

  @media (max-width: 992px) {
    margin: 96px 0px;
  }

  @media (max-width: 767px) {
    margin: 64px 0px;
  }

  @media (max-width: 576px) {
    margin: 32px 0px;
  }
`

const sectionBox = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  padding: 60px;

  @media (max-width: 992px) {
    padding: 30px;
  }
`

const AcceptAllPaymentMethodsSection = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <section id="pos-system-accept-all-payment-methods" css={sectionContainer}>
      <div css={container}>
        <div css={sectionBox}>
          <h2 css={primaryHeading}>
            {t('__pos_system_acceptallpaymentmethods_title__')}
          </h2>
          <p css={subtitle}>
            {t('__pos_system_acceptallpaymentmethods_subtitle__')}
          </p>

          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 60,
              '@media(max-width: 992px)': {
                flexDirection: 'column',
                alignItems: 'center',
              },
            }}
          >
            <div css={{ maxWidth: 300, margin: 10, textAlign: 'center' }}>
              <img
                src={creditCardProcessingIcon}
                alt="payment method credit cards"
                css={{ width: 120, height: 120, marginBottom: 30 }}
              />
              <h3 css={{ textTransform: 'capitalize' }}>
                {t(
                  '__pos_system_acceptallpaymentmethods_creditcardprocessing_title__',
                  locale,
                )}
              </h3>
              <p>
                {t(
                  '__pos_system_acceptallpaymentmethods_creditcardprocessing_content__',
                  locale,
                )}
              </p>
            </div>

            <div css={{ maxWidth: 300, margin: 10, textAlign: 'center' }}>
              <img
                src={voucherIcon}
                alt="payment method vouchers"
                css={{ width: 120, height: 120, marginBottom: 30 }}
              />
              <h3 css={{ textTransform: 'capitalize' }}>
                {t(
                  '__pos_system_acceptallpaymentmethods_vouchers_title__',
                  locale,
                )}
              </h3>
              <p>
                {t(
                  '__pos_system_acceptallpaymentmethods_vouchers_content__',
                  locale,
                )}
              </p>
            </div>

            <div css={{ maxWidth: 300, margin: 10, textAlign: 'center' }}>
              <img
                src={cashPaymentIcon}
                alt="cash payment method"
                css={{ width: 120, height: 120, marginBottom: 30 }}
              />
              <h3 css={{ textTransform: 'capitalize' }}>
                {t('__pos_system_acceptallpaymentmethods_cash_title__')}
              </h3>
              <p>
                {t(
                  '__pos_system_acceptallpaymentmethods_cash_content__',
                  locale,
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AcceptAllPaymentMethodsSection
