/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import faviconUrl from '@waiterio/shared/faviconUrl.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import colMd6 from '@waiterio/styles/bootstrap/colMd6.js'
import colLg3 from '@waiterio/styles/bootstrap/colLg3.js'
import colLg6 from '@waiterio/styles/bootstrap/colLg6.js'
import colSm12 from '@waiterio/styles/bootstrap/colSm12.js'
import { stepNumber, stepName } from '@waiterio/www/styles/section.js'
import CommissionsSection from '@waiterio/www/pages/ReferralPage/CommissionsSection.js'
import Layout from '../../components/LayoutComponent.js'
import languages from '../../languages.js'
import BannerSection from '../BannerSectionWithCoverImage.js'
import growBusinessIcon from '../../images/grow-business.svg'
import earnMoreMoneyIcon from '../../images/earn-more-money.svg'
import improveCustomerValueIcon from '../../images/improve-customer-value.svg'

const referralMeetingUrlJpg =
  'https://www.imagelato.com/images/referral-meeting-17be2aed.jpg'
const referralMeetingUrl768Jpg = referralMeetingUrlJpg.replace(
  '.jpg',
  '-768w.jpg',
)
const referralMeetingUrl640Jpg = referralMeetingUrlJpg.replace(
  '.jpg',
  '-640w.jpg',
)
const referralMeetingUrl768Webp = referralMeetingUrlJpg.replace(
  '.jpg',
  '-768w.webp',
)
const referralMeetingUrl640Webp = referralMeetingUrlJpg.replace(
  '.jpg',
  '-640w.webp',
)

const sectionContainer = css`
  margin: 100px 0px;

  @media (max-width: 992px) {
    margin: 80px 0px;
  }

  @media (max-width: 768px) {
    margin: 60px 0px;
  }
`

const textHeading = css`
  color: var(--color-primary);
  margin-bottom: 14px;
  margin-top: 28px;
  line-height: 46px;
  font-size: 38px;
  text-transform: capitalize;
  text-align: center;

  @media (max-width: 992px) {
    font-size: 32px;
    line-height: 38px;
  }

  @media (max-width: 768px) {
    margin-top: 28px;
    font-size: 26px;
    line-height: 32px;
  }
`

const ctaButton = css`
  background-color: var(--color-primary);
  border-radius: 4px;
  color: white;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  padding: 12px 36px;
  text-align: center;
  &:hover,
  &:focus {
    color: white;
    background: var(--color-green);
  }
`

export const Head = ({ locale }) => {
  const { t } = useTranslation(locale)

  const title = `Waiterio | ${t('Referral program')}`
  const description = `${t('Join waiterio referral program now')}`
  const url = `https://www.waiterio.com/${locale}/referral/`

  return (
    <>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={faviconUrl} />
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={faviconUrl} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={faviconUrl} />
    </>
  )
}

export const paths = languages.map(locale => ({
  path: `/${locale}/referral/`,
  props: {
    locale,
  },
}))

const ReferralPage = ({ locale }) => {
  const { t } = useTranslation(locale)
  let [didScroll, setDidScroll] = useState(false)

  useEffect(() => {
    function onScroll() {
      if (window.pageYOffset > 100 && !didScroll) {
        setDidScroll(true)
      } else if (window.pageYOffset < 100 && didScroll) {
        setDidScroll(false)
      }
    }

    window.addEventListener('scroll', onScroll)

    return function cleanup() {
      window.removeEventListener('scroll', onScroll)
    }
  }, [didScroll])

  let headerStyle = {
    boxShadow: '0 0 30px 0 rgba(0,0,0,.1)',
  }

  if (!didScroll) {
    headerStyle = {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    }
  }

  return (
    <Layout headerStyle={headerStyle} locale={locale}>
      <BannerSection
        title={t('__referral_banner_title__')}
        subtitle={t('__referral_banner_subtitle__')}
        heroImageUrl="https://www.imagelato.com/images/referral-hero-a1cf2236.jpg"
        imageAlt="man-doing-marketing-on-laptop"
        buttonText={t('Sign up')}
        buttonLink="mailto://info@waiterio.com"
      />

      <section css={sectionContainer} id="referral-overview">
        <div css={container}>
          <div css={row}>
            <div css={colSm12}>
              <h2 css={textHeading}>{t('__referral_overview_title__')}</h2>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  '@media(max-width: 992px)': {
                    flexDirection: 'column',
                    alignItems: 'center',
                  },
                }}
              >
                <div
                  css={{
                    maxWidth: 300,
                    margin: '60px 20px',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    css={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: 16,
                    }}
                  >
                    <img
                      src={growBusinessIcon}
                      alt="growing business chart"
                      css={{ width: 100, height: 100 }}
                    />
                  </div>
                  <h3 css={{ textTransform: 'capitalize' }}>
                    {t(
                      '__referral_overview_increasebusinessoffering_title__',
                      locale,
                    )}
                  </h3>
                  <p>
                    {t(
                      '__referral_overview_increasebusinessoffering_content__',
                      locale,
                    )}
                  </p>
                  <a href="mailto://info@waiterio.com" css={ctaButton}>
                    {t('Sign up')}
                  </a>
                </div>

                <div
                  css={{
                    maxWidth: 300,
                    margin: '60px 20px',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    css={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: 16,
                    }}
                  >
                    <img
                      src={earnMoreMoneyIcon}
                      alt="earning more money"
                      css={{ width: 100, height: 100 }}
                    />
                  </div>
                  <h3 css={{ textTransform: 'capitalize' }}>
                    {t('__referral_overview_earnmoremoney_title__')}
                  </h3>
                  <p>{t('__referral_overview_earnmoremoney_content__')}</p>
                  <a href="mailto://info@waiterio.com" css={ctaButton}>
                    {t('Sign up')}
                  </a>
                </div>

                <div
                  css={{
                    maxWidth: 300,
                    margin: '60px 20px',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    css={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: 16,
                    }}
                  >
                    <img
                      src={improveCustomerValueIcon}
                      alt="improving customer value"
                      css={{ width: 100, height: 100 }}
                    />
                  </div>
                  <h3 css={{ textTransform: 'capitalize' }}>
                    {t(
                      '__referral_overview_increasecustomervalue_title__',
                      locale,
                    )}
                  </h3>
                  <p>
                    {t(
                      '__referral_overview_increasecustomervalue_content__',
                      locale,
                    )}
                  </p>
                  <a href="mailto://info@waiterio.com" css={ctaButton}>
                    {t('Sign up')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CommissionsSection locale={locale} />

      <section id="referral-how-it-works" css={sectionContainer}>
        <div css={container}>
          <div css={row}>
            <div css={colSm12}>
              <h2 css={textHeading}>{t('__referral_howitworks_title__')}</h2>
              <p
                css={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  maxWidth: 400,
                  textAlign: 'center',
                }}
              >
                {t('__referral_howitworks_content__')}
              </p>
            </div>
          </div>
          <div css={row}>
            <div css={[colSm12, colMd6, colLg3]}>
              <div css={{ textAlign: 'center' }}>
                <div css={stepNumber}>1</div>
                <div css={stepName}>
                  {t('__referral_howitworks_step_1_title__')}
                </div>
                <p>{t('__referral_howitworks_step_1_content__')}</p>
              </div>
            </div>
            <div css={[colSm12, colMd6, colLg3]}>
              <div css={{ textAlign: 'center' }}>
                <div css={stepNumber}>2</div>
                <div css={stepName}>
                  {t('__referral_howitworks_step_2_title__')}
                </div>
                <p>{t('__referral_howitworks_step_2_content__')}</p>
              </div>
            </div>
            <div css={[colSm12, colMd6, colLg3]}>
              <div css={{ textAlign: 'center' }}>
                <div css={stepNumber}>3</div>
                <div css={stepName}>
                  {t('__referral_howitworks_step_3_title__')}
                </div>
                <p>{t('__referral_howitworks_step_3_content__')}</p>
              </div>
            </div>
            <div css={[colSm12, colMd6, colLg3]}>
              <div css={{ textAlign: 'center' }}>
                <div css={stepNumber}>4</div>
                <div css={stepName}>
                  {t('__referral_howitworks_step_4_title__')}
                </div>
                <p>{t('__referral_howitworks_step_4_content__')}</p>
              </div>
            </div>
          </div>
          <div css={row}>
            <div
              css={{
                display: 'flex',
                justifyContent: 'center',
                flex: 1,
                marginTop: 30,
              }}
            >
              <a href="mailto://info@waiterio.com" css={ctaButton}>
                {t('Sign up')}
              </a>
            </div>
          </div>
        </div>
      </section>

      <section id="referral-how-to-refer-waiterio" css={sectionContainer}>
        <div css={container}>
          <div css={row}>
            <div css={[colSm12, colLg6]}>
              <picture>
                <source
                  type="image/webp"
                  media="(min-width: 768px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 384px)"
                  srcSet={referralMeetingUrl768Webp}
                />
                <source type="image/webp" srcSet={referralMeetingUrl640Webp} />

                <source
                  type="image/jpg"
                  media="(min-width: 768px), (-webkit-min-device-pixel-ratio: 2) and (min-width: 384px)"
                  srcSet={referralMeetingUrl768Jpg}
                />
                <source type="image/jpg" srcSet={referralMeetingUrl640Jpg} />

                <img
                  css={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    maxHeight: 680,

                    '@media(max-width: 992px)': {
                      maxHeight: 500,
                    },
                    '@media(max-width: 768px)': {
                      maxHeight: 400,
                    },
                    '@media(max-width: 479px)': {
                      maxHeight: 350,
                    },
                  }}
                  src={referralMeetingUrl768Jpg}
                  alt="affiliate-referring-waiterio"
                />
              </picture>
            </div>
            <div css={[colSm12, colLg6]}>
              <div css={{ '@media(min-width: 993px)': { marginLeft: 20 } }}>
                <h2
                  css={[
                    textHeading,
                    { '@media(min-width: 992px)': { textAlign: 'left' } },
                  ]}
                >
                  {t('__referral_howtoreferwaiterio_title__')}
                </h2>
                <div
                  css={{
                    marginTop: 28,
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: '100%',
                    '@media(max-width: 992px)': {
                      flexDirection: 'column',
                      marginTop: 28,
                      div: {
                        marginRight: 0,
                        width: '100%',
                      },
                    },
                  }}
                >
                  <div
                    css={{
                      width: 250,
                      display: 'flex',
                      flexDirection: 'column',
                      marginRight: 14,
                      paddingBottom: 14,
                    }}
                  >
                    <h4 css={{ marginBottom: 5 }}>
                      {t(
                        '__referral_howtoreferwaiterio_talkaboutus_title__',
                        locale,
                      )}
                    </h4>
                    <p css={{ margin: 0 }}>
                      {t(
                        '__referral_howtoreferwaiterio_talkaboutus_content__',
                        locale,
                      )}
                    </p>
                  </div>

                  <div
                    css={{
                      width: 250,
                      display: 'flex',
                      flexDirection: 'column',
                      marginRight: 14,
                      paddingBottom: 14,
                    }}
                  >
                    <h4 css={{ marginBottom: 5 }}>
                      {t(
                        '__referral_howtoreferwaiterio_socialmedia_title__',
                        locale,
                      )}
                    </h4>
                    <p css={{ margin: 0 }}>
                      {t(
                        '__referral_howtoreferwaiterio_socialmedia_content__',
                        locale,
                      )}
                    </p>
                  </div>

                  <div
                    css={{
                      width: 250,
                      display: 'flex',
                      flexDirection: 'column',
                      marginRight: 14,
                      paddingBottom: 14,
                    }}
                  >
                    <h4 css={{ marginBottom: 5 }}>
                      {t(
                        '__referral_howtoreferwaiterio_marketingcampaigns_title__',
                        locale,
                      )}
                    </h4>
                    <p css={{ margin: 0 }}>
                      {t(
                        '__referral_howtoreferwaiterio_marketingcampaigns_content__',
                        locale,
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div css={[row, { marginTop: 30 }]}>
            <div css={colSm12}>
              <div css={{ display: 'flex', justifyContent: 'center' }}>
                <a
                  href="mailto://info@waiterio.com"
                  css={ctaButton}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('Sign up')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="referral-closing-section">
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 120,
            backgroundColor: 'var(--color-primary)',
            '@media(max-width: 992px)': {
              padding: 60,
              h2: {
                lineHeight: '38px',
                textAlign: 'center',
                fontSize: 32,
              },
            },
          }}
        >
          <h2
            css={{
              fontSize: 36,
              color: 'white',
              textTransform: 'capitalize',
            }}
          >
            {t('__referral_closing_title__')}
          </h2>
          <p
            css={{
              textAlign: 'center',
              color: 'white',
              margin: '36px 0px',
            }}
          >
            {t('__referral_closing_content__')}
          </p>
          <a
            href="mailto://info@waiterio.com"
            target="_blank"
            rel="noreferrer"
            css={{
              backgroundColor: 'white',
              borderRadius: 4,
              color: 'black',
              display: 'inline-block',
              fontSize: 16,
              fontWeight: '500',
              padding: '12px 36px',
              textAlign: 'center',
              ':hover, :focus': {
                color: 'white',
                background: 'var(--color-green)',
              },
            }}
          >
            {t('Sign up')}
          </a>
        </div>
      </section>
    </Layout>
  )
}

export default ReferralPage
