/* Copyright 2013 - 2024 Waiterio LLC */
export default {
  en: 'restaurant-website-builder',
  it: 'crea-sito-web-ristorante',
  es: 'crear-sitio-web-restaurante',
  fr: 'créer-site-Web-restaurant',
  cs: 'vytvořit-web-restaurace',
  da: 'Opret-websted-restaurant',
  de: 'restaurant-website-erstellen',
  el: 'dimiourgía-istótopou-estiatoríou',
  id: 'buat-situs-web-restoran',
  nl: 'website-restaurant-maken',
  pl: 'stworzyć-stronę-internetową-restauracji',
  pt: 'criar-site-restaurante',
  ru: 'sozdat-sayt-restorana',
  th: 'S̄r̂āng-wĕbsịt̒-r̂ān-xāh̄ār',
  tl: 'lumikha-website-restawran',
  tr: 'restoran-web-sitesi-oluştur',
  zh: 'chuàngjiàn-cāntīng-wǎngzhàn',
}
