/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import colLg6 from '@waiterio/styles/bootstrap/colLg6.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'

import IconDone from '@stiloso/icons/IconDone.js'

const section = css`
  margin: 80px 0px;
  background: #3f92d2;
  padding: 40px 0px;

  @media (max-width: 992px) {
    margin: 64px 0px;
  }

  @media (max-width: 768px) {
    margin: 48px 0px;
  }
`

const RestaurantManagementOverviewSection = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <section css={section}>
      <div css={container}>
        <div css={row}>
          <div css={[colLg6, { margin: 'auto 0px' }]}>
            <h2
              css={{
                marginBottom: 28,
                fontSize: 36,
                fontWeight: 'bold',
                lineHeight: '46px',
                color: 'white',
                '@media(max-width: 992px)': {
                  textAlign: 'center',
                },
              }}
            >
              {t(
                '__menu_with_qrcode_restaurantmanagementoverview_title__',
                locale,
              )}
            </h2>

            <p css={{ marginBottom: 28, color: 'white' }}>
              {t(
                '__menu_with_qrcode_restaurantmanagementoverview_subtitle__',
                locale,
              )}{' '}
              :
            </p>

            <div css={{ marginBottom: 28 }}>
              <div css={{ display: 'flex' }}>
                <IconDone css={{ fill: '#F1D46B', width: 28, height: 28 }} />
                <span
                  css={{ color: 'white', marginLeft: 8, fontWeight: '700' }}
                >
                  {t(
                    '__menu_with_qrcode_restaurantmanagementoverview_feature_1_name__',
                    locale,
                  )}
                </span>
              </div>

              <div css={{ display: 'flex' }}>
                <IconDone css={{ fill: '#F1D46B', width: 28, height: 28 }} />
                <span
                  css={{ color: 'white', marginLeft: 8, fontWeight: '700' }}
                >
                  {t(
                    '__menu_with_qrcode_restaurantmanagementoverview_feature_2_name__',
                    locale,
                  )}
                </span>
              </div>

              <div css={{ display: 'flex' }}>
                <IconDone css={{ fill: '#F1D46B', width: 28, height: 28 }} />
                <span
                  css={{ color: 'white', marginLeft: 8, fontWeight: '700' }}
                >
                  {t(
                    '__menu_with_qrcode_restaurantmanagementoverview_feature_3_name__',
                    locale,
                  )}
                </span>
              </div>
            </div>

            <p css={{ color: 'white' }}>
              {t(
                '__menu_with_qrcode_restaurantmanagementoverview_second_subtitle__',
                locale,
              )}
            </p>
          </div>

          <div css={colLg6}>
            <img
              css={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                '@media(max-width: 992px)': {
                  height: 'auto',
                },
              }}
              src="https://www.imagelato.com/images/menu-with-qrcode-restaurant-management-overview-4a8618b2.png"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default RestaurantManagementOverviewSection
