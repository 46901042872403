/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint react/no-unescaped-entities: ["error", {"forbid": ['>', '"', '}'] }] */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import container from '@waiterio/styles/bootstrap/container.js'
import {
  sectionContainer,
  sectionBox,
  subtitle,
} from '@waiterio/www/styles/section.js'
import primaryHeading from '@waiterio/www/styles/primaryHeading.js'
import orderManagementIcon from '../../images/order-management.svg'
import menuSyncIcon from '../../images/sync-menu.svg'
import salesReportIcon from '../../images/sales-report.svg'
import slugsPosSystem from '../../slugsPosSystem.js'

const learnMoreButton = css`
  background-color: var(--color-primary);
  border-radius: 4px;
  color: white;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  margin: auto;
  padding: 12px 36px;
  text-align: center;
  &:hover,
  &:focus {
    color: white;
    background: var(--color-green);
  }
`

const POSIntegrationSection = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <section
      id="restaurant-website-builder-pos-integration-section"
      css={sectionContainer}
    >
      <div css={container}>
        <div css={sectionBox}>
          <h2 css={primaryHeading}>
            {t('__website_builder_posintegration_title__')}
          </h2>
          <p css={subtitle}>
            {t('__website_builder_posintegration_subtitle__')}
          </p>

          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              '@media(max-width: 992px)': {
                flexDirection: 'column',
                alignItems: 'center',
              },
            }}
          >
            <div
              css={{
                maxWidth: 300,
                margin: '60px 20px',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: 16,
                }}
              >
                <img
                  src={orderManagementIcon}
                  alt="manage orders in computer"
                  css={{ width: 120, height: 120 }}
                />
              </div>
              <h3 css={{ textTransform: 'capitalize' }}>
                {t(
                  '__website_builder_posintegration_smoothordermanagement_title__',
                  locale,
                )}
              </h3>
              <p>
                {t(
                  '__website_builder_posintegration_smoothordermanagement_content__',
                  locale,
                )}
              </p>
              <a
                href={`/${locale}/${
                  slugsPosSystem[locale] || slugsPosSystem.en
                }`}
                css={learnMoreButton}
              >
                {t('Learn more')}
              </a>
            </div>

            <div
              css={{
                maxWidth: 300,
                margin: '60px 20px',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: 16,
                }}
              >
                <img
                  src={menuSyncIcon}
                  alt="synchronizing menu in computer"
                  css={{ width: 120, height: 120 }}
                />
              </div>
              <h3 css={{ textTransform: 'capitalize' }}>
                {t(
                  '__website_builder_posintegration_syncyourmenuinstantly_title__',
                  locale,
                )}
              </h3>
              <p>
                {t(
                  '__website_builder_posintegration_syncyourmenuinstantly_content__',
                  locale,
                )}
              </p>
              <a
                href={`/${locale}/${
                  slugsPosSystem[locale] || slugsPosSystem.en
                }`}
                css={learnMoreButton}
              >
                {t('Learn more')}
              </a>
            </div>

            <div
              css={{
                maxWidth: 300,
                margin: '60px 20px',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: 16,
                }}
              >
                <img
                  src={salesReportIcon}
                  alt="financial sales report"
                  css={{ width: 120, height: 120 }}
                />
              </div>
              <h3 css={{ textTransform: 'capitalize' }}>
                {t(
                  '__website_builder_posintegration_tracksalesandprofit_title__',
                  locale,
                )}
              </h3>
              <p>
                {t(
                  '__website_builder_posintegration_tracksalesandprofit_content__',
                  locale,
                )}
              </p>
              <a
                href={`/${locale}/${
                  slugsPosSystem[locale] || slugsPosSystem.en
                }`}
                css={learnMoreButton}
              >
                {t('Learn more')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default POSIntegrationSection
