/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint react/no-unescaped-entities: ["error", {"forbid": ['>', '"', '}'] }] */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import IconDoneAll from '@stiloso/icons/IconDoneAll.js'
import colLg6 from '@waiterio/styles/bootstrap/colLg6.js'
import container from '@waiterio/styles/bootstrap/container.js'
import row from '@waiterio/styles/bootstrap/row.js'
import horizontalDotsPng from '../../images/horizontal-dots.png'
import horizontalDotsWebp from '../../images/horizontal-dots.webp'
import jumbotron720Png from '../../images/jumbotron-720w.png'
import jumbotron720Webp from '../../images/jumbotron-720w.webp'
import verticalDotsPng from '../../images/vertical-dots.png'
import verticalDotsWebp from '../../images/vertical-dots.webp'

const featuresSection = css`
  margin-top: 50px;
  padding: 100px 0;
  position: relative;

  @media (max-width: 1399px) {
    margin-top: 0;
  }

  @media (max-width: 1199px) {
    padding: 80px 0 0;
  }

  @media (max-width: 767px) {
    padding: 50px 0 0;
  }
`

const featuresImageWrapper = css`
  height: 100%;
  width: 50%;
  left: 0;
  position: absolute;
  top: 50px;
  @media (max-width: 1399px) {
    top: 80px;
  }
  @media (max-width: 1199px) {
    top: 23%;
    height: 75%;
  }
  @media (max-width: 992px) {
    width: 100%;
    top: 0;
    position: inherit;
    text-align: center;
  }
`

const featuresImage = css`
  display: inline;
  max-width: 100%;
  vertical-align: middle;
`

const featuresDecorations = css`
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  z-index: -1;

  @media (max-width: 992px) {
    display: none;
  }
`

const featureDecorationsOne = css`
  position: absolute;
  top: 0;
  left: 0;
`

const featureDecorationsTwo = css`
  bottom: 5%;
  height: 75px;
  left: 15%;
  position: absolute;
  width: 210px;
`

const featureDecorationsThree = css`
  @keyframes fadebounce {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    50% {
      transform: translateY(20px);
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: fadebounce;

  position: absolute;
  top: 0%;
  left: 10%;
  border: 40px solid var(--color-primary);
  border-radius: 900px;
  height: 600px;
  width: 600px;
`

const FeaturesSection = props => (
  <section id="software-features" css={featuresSection}>
    <div css={featuresImageWrapper}>
      <picture>
        <source type="image/webp" srcSet={jumbotron720Webp} />
        <img
          css={featuresImage}
          src={jumbotron720Png}
          alt="Why Choose Waiterio Restaurant POS?"
        />
      </picture>
    </div>
    <div css={container}>
      <div css={[row, { justifyContent: 'flex-end' }]}>
        <div css={colLg6}>
          <h4
            css={{
              fontSize: 24,
              fontWeight: 600,
              lineHeight: '24px',
              marginBottom: 25,
            }}
          >
            Why Choose Waiterio Restaurant POS?
          </h4>
          <span css={{ color: '#333333', fontWeight: 600 }}>
            .. Because of its awesome features!
          </span>
          <ul
            css={{
              color: '#333333',
              fontWeight: 600,
              margin: '40px 0 0 0',
              padding: 0,
            }}
          >
            <li css={{ display: 'flex', marginBottom: 15 }}>
              <IconDoneAll
                css={{
                  width: 40,
                  height: 30,
                  marginRight: 2,
                  fill: 'var(--color-primary)',
                  marginLeft: -9.5,
                  '@media (max-width: 479px)': {
                    width: 60,
                  },
                }}
              />
              <span>
                Easy menu customization. You can add different items, categories
                and photos as needed.
              </span>
            </li>
            <li css={{ display: 'flex', marginBottom: 15 }}>
              <IconDoneAll
                css={{
                  width: 40,
                  height: 30,
                  marginRight: 2.5,
                  fill: 'var(--color-primary)',
                  marginLeft: -9.5,
                  '@media (max-width: 479px)': {
                    width: 60,
                  },
                }}
              />
              <span>
                Table management. You can see the map of your restaurant tables
                and assign orders directly.
              </span>
            </li>
            <li css={{ display: 'flex', marginBottom: 15 }}>
              <IconDoneAll
                css={{
                  width: 50,
                  height: 30,
                  marginRight: 4,
                  fill: 'var(--color-primary)',
                  marginLeft: -7,
                  '@media (max-width: 479px)': {
                    width: 80,
                    marginLeft: -8,
                    marginRight: 3.5,
                  },
                }}
              />
              <span css={{ marginLeft: -1 }}>
                Quick kitchen communication. Take an order from the customer and
                it will be send to all other co-workers (waiters and cooks).
              </span>
            </li>
            <li css={{ display: 'flex', marginBottom: 15 }}>
              <IconDoneAll
                css={{
                  width: 50,
                  height: 30,
                  marginRight: 1,
                  fill: 'var(--color-primary)',
                  marginLeft: -8.5,
                  '@media (max-width: 479px)': {
                    width: 80,
                    marginLeft: -10,
                    marginRight: 0,
                  },
                }}
              />
              <span css={{ marginLeft: 0.5 }}>
                Receive payments online. Your customers don't have to worry
                about keeping cash in their pockets all the time.
              </span>
            </li>
            <li css={{ display: 'flex', marginBottom: 15 }}>
              <IconDoneAll
                css={{
                  width: 40,
                  height: 30,
                  marginRight: 3,
                  fill: 'var(--color-primary)',
                  marginLeft: -7.5,
                  '@media (max-width: 479px)': {
                    width: 60,
                  },
                }}
              />
              <span>
                Organize the orders. Take full control of your orders by
                deciding which item will be served first.
              </span>
            </li>
            <li css={{ display: 'flex', marginBottom: 15 }}>
              <IconDoneAll
                css={{
                  width: 40,
                  height: 30,
                  marginRight: 3,
                  fill: 'var(--color-primary)',
                  marginLeft: -7,
                  '@media (max-width: 479px)': {
                    width: 60,
                  },
                }}
              />
              <span>
                Bill splitting. Waiterio is a one-of-its-kind POS for
                restaurants that allows customers to split bills.
              </span>
            </li>
            <li css={{ display: 'flex', marginBottom: 15 }}>
              <IconDoneAll
                css={{
                  width: 40,
                  height: 30,
                  marginRight: 3,
                  fill: 'var(--color-primary)',
                  marginLeft: -7.5,
                  '@media (max-width: 479px)': {
                    width: 60,
                  },
                }}
              />
              <span>
                Reporting. Focus on your best selling items and generate weekly
                or monthly reports as needed.
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div css={featuresDecorations}>
      <span css={featureDecorationsOne}>
        <picture>
          <source type="image/webp" srcSet={verticalDotsWebp} />
          <img src={verticalDotsPng} alt="dots" />
        </picture>
      </span>
      <span css={featureDecorationsTwo}>
        <picture>
          <source type="image/webp" srcSet={horizontalDotsWebp} />
          <img src={horizontalDotsPng} alt="dots" />
        </picture>
      </span>
      <span css={featureDecorationsThree} />
    </div>
  </section>
)

export default FeaturesSection
