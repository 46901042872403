/* Copyright 2013 - 2024 Waiterio LLC */
import getRootDomain from './getRootDomain.js'

export default function setCookie(key, value) {
  if (typeof document !== 'undefined') {
    const date = new Date()
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000)
    let expires = 'expires=' + date.toUTCString()
    let rootDomain = getRootDomain()
    document.cookie =
      `${key}=${encodeURIComponent(value)};` +
      `${expires};` +
      'path=/;' +
      `domain=${rootDomain};`
  }
}
