/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint react/no-unescaped-entities: ["error", {"forbid": ['>', '"', '}'] }] */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import { sectionContainer, subtitle } from '@waiterio/www/styles/section.js'
import primaryHeading from '@waiterio/www/styles/primaryHeading.js'

const productOverviewContainer = css`
  display: flex;
  filter: brightness(85%);
  flex: 1;
  align-items: center;
  flex-direction: column;
  height: 700px;
  position: relative;
  padding: 0 14px;
  :hover {
    filter: brightness(100%);
    p {
      transition: 0.5s transform ease-in-out;
      transform: translateY(-60px);
    }
    div {
      transition: 0.5s transform ease-in-out;
      transform: translateY(-120px);
    }
    a {
      transition: 0.75s bottom ease-in-out, 0.75s opacity ease-in-out;
      bottom: 30px;
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    height: 400px;

    h3 {
      margin-top: 15px;
    }

    :hover {
      div {
        transform: translateY(-90px);
      }

      p {
        transform: translateY(-40px);
      }

      a {
        bottom: 15px;
      }
    }
  }

  @media (max-width: 567px) {
    :hover {
      div {
        transform: translateY(-70px);
      }

      p {
        transform: translateY(-30px);
      }
    }
  }
`

const tabletImageContainer = css({
  zIndex: 2,
  transition: '0.75s all ease-in-out',
  marginTop: 'auto',
  marginBottom: 30,
  width: 512,
  height: 350,
  borderRadius: 10,
  border: '10px solid #535150',
  background: 'white',
  '@media(max-width: 767px)': {
    width: 256,
    height: 175,
    marginBottom: 15,
  },
})

const ctaButton = css({
  cursor: 'pointer',
  padding: '12px 36px',
  borderRadius: 4,
  position: 'absolute',
  bottom: 0,
  backgroundColor: 'white',
  color: 'black',
  transition: '0.5s all ease-in-out',
  opacity: 0,
  ':hover': {
    backgroundColor: 'var(--color-green)',
    color: 'white',
  },
})

export const sectionBox = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: white;
  padding-top: 60px;
  text-align: center;

  @media (max-width: 992px) {
    padding-top: 30px;
  }
`

const ProductsOverviewSection = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <section id="home-products-overview-section" css={sectionContainer}>
      <div css={sectionBox}>
        <h2 css={primaryHeading}>{t('__home_whatweoffer_title__')}</h2>
        <p css={subtitle}>{t('__home_whatweoffer_subtitle__')}</p>

        <div
          css={{
            flexWrap: 'wrap',
            marginTop: 60,
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            flexDirection: 'row',
            '@media(max-width: 767px)': {
              flexDirection: 'column',
            },
          }}
        >
          <div
            css={[
              productOverviewContainer,
              { backgroundColor: 'var(--color-primary)' },
            ]}
          >
            <h3
              css={{
                marginTop: 30,
                color: 'white',
                fontSize: 30,
                textTransform: 'capitalize',
              }}
            >
              {t('Restaurant POS system')}
            </h3>
            <p
              css={{
                marginTop: 'auto',
                color: 'white',
                transition: '0.75s all ease-in-out',
              }}
            >
              {t('__home_banner_subtitle__')}
            </p>
            <div css={tabletImageContainer}>
              <img
                src={`https://www.imagelato.com/images/screenshot-2e0628e9-orders-tablet-${locale}.jpg`}
                css={{ width: '100%', height: '100%' }}
              />
            </div>
            <a href="https://app.waiterio.com/signup" css={ctaButton}>
              {t('Try it for free')}
            </a>
          </div>
          <div css={[productOverviewContainer, { backgroundColor: '#9676FB' }]}>
            <h3
              css={{
                marginTop: 30,
                color: 'white',
                fontSize: 30,
                textTransform: 'capitalize',
              }}
            >
              {t('Restaurant website builder')}
            </h3>
            <p
              css={{
                marginTop: 'auto',
                color: 'white',
                transition: '0.75s all ease-in-out',
              }}
            >
              {t('__website_builder_banner_subtitle__')}
            </p>
            <div css={tabletImageContainer}>
              <img
                src={`https://www.imagelato.com/images/screenshot-1b46612d-order-tablet-${locale}.jpg`}
                css={{ width: '100%', height: '100%' }}
              />
            </div>
            <a href="https://app.waiterio.com/signup" css={ctaButton}>
              {t('Try it for free')}
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductsOverviewSection
