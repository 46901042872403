/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import violet from '@waiterio/styles/violet.js'
import faviconUrl from '@waiterio/shared/faviconUrl.js'
import Layout from '../../components/LayoutComponent.js'
import languages from '../../languages.js'
import slugsWebsiteBuilder from '../../slugsWebsiteBuilder.js'
import BannerSection from './BannerSection.js'
import HowItWorksSection from './HowItWorksSection.js'
import WhyUseWebsiteBuilderSection from './WhyUseWebsiteBuilderSection.js'
import OnlineOrderingSection from './OnlineOrderingSection.js'
import MoreOnlineOrderingFeaturesSection from './MoreOnlineOrderingFeaturesSection.js'
import POSIntegrationSection from './POSIntegrationSection.js'
import UsefulFeaturesSection from './UsefulFeaturesSection.js'
import ClientsSection from './ClientsSection.js'
import ClosingSection from '../ClosingSection.js'

export const Head = ({ locale }) => {
  const { t } = useTranslation(locale)

  const title = `${t('__website_builder_first_meta_title__')} | ${t(
    '__website_builder_second_meta_title__',
    locale,
  )}`
  const description = `${t('__website_builder_meta_description__')}`
  const url = `https://www.waiterio.com/${locale}/${slugsWebsiteBuilder[locale]}/`

  return (
    <>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={faviconUrl} />
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={faviconUrl} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={faviconUrl} />
    </>
  )
}

export const paths = languages.map(locale => ({
  path: `/${locale}/${slugsWebsiteBuilder[locale] || slugsWebsiteBuilder.en}/`,
  props: {
    locale,
  },
}))

const WebsiteBuilderPage = ({ locale }) => {
  const { t } = useTranslation(locale)

  return (
    <>
      <style>{`
      :root {
        --color-primary: ${violet};
      }
    `}</style>
      <Layout app="builder" locale={locale}>
        <BannerSection locale={locale} />
        <WhyUseWebsiteBuilderSection locale={locale} />
        <HowItWorksSection locale={locale} />
        <OnlineOrderingSection locale={locale} />
        <MoreOnlineOrderingFeaturesSection locale={locale} />
        <POSIntegrationSection locale={locale} />
        <UsefulFeaturesSection locale={locale} />
        <ClientsSection locale={locale} />
        <ClosingSection
          sectionId="website-builder"
          locale={locale}
          title={t('__website_builder_closing_title__')}
          subtitle={t('__website_builder_closing_subtitle__')}
        />
      </Layout>
    </>
  )
}

export default WebsiteBuilderPage
