/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'
import { Navigate } from 'react-router-dom'
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'

export const paths = [{ path: '/restaurant-online-ordering/' }]

const OnlineOrderingRedirect = () => {
  const locale = getBrowserLanguage() || 'en'
  return <Navigate exact to={`/${locale}/restaurant-online-ordering/`} />
}

export default OnlineOrderingRedirect
