/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import { css } from '@emotion/react'

export default css`
  color: white;
  text-transform: capitalize;
  font-size: 40px;
  line-height: 50px;
  margin: 8px 0px;

  @media (max-width: 992px) {
    font-size: 36px;
    line-height: 45px;
  }

  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 40px;
    text-align: center;
  }

  @media (max-width: 576px) {
    font-size: 28px;
    line-height: 35px;
  }

  @media (max-width: 479px) {
    font-size: 24px;
    line-height: 30px;
  }
`
