/* Copyright 2013 - 2024 Waiterio LLC */
import React from 'react'
import { useSearchParams } from 'react-router-dom'

export const paths = [{ path: '/forgotpassword' }]

const ForgotPasswordRedirect = () => {
  const [searchParams] = useSearchParams()
  // console.log(searchParams)
  if (typeof window !== 'undefined') {
    window.location = `https://app.waiterio.com/forgotpassword?${searchParams}`
  }
}

export default ForgotPasswordRedirect
