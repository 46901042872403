/* Copyright 2013 - 2024 Waiterio LLC */

export default {
  cs: 'dokumentace',
  da: 'dokumentation',
  de: 'dokumentation',
  el: 'tekmiriosi',
  en: 'documentation',
  es: 'documentacion',
  fr: 'documentation',
  id: 'dokumentasi',
  it: 'documentazione',
  nl: 'documentatie',
  pl: 'dokumentacja',
  pt: 'documentacao',
  ru: 'dokumentatsiya',
  th: 'xeksar',
  tl: 'dokumentasyon',
  tr: 'belgeleme',
}
